// The strings should be declared as lowercase
export default {
  MISC: 'misc',
  AVATAR: 'avatar',
  LOGO: 'logo',
  DATA_SEED: 'DATA_SEED',
  LOGISTICS_DOCS_DELIVERY: 'LOGISTICS_DOCS_DELIVERY',
  LOGISTICS_DOCS_LOAD: 'LOGISTICS_DOCS_LOAD',
  LOGISTICS_WEIGHBRIDGE: 'LOGISTICS_PHOTO_WEIGHBRIDGE',
  VEHICLE_DOCUMENT: 'VEHICLE_DOCUMENT',
  CARRIER_TERMS_AND_CONDITIONS: 'CARRIER_TERMS_AND_CONDITIONS',
  INVENTORY_TRADE_CONTRACT: 'INVENTORY_TRADE_CONTRACT',
  ADJUSTMENT: 'adjustment',
  SAMPLE: 'sample',
  WEIGHBRIDGE: 'weighbridge',
  CONTRACT: 'contract',
  IMAGE: 'image',
  VEHICLE: 'vehicle',
  TERMS_AND_CONDITIONS: 'terms-conditions',
  TRADE_CONTRACT: 'trade-contract',
  TRADE_CONTRACT_SETTLEMENT_INVOICE: 'trade-contract-settlement-invoice'
}
