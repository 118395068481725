const enums = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  NZD: 'NZ $',
  AUD: 'AU $',
  CAD: 'CA $',
  ZAR: 'ZAR',
  USX: '$'
} as const

export default enums
