import * as richTextHtmlRenderer from '@contentful/rich-text-html-renderer'
import { Document, INLINES } from '@contentful/rich-text-types'

import { contentfulSpaces } from '~/lib/enums/contentful'

const graindexSpaceId = process.env.CONTENTFUL_GRAINDEX_SPACE_ID
const graindexContentDeliveryAccessToken = process.env.CONTENTFUL_GRAINDEX_CONTENT_DELIVERY_ACCESS_TOKEN

const helpCentreSpaceId = process.env.CONTENTFUL_HELP_CENTRE_SPACE_ID
const helpCentreContentDeliveryAccessToken = process.env.CONTENTFUL_HELP_CENTRE_CONTENT_DELIVERY_ACCESS_TOKEN
const helpCentreEnvironment = process.env.CONTENTFUL_HELP_CENTRE_ENVIRONMENT

export const fetchUrl = (space: string) =>
  space === contentfulSpaces.GRAINDEX
    ? `https://graphql.contentful.com/content/v1/spaces/${graindexSpaceId}`
    : `https://graphql.contentful.com/content/v1/spaces/${helpCentreSpaceId}/environments/${helpCentreEnvironment}`

export const buildFetchOptions = (query: string, space: string, variables?: Record<string, unknown>) => {
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${
        space === contentfulSpaces.GRAINDEX
          ? graindexContentDeliveryAccessToken
          : helpCentreContentDeliveryAccessToken
      }`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query, variables })
  }
}

export const richTextToHTMLString = (richText: Document) => {
  const options: richTextHtmlRenderer.Options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => {
        return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`
      }
    }
  }

  return richTextHtmlRenderer.documentToHtmlString(richText, options)
}
