import { get } from 'lodash'

import telephoneMask from '~/lib/input-masks/telephone'
import { icons } from '~/lib/utility/assets'

export function getCountryConfig(countryCode = 'GB') {
  const countryConfig = {
    GB: {
      geo: { centerLngLat: [-4.174804, 54.559322], centerZoomLevel: 4 },
      validationOverrides: {
        accountNumber: { digits: 8 },
        taxId: { digits: 9 }
      },
      membershipSchemes: [
        {
          id: 'red-tractor',
          apiKey: 'redTractor',
          name: 'Red Tractor assurance',
          nameShort: 'Red Tractor',
          logo: icons.redTractorPng,
          validStatuses: ['Valid', 'Grace'],
          quickQuoteOnly: true,
          form: {
            placeholder: '17/164/0006',
            mask: { mask: '00/000/0000' }
          }
        }
      ],
      placeholders: {
        accountNumber: '00000000',
        taxId: '000000000'
      },
      vehicleLookup: true,
      telephoneMask: telephoneMask.GB
    },
    ZA: {
      geo: { centerLngLat: [26.871042, -28.673057], centerZoomLevel: 4 },
      validationOverrides: {
        accountNumber: { digits: 12 },
        taxId: { digits: 10 }
      },
      placeholders: {
        accountNumber: '000000000000',
        taxId: '0000000000'
      },
      telephoneMask: telephoneMask.ZA
    },
    US: {
      geo: { centerLngLat: [-103.135581, 38.259611], centerZoomLevel: 3 },
      validationOverrides: {
        accountNumber: { digits: 8 },
        taxId: { digits: 9 }
      },
      placeholders: {
        accountNumber: '00000000',
        taxId: '000000000'
      },
      telephoneMask: telephoneMask.US
    },
    IE: {
      geo: { centerLngLat: [-7.344573, 53.604045], centerZoomLevel: 4 },
      validationOverrides: {
        accountNumber: { digits: 8 },
        taxId: { digits: 9 }
      },
      placeholders: {
        accountNumber: '00000000',
        taxId: '000000000'
      },
      telephoneMask: telephoneMask.IE
    },
    FR: {
      telephoneMask: telephoneMask.FR
    },
    DE: {
      telephoneMask: telephoneMask.DE
    }
  }

  return countryCode in countryConfig ? countryConfig[countryCode] : countryConfig.GB
}

export const getMembershipSchemes = app => {
  let validSchemes = []

  const membershipSchemes = get(app, 'countryConfig.membershipSchemes', [])

  // TODO: For now we just return the first. As we add more schemes, we'll need to change this
  membershipSchemes.forEach(scheme => {
    if (scheme.quickQuoteOnly) {
      if (app.isQuickQuoteUser) {
        validSchemes = [scheme]
      }
    } else {
      validSchemes = [scheme]
    }
  })

  return validSchemes
}
