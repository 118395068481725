import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_297b1bff from 'nuxt_plugin_plugin_297b1bff' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_75496859 from 'nuxt_plugin_sentryserver_75496859' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_0f8ff6d1 from 'nuxt_plugin_sentryclient_0f8ff6d1' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_50b459a7 from 'nuxt_plugin_plugin_50b459a7' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_gtm_b07d94aa from 'nuxt_plugin_gtm_b07d94aa' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_pluginutils_53ca9d46 from 'nuxt_plugin_pluginutils_53ca9d46' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_4198f111 from 'nuxt_plugin_pluginrouting_4198f111' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_81920804 from 'nuxt_plugin_pluginmain_81920804' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_portalvue_4e6513de from 'nuxt_plugin_portalvue_4e6513de' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_657de33d from 'nuxt_plugin_cookieuniversalnuxt_657de33d' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_appversion_4495f4e2 from 'nuxt_plugin_appversion_4495f4e2' // Source: ../src/plugins/app-version.js (mode: 'all')
import nuxt_plugin_setglobalstate_3de1fbe4 from 'nuxt_plugin_setglobalstate_3de1fbe4' // Source: ../src/plugins/set-global-state.js (mode: 'all')
import nuxt_plugin_notify_106da7d2 from 'nuxt_plugin_notify_106da7d2' // Source: ../src/plugins/notify.js (mode: 'all')
import nuxt_plugin_log_536d6a6c from 'nuxt_plugin_log_536d6a6c' // Source: ../src/plugins/log.js (mode: 'all')
import nuxt_plugin_date_3499abd2 from 'nuxt_plugin_date_3499abd2' // Source: ../src/plugins/date.js (mode: 'all')
import nuxt_plugin_nativeinitclient_4f92f27a from 'nuxt_plugin_nativeinitclient_4f92f27a' // Source: ../src/plugins/native/native-init.client.js (mode: 'client')
import nuxt_plugin_overtheairupdatesclient_6b8507d1 from 'nuxt_plugin_overtheairupdatesclient_6b8507d1' // Source: ../src/plugins/native/over-the-air-updates.client.js (mode: 'client')
import nuxt_plugin_nuxterror_53724802 from 'nuxt_plugin_nuxterror_53724802' // Source: ../src/plugins/nuxt-error.js (mode: 'all')
import nuxt_plugin_unittools_5bfc09ee from 'nuxt_plugin_unittools_5bfc09ee' // Source: ../src/plugins/unit-tools.js (mode: 'all')
import nuxt_plugin_breakpoints_e577f0a8 from 'nuxt_plugin_breakpoints_e577f0a8' // Source: ../src/plugins/breakpoints.js (mode: 'all')
import nuxt_plugin_appmixin_7b66654e from 'nuxt_plugin_appmixin_7b66654e' // Source: ../src/plugins/app-mixin.js (mode: 'all')
import nuxt_plugin_modal_6299f5fe from 'nuxt_plugin_modal_6299f5fe' // Source: ../src/plugins/modal.js (mode: 'all')
import nuxt_plugin_fontawesome_21e933da from 'nuxt_plugin_fontawesome_21e933da' // Source: ../src/plugins/fontawesome.js (mode: 'all')
import nuxt_plugin_confirm_7912624e from 'nuxt_plugin_confirm_7912624e' // Source: ../src/plugins/confirm.js (mode: 'all')
import nuxt_plugin_apimodel_10418a5d from 'nuxt_plugin_apimodel_10418a5d' // Source: ../src/plugins/api-logistics/api-model.ts (mode: 'all')
import nuxt_plugin_api_78da0798 from 'nuxt_plugin_api_78da0798' // Source: ../src/plugins/api.js (mode: 'all')
import nuxt_plugin_locale_3e9b0f66 from 'nuxt_plugin_locale_3e9b0f66' // Source: ../src/plugins/locale.js (mode: 'all')
import nuxt_plugin_localeredirect_a6906482 from 'nuxt_plugin_localeredirect_a6906482' // Source: ../src/plugins/locale-redirect.js (mode: 'all')
import nuxt_plugin_formvalidation_53476ca8 from 'nuxt_plugin_formvalidation_53476ca8' // Source: ../src/plugins/form-validation.js (mode: 'all')
import nuxt_plugin_scrollclient_8b0fff10 from 'nuxt_plugin_scrollclient_8b0fff10' // Source: ../src/plugins/client/scroll.client.js (mode: 'client')
import nuxt_plugin_logprocessorclient_4eacc98c from 'nuxt_plugin_logprocessorclient_4eacc98c' // Source: ../src/plugins/client/log-processor.client.js (mode: 'client')
import nuxt_plugin_hotjarclient_947e0368 from 'nuxt_plugin_hotjarclient_947e0368' // Source: ../src/plugins/client/hot-jar.client.js (mode: 'client')
import nuxt_plugin_openwindowclient_ba8c89f8 from 'nuxt_plugin_openwindowclient_ba8c89f8' // Source: ../src/plugins/client/open-window.client.js (mode: 'client')
import nuxt_plugin_forcenexttickclient_35379206 from 'nuxt_plugin_forcenexttickclient_35379206' // Source: ../src/plugins/client/force-next-tick.client.js (mode: 'client')
import nuxt_plugin_activityrefreshclient_7c513388 from 'nuxt_plugin_activityrefreshclient_7c513388' // Source: ../src/plugins/client/activity-refresh.client.js (mode: 'client')
import nuxt_plugin_copytoclipboardclient_126a2ccd from 'nuxt_plugin_copytoclipboardclient_126a2ccd' // Source: ../src/plugins/client/copy-to-clipboard.client.js (mode: 'client')
import nuxt_plugin_fileclient_6ab65fa7 from 'nuxt_plugin_fileclient_6ab65fa7' // Source: ../src/plugins/client/file.client.js (mode: 'client')
import nuxt_plugin_keyboardclient_25e06e06 from 'nuxt_plugin_keyboardclient_25e06e06' // Source: ../src/plugins/native/keyboard.client.js (mode: 'client')
import nuxt_plugin_statusbarclient_28513752 from 'nuxt_plugin_statusbarclient_28513752' // Source: ../src/plugins/native/status-bar.client.js (mode: 'client')
import nuxt_plugin_iconclient_cd505ed0 from 'nuxt_plugin_iconclient_cd505ed0' // Source: ../src/plugins/native/icon.client.js (mode: 'client')
import nuxt_plugin_pushnotificationsclient_050612d4 from 'nuxt_plugin_pushnotificationsclient_050612d4' // Source: ../src/plugins/native/push-notifications.client.js (mode: 'client')
import nuxt_plugin_initclient_5b30663b from 'nuxt_plugin_initclient_5b30663b' // Source: ../src/plugins/client/init.client.js (mode: 'client')
import nuxt_plugin_iframeclient_3ac408e2 from 'nuxt_plugin_iframeclient_3ac408e2' // Source: ../src/plugins/client/iframe.client.js (mode: 'client')
import nuxt_plugin_elementreadyclient_515f6643 from 'nuxt_plugin_elementreadyclient_515f6643' // Source: ../src/plugins/client/element-ready.client.js (mode: 'client')
import nuxt_plugin_observevisibility_0241871a from 'nuxt_plugin_observevisibility_0241871a' // Source: ../src/plugins/observe-visibility.ts (mode: 'all')
import nuxt_plugin_posthog_6a2f32b8 from 'nuxt_plugin_posthog_6a2f32b8' // Source: ../src/plugins/posthog.ts (mode: 'all')
import nuxt_plugin_honeycomb_7ba90a48 from 'nuxt_plugin_honeycomb_7ba90a48' // Source: ../src/plugins/honeycomb.ts (mode: 'all')
import nuxt_plugin_meta_b9371b0e from 'nuxt_plugin_meta_b9371b0e' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"hid":"viewport","name":"viewport","content":"viewport-fit=cover, width=device-width, initial-scale=1.0, user-scalable=no"},{}],"link":[{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon.png"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i+'&gtm_auth=Yy181u-nve7hxTh4vXq0lQ&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);};w[y]('GTM-MQR5QGFF')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-MQR5QGFF&gtm_auth=Yy181u-nve7hxTh4vXq0lQ&gtm_preview=env-1&gtm_cookies_win=x\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_297b1bff === 'function') {
    await nuxt_plugin_plugin_297b1bff(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_75496859 === 'function') {
    await nuxt_plugin_sentryserver_75496859(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_0f8ff6d1 === 'function') {
    await nuxt_plugin_sentryclient_0f8ff6d1(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_50b459a7 === 'function') {
    await nuxt_plugin_plugin_50b459a7(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_b07d94aa === 'function') {
    await nuxt_plugin_gtm_b07d94aa(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_53ca9d46 === 'function') {
    await nuxt_plugin_pluginutils_53ca9d46(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_4198f111 === 'function') {
    await nuxt_plugin_pluginrouting_4198f111(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_81920804 === 'function') {
    await nuxt_plugin_pluginmain_81920804(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_4e6513de === 'function') {
    await nuxt_plugin_portalvue_4e6513de(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_657de33d === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_657de33d(app.context, inject)
  }

  if (typeof nuxt_plugin_appversion_4495f4e2 === 'function') {
    await nuxt_plugin_appversion_4495f4e2(app.context, inject)
  }

  if (typeof nuxt_plugin_setglobalstate_3de1fbe4 === 'function') {
    await nuxt_plugin_setglobalstate_3de1fbe4(app.context, inject)
  }

  if (typeof nuxt_plugin_notify_106da7d2 === 'function') {
    await nuxt_plugin_notify_106da7d2(app.context, inject)
  }

  if (typeof nuxt_plugin_log_536d6a6c === 'function') {
    await nuxt_plugin_log_536d6a6c(app.context, inject)
  }

  if (typeof nuxt_plugin_date_3499abd2 === 'function') {
    await nuxt_plugin_date_3499abd2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nativeinitclient_4f92f27a === 'function') {
    await nuxt_plugin_nativeinitclient_4f92f27a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_overtheairupdatesclient_6b8507d1 === 'function') {
    await nuxt_plugin_overtheairupdatesclient_6b8507d1(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxterror_53724802 === 'function') {
    await nuxt_plugin_nuxterror_53724802(app.context, inject)
  }

  if (typeof nuxt_plugin_unittools_5bfc09ee === 'function') {
    await nuxt_plugin_unittools_5bfc09ee(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoints_e577f0a8 === 'function') {
    await nuxt_plugin_breakpoints_e577f0a8(app.context, inject)
  }

  if (typeof nuxt_plugin_appmixin_7b66654e === 'function') {
    await nuxt_plugin_appmixin_7b66654e(app.context, inject)
  }

  if (typeof nuxt_plugin_modal_6299f5fe === 'function') {
    await nuxt_plugin_modal_6299f5fe(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_21e933da === 'function') {
    await nuxt_plugin_fontawesome_21e933da(app.context, inject)
  }

  if (typeof nuxt_plugin_confirm_7912624e === 'function') {
    await nuxt_plugin_confirm_7912624e(app.context, inject)
  }

  if (typeof nuxt_plugin_apimodel_10418a5d === 'function') {
    await nuxt_plugin_apimodel_10418a5d(app.context, inject)
  }

  if (typeof nuxt_plugin_api_78da0798 === 'function') {
    await nuxt_plugin_api_78da0798(app.context, inject)
  }

  if (typeof nuxt_plugin_locale_3e9b0f66 === 'function') {
    await nuxt_plugin_locale_3e9b0f66(app.context, inject)
  }

  if (typeof nuxt_plugin_localeredirect_a6906482 === 'function') {
    await nuxt_plugin_localeredirect_a6906482(app.context, inject)
  }

  if (typeof nuxt_plugin_formvalidation_53476ca8 === 'function') {
    await nuxt_plugin_formvalidation_53476ca8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollclient_8b0fff10 === 'function') {
    await nuxt_plugin_scrollclient_8b0fff10(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_logprocessorclient_4eacc98c === 'function') {
    await nuxt_plugin_logprocessorclient_4eacc98c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjarclient_947e0368 === 'function') {
    await nuxt_plugin_hotjarclient_947e0368(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_openwindowclient_ba8c89f8 === 'function') {
    await nuxt_plugin_openwindowclient_ba8c89f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_forcenexttickclient_35379206 === 'function') {
    await nuxt_plugin_forcenexttickclient_35379206(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_activityrefreshclient_7c513388 === 'function') {
    await nuxt_plugin_activityrefreshclient_7c513388(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_copytoclipboardclient_126a2ccd === 'function') {
    await nuxt_plugin_copytoclipboardclient_126a2ccd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fileclient_6ab65fa7 === 'function') {
    await nuxt_plugin_fileclient_6ab65fa7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_keyboardclient_25e06e06 === 'function') {
    await nuxt_plugin_keyboardclient_25e06e06(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_statusbarclient_28513752 === 'function') {
    await nuxt_plugin_statusbarclient_28513752(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_iconclient_cd505ed0 === 'function') {
    await nuxt_plugin_iconclient_cd505ed0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pushnotificationsclient_050612d4 === 'function') {
    await nuxt_plugin_pushnotificationsclient_050612d4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initclient_5b30663b === 'function') {
    await nuxt_plugin_initclient_5b30663b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_iframeclient_3ac408e2 === 'function') {
    await nuxt_plugin_iframeclient_3ac408e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_elementreadyclient_515f6643 === 'function') {
    await nuxt_plugin_elementreadyclient_515f6643(app.context, inject)
  }

  if (typeof nuxt_plugin_observevisibility_0241871a === 'function') {
    await nuxt_plugin_observevisibility_0241871a(app.context, inject)
  }

  if (typeof nuxt_plugin_posthog_6a2f32b8 === 'function') {
    await nuxt_plugin_posthog_6a2f32b8(app.context, inject)
  }

  if (typeof nuxt_plugin_honeycomb_7ba90a48 === 'function') {
    await nuxt_plugin_honeycomb_7ba90a48(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_b9371b0e === 'function') {
    await nuxt_plugin_meta_b9371b0e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
