var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"notification color-backgrounds rounded-input",class:{
    error: _setup.props.type === 'error',
    information: _setup.props.type === 'information',
    warning: _setup.props.type === 'warning',
    success: _setup.props.type === 'success',
    'no-icon': !_setup.props.isIconVisible
  },attrs:{"data-testid":`notify-${_setup.props.type}`},on:{"click":function($event){return _setup.emits('click')}}},[_c('div',{staticClass:"p-4 relative"},[_c('div',{staticClass:"flex relative z-layer-paint",class:_vm.$slots.body ? 'items-start' : 'items-center'},[(_vm.isIconVisible)?_c('div',{staticClass:"flex-shrink-0"},[_c('FontAwesomeIcon',{staticClass:"icon",attrs:{"icon":_setup.icon,"fixed-width":""}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1 pt-0.5 w-0",class:{ 'ml-3': _vm.isIconVisible }},[_c('HText',{staticClass:"title break-words",attrs:{"size":"sm","weight":"bold"}},[_vm._t("title")],2),_vm._v(" "),(_vm.$slots.body)?_c('HText',{staticClass:"mt-1",attrs:{"size":"sm"}},[_vm._t("body")],2):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex flex-shrink-0 ml-4"},[_c('button',{staticClass:"inline-flex text-gray-700 hover:text-gray-500 rounded-md focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-2",attrs:{"data-testid":"notificationDismiss"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('close')))]),_vm._v(" "),_c('FontAwesomeIcon',{staticClass:"cursor-pointer !text-gray-700",attrs:{"icon":_setup.faTimes}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }