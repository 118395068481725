import { apiHostOptions } from '~/lib/enums/api-host'

// TODO set all apiPaths explicitly (never from key name) use an Enum for paths.
export const stateConfig = {
  records: { host: apiHostOptions.INVENTORY },
  storageStore: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'storage',
    defaultSearchParams: { 'f:type': 'store' }
  },
  recordsInField: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'records',
    defaultSearchParams: { 'f:storageType': 'field' }
  },
  movements: { host: apiHostOptions.INVENTORY },
  movementsIntake: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'movements',
    defaultSearchParams: { type: 'intake' }
  },
  movementsInternal: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'movements',
    defaultSearchParams: { type: 'internal' }
  },
  movementsExternal: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'movements',
    defaultSearchParams: { type: 'outbound' }
  },
  movementsIncomplete: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'movements',
    defaultSearchParams: { actions: true, sortBy: 'moveAtUTC', sortDesc: false }
  },
  actions: { host: apiHostOptions.INVENTORY, aliasApiPath: 'movements' },
  openSalesContracts: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { status: 'open' }
  },
  completedSalesContracts: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { status: 'complete' }
  },
  cancelledSalesContracts: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { status: 'cancelled' }
  },
  premiumSalesContracts: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { priceType: 'premium', sortBy: 'startDate', status: 'open' }
  },
  tradeAvailability: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { format: 'availability', docs: false }
  },
  tradePosition: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { format: 'position', docs: false }
  },
  tradePositionBusinesses: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { format: 'position', docs: false }
  },
  tradePositionBusinessUnitCommodity: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { format: 'position', docs: false }
  },
  traded: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'contracts',
    defaultSearchParams: { format: 'traded', docs: false }
  },
  businessUnitAdd: { host: apiHostOptions.INVENTORY, aliasApiPath: 'storage' },
  locationGroupAdd: { host: apiHostOptions.INVENTORY, aliasApiPath: 'storage' },
  setupStores: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'storage',
    defaultSearchParams: { 'f:type': 'store' }
  },
  setupFields: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'storage',
    defaultSearchParams: { 'f:type': 'field' }
  },
  activeStorageContracts: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'storage-contracts',
    defaultSearchParams: { status: 'active' }
  },
  completedStorageContracts: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'storage-contracts',
    defaultSearchParams: { status: 'completed' }
  },
  marketListingAll: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'listings',
    defaultSearchParams: { status: 'active,closed,traded' }
  },
  marketListingEnded: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'listings',
    defaultSearchParams: { status: 'closed,traded' }
  },
  marketListingTraded: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'listings',
    defaultSearchParams: { status: 'traded' }
  },
  bids: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'bids'
  },
  tradingInventory: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'records'
  },
  tradingBuyerAwaitingBids: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'buyer/listings'
  },
  tradingBuyerBidsAccepted: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'bids',
    defaultSearchParams: { accepted: true }
  },
  tradingBuyerMyBids: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'bids'
  },
  tradingBuyerMyBidsAccepted: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'bids',
    defaultSearchParams: { status: 'accepted', format: 'buyerTrades' }
  },
  tradingSellerBidsAccepted: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'bids',
    defaultSearchParams: { status: 'accepted', format: 'sellerTrades' }
  },
  wantedTradeRequestsSeller: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'trade-requests',
    defaultSearchParams: { status: 'active' }
  },
  wantedActiveTradeRequestsBuyer: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'trade-requests',
    defaultSearchParams: { status: 'active', owner: true }
  },
  wantedClosedTradeRequestsBuyer: {
    host: apiHostOptions.TRADING,
    aliasApiPath: 'trade-requests',
    defaultSearchParams: { status: 'active' }
  },
  harvestPlans: {
    host: apiHostOptions.INVENTORY,
    aliasApiPath: 'harvest-plans'
  }
}

export const buildSearchStateObject = () => {
  const keys = [
    { name: 'docs', value: [] },
    { name: 'aggregations', value: [] },
    { name: 'totalDocs', value: 0 },
    { name: 'totalYield', value: {} },
    { name: 'custom', value: {} },
    { name: 'clearUrl', value: '' },
    { name: 'activeFilters', value: {} },
    { name: 'availableFilters', value: [] },
    { name: 'searchLimit', value: 25 },
    { name: 'lastCalledApiPath', value: null },
    { name: 'custom', value: {} },
    { name: 'facets', value: [] }
  ]

  const searchState = {}

  keys.forEach(key => {
    const statesObj = {}

    Object.entries(stateConfig).forEach(state => {
      statesObj[state[0]] = key.value
    })

    searchState[key.name] = { ...statesObj }
  })

  return searchState
}

export const initialSearchState = {
  ...buildSearchStateObject(),
  searchOffset: 0
}
