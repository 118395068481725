// @preval
const resolveConfig = require('tailwindcss/resolveConfig')

const tailwindConfig = require('../../tailwind.config')

const config = resolveConfig(tailwindConfig)

module.exports = {
  colors: config.theme.colors,
  screens: config.theme.screens
}
