
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'Notification',
  components: { FontAwesomeIcon },
  props: {
    type: {
      type: String,
      default: 'information',
      validator: prop => ['information', 'warning', 'error', 'success', 'generic'].includes(prop)
    },

    isIconVisible: {
      type: Boolean,
      default: true
    },

    isDismissible: {
      type: Boolean,
      default: false
    },

    // Used for analytics when dismissing a message.
    // If isDismissible is true then you must provide a name
    name: {
      type: String,
      default: null
    }
  },

  emits: ['click', 'dismiss'],

  data() {
    return {
      faTimes,
      hasBeenDismissed: false
    }
  },

  computed: {
    compIsErrorWithDismissible() {
      return this.isDismissible && !this.name
    },

    compIsSingleLine() {
      return !this.$slots.body
    },

    compIcon() {
      let icon = faInfoCircle

      switch (this.type) {
        case 'warning':
          icon = faExclamationTriangle
          break

        case 'error':
          icon = faTimesCircle
          break

        case 'success':
          icon = faCheckCircle
          break
      }

      return icon
    }
  },

  created() {
    if (this.compIsErrorWithDismissible) {
      console.error('You must provide a name to enable isDismissible')
    }
  },

  methods: {
    dismiss() {
      if (this.compIsErrorWithDismissible) {
        return false
      }

      this.hasBeenDismissed = true
      this.$emit('dismiss', this.name)
    }
  }
}
