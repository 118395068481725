import { Device } from '@capacitor/device'
import { ref, watchEffect } from '@nuxtjs/composition-api'

import { isNative } from '~/plugins/native/capacitor'

const mediaQueryMobile = window.matchMedia('(max-width: 767px)')

const useDevice = () => {
  const isMobile = ref<boolean>(false)
  const isTablet = ref<boolean>(false)
  const isIos = ref<boolean>()
  const deviceInfo = ref<unknown | null>(null)

  const onChangeMobile = () => {
    isMobile.value = mediaQueryMobile.matches
  }

  const onChangeTablet = () => {
    const width = window.innerWidth

    isTablet.value = width >= 768 && width <= 1194
  }

  const isMobileOrNative = isNative || mediaQueryMobile.matches

  watchEffect(onInvalidate => {
    isMobile.value = mediaQueryMobile.matches
    onChangeTablet()

    mediaQueryMobile.addListener(onChangeMobile)
    window.addEventListener('resize', onChangeTablet)

    onInvalidate(() => {
      mediaQueryMobile.removeListener(onChangeMobile)
      window.removeEventListener('resize', onChangeTablet)
    })
  })

  const fetchDeviceInfo = async () => {
    if (!deviceInfo.value) {
      const info = await Device.getInfo()

      deviceInfo.value = info
      isIos.value = info.platform === 'ios'
    }
  }

  fetchDeviceInfo()

  return { isMobile, isMobileOrNative, isNative, isIos, isTablet }
}

export default useDevice
