const initialState = () => {
  return {
    routeHistory: [],
    originalUnauthenticatedPath: null
  }
}

export const state = () => initialState()

export const getters = {
  currentRoutePath: state => {
    return state.routeHistory[state.routeHistory.length - 1]
  },

  previousRoutePath: state => {
    return state.routeHistory[state.routeHistory.length - 2]
  }
}

export const mutations = {
  // Only called client side
  addRouteHistory(state, to) {
    if (state.routeHistory.length && state.routeHistory[state.routeHistory.length - 1] === to.fullPath) {
      return false
    }

    state.routeHistory.push(to.fullPath)
  },

  setUnauthenticatedPath(state, path) {
    state.originalUnauthenticatedPath = path
  }
}
