import tinycolor from 'tinycolor2'
import { PropType } from 'vue'

import { colors } from '~/lib/resolve-tailwind-config'

// Currently used as a list of available map marker colours to cycle through per driver
export const colorsArray = ['green', 'indigo', 'yellow', 'purple', 'red', 'gray', 'orange', 'blue', 'light-blue']

export const hexColorsArray = [
  '#2563eb',
  '#facc15',
  '#16a34a',
  '#dc2626',
  '#9333ea',
  '#db2777',
  '#ea580c',
  '#dbeafe',
  '#fef9c3',
  '#dcfce7',
  '#fee2e2',
  '#f3e8ff',
  '#fce7f3',
  '#ffedd5'
]

export function brightnessHex(color: string, amount: number): string {
  let usePound = false

  if (color[0] === '#') {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)

  let red = (num >> 16) + amount

  if (red > 255) red = 255
  else if (red < 0) red = 0

  let blue = ((num >> 8) & 0x00ff) + amount

  if (blue > 255) blue = 255
  else if (blue < 0) blue = 0

  let green = (num & 0x0000ff) + amount

  if (green > 255) green = 255
  else if (green < 0) green = 0

  return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16)
}

export const colorsToHex = {
  green: colors.green[500],
  indigo: colors.indigo[500],
  yellow: colors.yellow[500],
  purple: colors.purple[500],
  red: colors.red[500],
  gray: colors.gray[500],
  blue: colors.blue[500],
  orange: colors.orange[500],
  'light-blue': colors.secondary[600]
}

// Reusable color prop
export const colorProp = {
  type: String as PropType<string>,
  default: 'gray',
  validator: (prop: string) => colorsArray.includes(prop)
}

interface ColorProperties {
  hex: string
  textShade: string
  color: tinycolor.Instance
  isDark: boolean
}

export function parseColor(colorString: string): ColorProperties {
  const color = tinycolor(colorString)

  const brightness = color.getBrightness()
  const isDark = brightness < 150

  // These are abritrary numbers found to work after trial and error with a handful of brands...
  const darkenAmount = brightness < 150 ? 30 : 60

  const textShade = isDark ? '#FFFFFF' : color.clone().darken(darkenAmount).toString()

  return {
    hex: color.toHexString(),
    textShade,
    color,
    isDark
  }
}

export function getRandomColorSchemaFromStringCount(characters: string) {
  let val = 0

  if (characters.length === 0) {
    return val
  }

  for (const char in characters.split('')) {
    val = characters.charCodeAt(Number(char)) + ((val << 5) - val)
    val = val & val
  }

  val = ((val % hexColorsArray.length) + hexColorsArray.length) % hexColorsArray.length

  return { background: hexColorsArray[val], textShade: parseColor(hexColorsArray[val]).textShade }
}
