import { BidDetail, CreateBid, ListingDetail } from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'

type PutBidAlert = Omit<NonNullable<ListingDetail['bidAlerts']>[0], 'mobileNumber'> & {
  mobileNumber: string
}

export class MarketListingService extends ApiInventory {
  createBid(postModel: CreateBid): Promise<BidDetail> {
    return this.post(`/trading/bids`, postModel)
  }

  getBid(bidId: string) {
    return this.get(`/trading/bids/${bidId}`)
  }

  getMarketListing(id: string): Promise<ListingDetail> {
    return this.get(`/trading/listings/${id}`)
  }

  archiveMarketListing(id: string, reasonId: number): Promise<ListingDetail> {
    return this.patch(`/trading/listings/${id}/close?reasonId=${reasonId}`)
  }

  putMarketListingAlerts(marketListingId: string, bidAlerts: PutBidAlert[]) {
    return this.put(`trading/listings/${marketListingId}/bid-alerts`, { bidAlerts })
  }
}
