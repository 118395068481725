import { MarketUpdateDetail } from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'

export class MarketUpdateService extends ApiInventory {
  getMarketUpdatesLatest(startDateUTC: string): Promise<MarketUpdateDetail[]> {
    const searchParams = new URLSearchParams({ startDateUTC })

    return this.get(`/trading/market-updates/latest?${searchParams}`)
  }
}
