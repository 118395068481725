import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0632f846 = () => interopDefault(import('../src/pages/accept-terms.vue' /* webpackChunkName: "pages/accept-terms" */))
const _a83d9cd4 = () => interopDefault(import('../src/pages/help-centre/index.vue' /* webpackChunkName: "pages/help-centre/index" */))
const _8a7f81c4 = () => interopDefault(import('../src/pages/locale.vue' /* webpackChunkName: "pages/locale" */))
const _9a92c986 = () => interopDefault(import('../src/pages/marketing-preferences.vue' /* webpackChunkName: "pages/marketing-preferences" */))
const _434f1ff2 = () => interopDefault(import('../src/pages/signup-preferences.vue' /* webpackChunkName: "pages/signup-preferences" */))
const _39a61cc8 = () => interopDefault(import('../src/pages/trading/alert-preferences/index.vue' /* webpackChunkName: "pages/trading/alert-preferences/index" */))
const _3fb2064d = () => interopDefault(import('../src/pages/trading/market-insights/index.vue' /* webpackChunkName: "pages/trading/market-insights/index" */))
const _37c710a8 = () => interopDefault(import('../src/pages/trading/market-listing/index.vue' /* webpackChunkName: "pages/trading/market-listing/index" */))
const _eaeb1738 = () => interopDefault(import('../src/pages/trading/wanted/index.vue' /* webpackChunkName: "pages/trading/wanted/index" */))
const _01ba4234 = () => interopDefault(import('../src/pages/inventory/auth/business-invite.vue' /* webpackChunkName: "pages/inventory/auth/business-invite" */))
const _1dfcafb5 = () => interopDefault(import('../src/pages/inventory/auth/forgot-password.vue' /* webpackChunkName: "pages/inventory/auth/forgot-password" */))
const _387548dc = () => interopDefault(import('../src/pages/inventory/auth/login/index.vue' /* webpackChunkName: "pages/inventory/auth/login/index" */))
const _06ec728a = () => interopDefault(import('../src/pages/inventory/auth/logout.vue' /* webpackChunkName: "pages/inventory/auth/logout" */))
const _194e07da = () => interopDefault(import('../src/pages/inventory/auth/organisation.vue' /* webpackChunkName: "pages/inventory/auth/organisation" */))
const _37eb9ed9 = () => interopDefault(import('../src/pages/inventory/auth/reset-password.vue' /* webpackChunkName: "pages/inventory/auth/reset-password" */))
const _63bf10a6 = () => interopDefault(import('../src/pages/inventory/auth/set-password.vue' /* webpackChunkName: "pages/inventory/auth/set-password" */))
const _37ffcd98 = () => interopDefault(import('../src/pages/inventory/auth/sign-in-without-password.vue' /* webpackChunkName: "pages/inventory/auth/sign-in-without-password" */))
const _d1dc3f4a = () => interopDefault(import('../src/pages/inventory/internal/business-accounts/index.vue' /* webpackChunkName: "pages/inventory/internal/business-accounts/index" */))
const _105bf45a = () => interopDefault(import('../src/pages/inventory/manage/account.vue' /* webpackChunkName: "pages/inventory/manage/account" */))
const _9e6aaf00 = () => interopDefault(import('../src/pages/inventory/manage/team.vue' /* webpackChunkName: "pages/inventory/manage/team" */))
const _871b0bca = () => interopDefault(import('../src/pages/trading/manage/business-setup/index.vue' /* webpackChunkName: "pages/trading/manage/business-setup/index" */))
const _0ee5ef33 = () => interopDefault(import('../src/pages/trading/market-listing/bids/index.vue' /* webpackChunkName: "pages/trading/market-listing/bids/index" */))
const _45b7a7f9 = () => interopDefault(import('../src/pages/trading/market-listing/new/index.vue' /* webpackChunkName: "pages/trading/market-listing/new/index" */))
const _072a6496 = () => interopDefault(import('../src/pages/trading/wanted/new/index.vue' /* webpackChunkName: "pages/trading/wanted/new/index" */))
const _ee3cf6c0 = () => interopDefault(import('../src/pages/trading/wanted/seller/index.vue' /* webpackChunkName: "pages/trading/wanted/seller/index" */))
const _7e9070c2 = () => interopDefault(import('../src/pages/inventory/auth/login/create-account.vue' /* webpackChunkName: "pages/inventory/auth/login/create-account" */))
const _46ecd1be = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _040310eb = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/index.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/index" */))
const _2dcbde96 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/trade-confirmation.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/trade-confirmation" */))
const _546095d8 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/options/_optionId/advance-pay-offer.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/options/_optionId/advance-pay-offer" */))
const _7557b365 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/options/_optionId/trade-summary.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/options/_optionId/trade-summary" */))
const _93712a66 = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/create-bid.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/create-bid" */))
const _a947798a = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/offers/index.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/offers/index" */))
const _3ce98ade = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/bids/_bidId/offer/index.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/bids/_bidId/offer/index" */))
const _23104d64 = () => interopDefault(import('../src/pages/help-centre/_category/index.vue' /* webpackChunkName: "pages/help-centre/_category/index" */))
const _6106ad95 = () => interopDefault(import('../src/pages/help-centre/_category/_article.vue' /* webpackChunkName: "pages/help-centre/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-terms",
    component: _0632f846,
    name: "accept-terms"
  }, {
    path: "/help-centre",
    component: _a83d9cd4,
    name: "help-centre"
  }, {
    path: "/locale",
    component: _8a7f81c4,
    name: "locale"
  }, {
    path: "/marketing-preferences",
    component: _9a92c986,
    name: "marketing-preferences"
  }, {
    path: "/signup-preferences",
    component: _434f1ff2,
    name: "signup-preferences"
  }, {
    path: "/trading/alert-preferences",
    component: _39a61cc8,
    name: "trading-alert-preferences"
  }, {
    path: "/trading/market-insights",
    component: _3fb2064d,
    name: "trading-market-insights"
  }, {
    path: "/trading/market-listing",
    component: _37c710a8,
    name: "trading-market-listing"
  }, {
    path: "/trading/wanted",
    component: _eaeb1738,
    name: "trading-wanted"
  }, {
    path: "/inventory/auth/business-invite",
    component: _01ba4234,
    name: "inventory-auth-business-invite"
  }, {
    path: "/inventory/auth/forgot-password",
    component: _1dfcafb5,
    name: "inventory-auth-forgot-password"
  }, {
    path: "/inventory/auth/login",
    component: _387548dc,
    name: "inventory-auth-login"
  }, {
    path: "/inventory/auth/logout",
    component: _06ec728a,
    name: "inventory-auth-logout"
  }, {
    path: "/inventory/auth/organisation",
    component: _194e07da,
    name: "inventory-auth-organisation"
  }, {
    path: "/inventory/auth/reset-password",
    component: _37eb9ed9,
    name: "inventory-auth-reset-password"
  }, {
    path: "/inventory/auth/set-password",
    component: _63bf10a6,
    name: "inventory-auth-set-password"
  }, {
    path: "/inventory/auth/sign-in-without-password",
    component: _37ffcd98,
    name: "inventory-auth-sign-in-without-password"
  }, {
    path: "/inventory/internal/business-accounts",
    component: _d1dc3f4a,
    name: "inventory-internal-business-accounts"
  }, {
    path: "/inventory/manage/account",
    component: _105bf45a,
    name: "inventory-manage-account"
  }, {
    path: "/inventory/manage/team",
    component: _9e6aaf00,
    name: "inventory-manage-team"
  }, {
    path: "/trading/manage/business-setup",
    component: _871b0bca,
    name: "trading-manage-business-setup"
  }, {
    path: "/trading/market-listing/bids",
    component: _0ee5ef33,
    name: "trading-market-listing-bids"
  }, {
    path: "/trading/market-listing/new",
    component: _45b7a7f9,
    name: "trading-market-listing-new"
  }, {
    path: "/trading/wanted/new",
    component: _072a6496,
    name: "trading-wanted-new"
  }, {
    path: "/trading/wanted/seller",
    component: _ee3cf6c0,
    name: "trading-wanted-seller"
  }, {
    path: "/inventory/auth/login/create-account",
    component: _7e9070c2,
    name: "inventory-auth-login-create-account"
  }, {
    path: "/",
    component: _46ecd1be,
    name: "index"
  }, {
    path: "/trading/market-listing/bids/:bidId",
    component: _040310eb,
    name: "trading-market-listing-bids-bidId"
  }, {
    path: "/trading/market-listing/bids/:bidId/trade-confirmation",
    component: _2dcbde96,
    name: "trading-market-listing-bids-bidId-trade-confirmation"
  }, {
    path: "/trading/market-listing/bids/:bidId/options/:optionId/advance-pay-offer",
    component: _546095d8,
    name: "trading-market-listing-bids-bidId-options-optionId-advance-pay-offer"
  }, {
    path: "/trading/market-listing/bids/:bidId/options/:optionId/trade-summary",
    component: _7557b365,
    name: "trading-market-listing-bids-bidId-options-optionId-trade-summary"
  }, {
    path: "/trading/market-listing/:marketListingId?/create-bid",
    component: _93712a66,
    name: "trading-market-listing-marketListingId-create-bid"
  }, {
    path: "/trading/market-listing/:marketListingId?/offers",
    component: _a947798a,
    name: "trading-market-listing-marketListingId-offers"
  }, {
    path: "/trading/market-listing/:marketListingId?/bids/:bidId?/offer",
    component: _3ce98ade,
    name: "trading-market-listing-marketListingId-bids-bidId-offer"
  }, {
    path: "/help-centre/:category",
    component: _23104d64,
    name: "help-centre-category"
  }, {
    path: "/help-centre/:category?/:article",
    component: _6106ad95,
    name: "help-centre-category-article"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
