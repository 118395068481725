import { isNative } from '~/plugins/native/capacitor'

// Handle modifications to app icon, be it changing the image or setting unread counts
export default async function ({ store }, inject) {
  const nativeAppIcon = {}

  if (isNative) {
    const { Badge } = await import('@robingenz/capacitor-badge')
    // const { AppIcon } = await import('@capacitor-community/app-icon')

    nativeAppIcon.setUnreadCount = async count => {
      if (store.getters['notifications/isPushNotificationsReady']) {
        await Badge.set({ count })
      }
    }

    // nativeAppIcon.setIcon = async iconName => {
    //   await AppIcon.change({ name: iconName, suppressNotification: true })
    // }
  }

  inject('nativeAppIcon', nativeAppIcon)
}
