import { render, staticRenderFns } from "./HNotification.vue?vue&type=template&id=1cb5d1fa&scoped=true"
import script from "./HNotification.vue?vue&type=script&setup=true&lang=ts"
export * from "./HNotification.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./HNotification.vue?vue&type=style&index=0&id=1cb5d1fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb5d1fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HText: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/HText.vue').default})
