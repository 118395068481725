// This plugin allows us to gloablly share the state of vuex app/getApp getter
// in a more convenient way. Note this is only available for Vue components, outside of that use:
// const app = this.$store.getters['app/getApp']'

import Vue from 'vue'

let uid = 0

const mixins = {
  // Add a unique id to each component
  beforeCreate() {
    this.uid = uid.toString()
    uid += 1
  },

  computed: {
    $app() {
      // TODO: rm check once store moved outside module https://hectareagtech.atlassian.net/browse/HE-218
      if (this.$store?.getters['inventory/app/getApp']) {
        return this.$store.getters['inventory/app/getApp']
      } else {
        return {
          isNative: false,
          isStoryBook: true
        }
      }
    },

    $getHasPermission() {
      return this.$store.getters['inventory/auth/getHasPermission']
    },

    $getHasModule() {
      return this.$store.getters['inventory/auth/getHasModule']
    },

    $getHasFeature() {
      return this.$store.getters['inventory/auth/getHasFeature']
    }
  }
}

Vue.mixin(mixins)
