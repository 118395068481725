import { removeEmptyValues } from '~/lib/transform-data-format'

export interface QueryOptions {
  [key: string]: unknown
}

export function composeSearchParams(queryObject: QueryOptions): URLSearchParams {
  // Api doesn't support undefined queries so strip emtpy values before constructing the query
  return new URLSearchParams(removeEmptyValues(queryObject))
}
