
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'Error',

  layout: 'default',

  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      faChevronLeft
    }
  },

  methods: {
    handleBack() {
      this.$redirect.back()
    },

    handleHome() {
      if (this.$app.isInventory) {
        window.location.href = 'inventory'
      } else {
        window.location.href = 'dashboard'
      }
    }
  }
}
