import { configure, extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  confirmed,
  digits,
  email,
  is,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  numeric,
  regex,
  required,
  required_if as requiredIf
} from 'vee-validate/dist/rules'
import Vue from 'vue'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const oneLowercaseCharacterRegex = /\p{Ll}/u
const oneUppercaseCharacterRegex = /\p{Lu}/u
const oneDigitRegex = /\d/
const oneSpecialCharacterRegex = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
const coordinateRegex = /^-?(\d{1,3})(\.\d{1,12})?$/
const mobileNumberSimple = /^\+?[0-9]{5,20}$/
const internationalNumber = /^\+[0-9]{1,3}\s?[0-9]{5,20}$/
const newWeightTwoDigitsThreeDecimalPlaces = /^\d{1,2}(\.\d{1,3})?$/

export const sortCodeRegex = /^([0-9]){2}-([0-9]){2}-([0-9]){2}$/

function checkFieldValueTruthy(value) {
  if (value && Array.isArray(value)) {
    return value.length
  }

  if (value && typeof value === 'object') {
    return Object.keys(value).length
  }

  if (typeof value === 'number') {
    return value >= 0
  }

  return !!value
}

export default function ({ app, $log }) {
  // All our form field names must be translated
  configure({
    // fastExit: false,
    defaultMessage: (field, values) => {
      // Override the field name.
      values._field_ = app.i18n.t(`formFields.${field}`)
      return app.i18n.t(`formValidation.${values._rule_}`, values)
    }
  })

  // https://baianat.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
  setInteractionMode('eager-change', ({ errors, value }) => {
    if (errors.length) {
      return {
        on: ['input', 'change']
      }
    }

    // If the user hasn't entered a value yet then don't error on blur.
    // This is useful when using autofocus on a modal when the user
    // immediately clicks close modal, we won't want an error to show
    if (!value) {
      return {
        on: ['change']
      }
    } else {
      return {
        on: ['change', 'blur']
      }
    }
  })

  // Rules to use globally
  extend('required', required)
  extend('requiredIf', requiredIf)
  extend('email', email)
  extend('is', is)
  extend('min', min)
  extend('max', max)
  extend('minValue', minValue)
  extend('maxValue', maxValue)
  extend('maxLoad', maxValue)
  extend('digits', digits)
  extend('numeric', numeric)
  extend('regex', regex)

  extend('realisticPrice', {
    validate: value => {
      // Value comes in as pence
      value = parseInt(value) / 100

      if (value === 123 || value === 1234 || value === 12345) {
        return app.i18n.t('enterRealisticPrice')
      }

      return true
    }
  })

  extend('isSameNumber', {
    ...is,
    validate: (value, args) => {
      return parseFloat(value) === parseFloat(args.other)
    }
  })

  extend('hasEnteredPrice', {
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseEnterAValue', { fieldName: app.i18n.t(`formFields.${fieldName}`) })
    },
    validate: value => {
      if (value.amount !== null && value.currency !== null) {
        return true
      }

      return false
    }
  })

  extend('nonZeroValue', {
    message: (fieldName, placeholders) => {
      // const fieldTranslation = app.i18n.t(`formFields.${fieldName}`)

      // console.log('fieldname', fieldName)
      // console.log('fieldTranslation', fieldTranslation)

      return app.i18n.t('pleaseEnterAValue', { fieldName })
    },
    validate: value => {
      value = parseFloat(value)

      if (!value || value === 0) {
        return false
      }

      return true
    }
  })

  extend('requiredIf', {
    ...requiredIf,
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseCompleteFieldIf', {
        field1: app.i18n.t(`formFields.${fieldName}`),
        field2: app.i18n.t(`formFields.${placeholders.target}`)
      })
    }
  })

  extend('confirmed', {
    ...confirmed,
    message: () => {
      return app.i18n.t('formValidation.passwordsDoNotMatch')
    }
  })

  extend('requiredArray', {
    ...required,
    message: (fieldName, value) => {
      return app.i18n.t('pleaseEnterAtLeast', {
        fieldName: app.i18n.t(`formFields.${fieldName}`),
        value: value.allowFalse
      })
    }
  })

  extend('requiredIfTargetEmpty', {
    ...requiredIf,
    validate: (value, args) => {
      const targetValue = args.target

      return checkFieldValueTruthy(targetValue) || checkFieldValueTruthy(value)
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseFillInEitherField', {
        field1: app.i18n.t(`formFields.${fieldName}`),
        field2: app.i18n.t(`formFields.${placeholders.target}`)
      })
    }
  })

  extend('minValCompare', {
    ...requiredIf,
    validate: (value, args) => {
      const targetValue = args.target

      return value === null || value >= targetValue
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('valueCannotBeLessThan', {
        field1: app.i18n.t(`formFields.${fieldName}`),
        field2: app.i18n.t(`formFields.${placeholders.target}`)
      })
    }
  })

  extend('requiredMaxValCompare', {
    ...requiredIf,
    validate: (value, args) => {
      const targetValue = args.target

      return value <= targetValue
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('valueCannotBeMoreThan', {
        field1: app.i18n.t(`formFields.${fieldName}`),
        field2: app.i18n.t(`formFields.${placeholders.target}`)
      })
    }
  })

  extend('cognitoPassword', {
    validate: value => {
      return value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/)
    },
    message: () => {
      return app.i18n.t('passwordMatchCognitoValidation')
    }
  })

  extend('companyNumber', {
    validate: value => {
      return value.match(/^(?:(LP|OC|NC|NI|NL|SC|SL|SO|R0|[0-9]{2})[0-9]{6})$/)
    },
    message: () => {
      return app.i18n.t('companyNumberMatchValidation')
    }
  })

  extend('sortcode', {
    validate: value => {
      return value.match(sortCodeRegex)
    },
    message: () => {
      return app.i18n.t('sortCodeNotValidFormat')
    }
  })

  extend('unique', {
    validate: (value, args) => {
      const instances = args.filter(item => item === value)

      return instances.length === 1
    },
    message: fieldName => {
      return app.i18n.t('mustBeUnique', { fieldName: app.i18n.t(`formFields.${fieldName}`) })
    }
  })

  extend('maxMovementWeight', {
    ...maxValue,
    message: () => {
      return app.i18n.t('movingTooMuchHere')
    }
  })

  extend('telephoneNumberRequired', {
    validate: value => {
      if (value.number && value.callingCode) return true

      return false
    },

    message: fieldName => {
      const values = { _field_: app.i18n.t(`formFields.${fieldName}`), _rule_: 'required' }

      return app.i18n.t(`formValidation.${values._rule_}`, values)
    }
  })

  extend('latitude', {
    validate: value => {
      const lat = Number(value)
      const isValid = Math.abs(lat) <= 90 && coordinateRegex.test(value)

      return isValid
    },

    message: fieldName => {
      return app.i18n.t('formValidation.isNotValid', { _field_: app.i18n.t(`formFields.${fieldName}`) })
    }
  })

  extend('longitude', {
    validate: value => {
      const lon = Number(value)
      const isValid = Math.abs(lon) <= 180 && coordinateRegex.test(value)

      return isValid
    },

    message: fieldName => {
      return app.i18n.t('formValidation.isNotValid', { _field_: app.i18n.t(`formFields.${fieldName}`) })
    }
  })

  extend('minPricePerUnit', {
    validate: value => {
      if (value >= 0.01) return true

      return false
    },

    message: fieldName => {
      return app.i18n.t('formValidation.mustHaveAMinPricePerUnitValue', {
        fieldName: app.i18n.t(`formFields.${fieldName}`)
      })
    }
  })

  extend('requiredNonZero', {
    validate: value => {
      if (value) {
        return (typeof value === 'string' && value !== '') || (typeof value === 'number' && value > 0)
      }

      return false
    },

    message: fieldName => {
      return app.i18n.t('formValidation.required', {
        fieldName: app.i18n.t(`formFields.${fieldName}`)
      })
    }
  })

  // Check some object in value array has property with specific values
  // param[0] is the key and param[1] is the value or array of values
  // e.g. ['type', 'farm'] or ['type', ['farm', 'store']]
  const someValueHasPropertyValueValidator = (value, params) => {
    const keyName = params[0] || 'key'
    const propValue = params[1]

    if (!Array.isArray(value)) {
      return false
    }

    if (Array.isArray(propValue)) {
      return value.some(item => keyName in item && propValue.includes(item[keyName]))
    }

    return value.some(item => keyName in item && item[keyName] === propValue)
  }

  const someValueHasPropertyValue = {
    message: (field, params) => {
      const keyName = params[0] || 'key'
      const propValue = params[1]

      if (Array.isArray(propValue)) {
        return app.i18n.t('formValidation.someValueHasPropertyValues', {
          keyName,
          propValues: propValue.join(', ')
        })
      }

      return app.i18n.t('formValidation.someValueHasPropertyValue', { keyName, propValue: propValue.toString() })
    },
    validate: (value, params) => someValueHasPropertyValueValidator(value, params)
  }

  extend('someValueHasPropertyValue', someValueHasPropertyValue)

  extend('businessUnitSelectorIncludesCommercial', {
    validate: value => someValueHasPropertyValueValidator(value, ['type', ['farm', 'store']]),
    message: () => {
      return app.i18n.t('aBusinessUnitRequired')
    }
  })

  extend('oneLowercaseCharacter', {
    validate: value => {
      return value ? oneLowercaseCharacterRegex.test(value) : false
    },
    message: () => app.i18n.t('oneLowercaseCharacter')
  })

  extend('oneUppercaseCharacter', {
    validate: value => {
      return value ? oneUppercaseCharacterRegex.test(value) : false
    },
    message: () => app.i18n.t('oneUppercaseCharacter')
  })

  extend('oneDigit', {
    validate: value => {
      return oneDigitRegex.test(value)
    },
    message: () => app.i18n.t('oneDigit')
  })

  extend('oneSpecialCharacter', {
    validate: value => {
      return oneSpecialCharacterRegex.test(value)
    },
    message: () => app.i18n.t('oneSpecialCharacter')
  })

  extend('emailOrInternationalNumber', {
    validate: value => {
      return email.validate(value) || internationalNumber.test(value)
    },
    message: () => app.i18n.t('mustBeAValidPhoneNumber')
  })

  extend('emailOrMobileNumber', {
    validate: value => {
      return email.validate(value) || mobileNumberSimple.test(value)
    },
    message: () => app.i18n.t('mustBeAValidEmailOrMobileNumber')
  })

  extend('loadNetWeightTonnage', {
    validate: value => {
      return newWeightTwoDigitsThreeDecimalPlaces.test(value)
    },
    message: () => app.i18n.t('mustBeTonnes')
  })

  extend('contactNumberIsNotValid', {
    validate: () => false,
    message: () => app.i18n.t('mustBeAValidContactNumber')
  })
}
