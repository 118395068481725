export const filterConfig = {
  RECORD_TYPE: {
    key: 'RECORD_TYPE',
    translationKey: 'filterTranslations.category',
    canMultiSelect: false,
    facetKey: 'f:type'
  },
  COMMODITY: {
    key: 'COMMODITY',
    translationKey: 'filterTranslations.crop',
    canMultiSelect: true,
    facetKey: 'f:commodity'
  },
  LOCATION: {
    key: 'LOCATION',
    translationKey: 'filterTranslations.location',
    canMultiSelect: true,
    facetKey: 'f:storageId'
  },
  TYPE_CLASS: {
    key: 'TYPE_CLASS',
    translationKey: 'filterTranslations.typeClass',
    canMultiSelect: true,
    facetKey: 'f:commodityType'
  },
  GROUP_GRADE: {
    key: 'GROUP_GRADE',
    translationKey: 'filterTranslations.groupGrade',
    canMultiSelect: true,
    facetKey: 'f:commodityGrade'
  },
  ATTRIBUTES: {
    key: 'ATTRIBUTES',
    translationKey: 'filterTranslations.attributes',
    canMultiSelect: true,
    facetKey: 'f:commodityTags'
  },
  VARIETY: {
    key: 'VARIETY',
    translationKey: 'filterTranslations.variety',
    canMultiSelect: true,
    facetKey: 'f:commodityVariety'
  },
  MOVING_FROM: {
    key: 'MOVING_FROM',
    translationKey: 'filterTranslations.movingFrom',
    canMultiSelect: true,
    facetKey: 'f:storageSourceId'
  },
  MOVING_TO: {
    key: 'MOVING_TO',
    translationKey: 'filterTranslations.movingTo',
    canMultiSelect: true,
    facetKey: 'f:storageDestinationId'
  },
  SALES_STATUS: {
    key: 'SALES_STATUS',
    translationKey: 'filterTranslations.salesStatus',
    canMultiSelect: false,
    facetKey: 'f:saleStatus'
  },

  OWNER_STATUS: {
    key: 'OWNER_STATUS',
    translationKey: 'filterTranslations.ownerStatus',
    canMultiSelect: false,
    facetKey: 'ownerStatus',
    termTranslations: {
      OWNED: 'owned',
      STORED: 'thirdPartyOwned'
    }
  },
  INVENTORY_OWNER_RECORDS: {
    // records, records in field
    key: 'INVENTORY_OWNER_RECORDS',
    translationKey: 'filterTranslations.inventoryOwner',
    canMultiSelect: false,
    facetKey: 'f:organisationId'
  },

  INVENTORY_OWNER_STORAGE: {
    // storage store
    key: 'INVENTORY_OWNER_STORAGE',
    translationKey: 'filterTranslations.inventoryOwner',
    canMultiSelect: false,
    facetKey: 'f:ownerOrganisationId'
  },

  INVENTORY_TYPE: {
    key: 'INVENTORY_TYPE',
    translationKey: 'filterTranslations.inventoryType',
    canMultiSelect: false,
    facetKey: 'f:inventoryType'
  },
  STORAGE_OWNER: {
    key: 'STORAGE_OWNER',
    translationKey: 'filterTranslations.storageOwner',
    canMultiSelect: false,
    facetKey: 'f:storageOrganisationId'
  },
  HARVEST_YEAR: {
    key: 'HARVEST_YEAR',
    translationKey: 'filterTranslations.harvestYear',
    canMultiSelect: true,
    facetKey: 'f:harvestYear'
  },
  STORAGE_TYPE: {
    key: 'STORAGE_TYPE',
    translationKey: 'filterTranslations.storageType',
    canMultiSelect: true,
    facetKey: 'f:storageType'
  },

  BUSINESS_UNIT: {
    key: 'BUSINESS_UNIT',
    translationKey: 'filterTranslations.businessUnits',
    canMultiSelect: true,
    facetKey: 'f:businessUnitIds'
  },

  STATUS: {
    key: 'STATUS',
    translationKey: 'filterTranslations.status',
    canMultiSelect: true,
    facetKey: 'f:status'
  },

  SELLER: {
    key: 'SELLER',
    translationKey: 'filterTranslations.seller',
    canMultiSelect: true,
    facetKey: 'f:sellerOrganisationId'
  },

  ORGANISER_ID: {
    key: 'ORGANISER_ID',
    translationKey: 'filterTranslations.organiserId',
    canMultiSelect: true,
    facetKey: 'f:organiserId'
  },

  CARRIER_ID: {
    key: 'CARRIER_ID',
    translationKey: 'filterTranslations.carrierId',
    canMultiSelect: true,
    facetKey: 'f:carrierId'
  },

  REGION: {
    key: 'REGION',
    translationKey: 'filterTranslations.region',
    canMultiSelect: true,
    facetKey: 'f:regionISO'
  },

  SORT_BY_MOVEMENT: {
    key: 'SORT_BY_MOVEMENT',
    translationKey: 'filterTranslations.sortByMovement',
    canMultiSelect: false,
    facetKey: null
  },

  MANAGEMENT_TYPE: {
    key: 'MANAGEMENT_TYPE',
    translationKey: 'filterTranslations.managementType',
    canMultiSelect: true,
    facetKey: 'f:tagm'
  },

  BUSINESS_AREA: {
    key: 'BUSINESS_AREA',
    translationKey: 'filterTranslations.businessArea',
    canMultiSelect: true,
    facetKey: 'f:tagl'
  }
}
