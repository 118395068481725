const colors = require('tailwindcss/colors')

const beamingBlue = {
  50: '#F6F6FE',
  100: '#E8EAFC',
  200: '#C4C7F8',
  300: '#A6ACF2',
  400: '#868DEA',
  500: '#5963E3',
  DEFAULT: '#3B47E3',
  600: '#3B47E3',
  700: '#272ADD',
  800: '#1F22BD',
  900: '#0902A1'
}

const navy = {
  50: '#F8F8FC',
  100: '#E7E8F4',
  200: '#CDD1EA',
  300: '#A8AFDC',
  400: '#828CCE',
  500: '#606CBE',
  DEFAULT: '#071151',
  600: '#4351AD',
  700: '#253498',
  800: '#122078',
  900: '#071151'
}
const primary = beamingBlue

const tailwindColorConfig = {
  body: {
    DEFAULT: colors.gray[700]
  },
  highlight: '#FAFCFF',
  error: colors.red[700],
  success: colors.green[600],
  active: '#F0F6FF',
  label: colors.gray[800],
  light: colors.gray[500],
  lighter: colors.gray[400],
  'panel-bg': colors.gray[50],
  'panel-border': colors.gray[200],
  overlay: 'rgba(00, 00, 00, 0.1)',
  hover: beamingBlue[50],
  primary,
  secondary: {
    100: '#E0ECF5',
    200: '#C2D9EB',
    300: '#A3C7E2',
    400: '#85B4D8',
    500: '#66a1ce',
    DEFAULT: '#66a1ce',
    600: '#408EC9',
    700: '#2C79B5',
    800: '#1F6498',
    900: '#154E7A'
  },
  background: navy.DEFAULT,
  navy,
  'beaming-blue': beamingBlue,
  'beaming-hover': beamingBlue[700],

  green: colors.emerald
}

const defaultBrandConfig = {
  colors: {
    background: {
      hex: navy.DEFAULT,
      secondary: '#FFFFFF',
      isDark: true
    },
    primary: {
      hex: beamingBlue.DEFAULT,
      secondary: '#FFFFFF',
      isDark: true
    }
  }
}

module.exports = {
  navy,
  beamingBlue,
  defaultBrandConfig,
  primary,
  tailwindColorConfig
}
