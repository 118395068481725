import { searchFilterEnums } from '../enums/searchFilters'

export const formatApiPath = (apiPath: string, shouldReturnDocs: boolean): string => {
  if (shouldReturnDocs && apiPath?.includes('docs=false')) {
    apiPath = apiPath.replace('docs=false', '')
  } else if (!shouldReturnDocs && !apiPath?.includes('docs=false')) {
    apiPath = `${apiPath}&docs=false`
  }

  return apiPath
}

export const filterSearchURLParams = (urlParams: Record<string, unknown>) => {
  const searchParams = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => {
      return key.includes('f:') || searchFilterEnums.includes(key)
    })
  )

  const otherParams = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => {
      return !key.includes('f:') && !searchFilterEnums.includes(key)
    })
  )

  const paramsWithNoFilters = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => {
      return !key.includes('f:')
    })
  )

  return { searchParams, otherParams, paramsWithNoFilters }
}
