import { isNative } from '~/plugins/native/capacitor'

export default function ({ $notify, app }, inject) {
  const copyToClipboard = async (copyText = '', notifyOnSuccess = true) => {
    if (isNative) {
      const { Clipboard } = await import('@capacitor/clipboard')

      await Clipboard.write({
        string: copyText
      })
    } else {
      navigator.clipboard.writeText(copyText)
    }

    if (notifyOnSuccess) {
      $notify.success(app.i18n.t('copiedToClipboard'))
    }
  }

  // Inject to context as $copyToClipboard
  inject('copyToClipboard', copyToClipboard)
}
