import { CreateTradeRequest, TradeRequestDetail } from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'

type SeachTradeRequestQueryParams = {
  status?: Exclude<TradeRequestDetail['status'], undefined>
  owner?: string
  limit?: string
  offset?: string
  sortBy?: string
}

export class TradeRequestsService extends ApiInventory {
  searchTradeRequests(searchParams: SeachTradeRequestQueryParams = {}): Promise<{ docs?: TradeRequestDetail[] }> {
    const queryParams = new URLSearchParams(searchParams)

    return this.get(`/trading/trade-requests?${queryParams}`)
  }

  endTradeRequest(id: string): Promise<TradeRequestDetail> {
    return this.patch(`/trading/trade-requests/${id}/close`)
  }

  getTradeRequests(query: Record<string, string>): Promise<TradeRequestDetail> {
    const queryParams = new URLSearchParams(query)

    return this.get(`/trading/trade-requests?${queryParams.toString()}`)
  }

  getTradeRequest(id: string): Promise<TradeRequestDetail> {
    return this.get(`/trading/trade-requests/${id}`)
  }

  createTradeRequest(postModel: CreateTradeRequest): Promise<TradeRequestDetail> {
    return this.post('/trading/trade-requests', postModel)
  }
}
