import {
  formatDecimals,
  getAreaYieldUnits,
  getConfig,
  getConversionFactor,
  getFormattedArea,
  getFullDisplayWeight,
  getShortDisplayWeight,
  getUnitMask,
  getUnitShort,
  getUnitTranslations
} from '~/modules/inventory/lib/utility/unitTool'

export default function ({ app, store }, inject) {
  const unitTools = {
    getConfig: ({ measurement = 'weight', commodity = null, unitValue = 0, asset = null }) => {
      const config = getConfig(
        {
          measurement,
          commodity,
          unitValue,
          asset
        },
        store.getters['inventory/app/getApp'],
        app.i18n
      )

      return config
    },
    getConversionFactor,
    getUnitTranslations: (unitKey, unitValue) => getUnitTranslations(app.i18n, unitKey, unitValue),
    getUnitMask,
    formatDecimals,
    getDisplayWeight: (unitValue, unitKey, isShort = false, fixedDecimals = 2) => {
      const locale = app.i18n.localeProperties.iso

      if (isShort) {
        return getShortDisplayWeight(app.i18n, unitValue, unitKey, fixedDecimals, locale)
      } else {
        return getFullDisplayWeight(app.i18n, unitValue, unitKey, fixedDecimals, locale)
      }
    },
    getUnitShort: unitKey => getUnitShort(app.i18n, unitKey),
    getAreaYieldUnits: weightUnit => getAreaYieldUnits(app.i18n, weightUnit),
    getFormattedArea: (area, short = false) => getFormattedArea(app.i18n, area, short)
  }

  inject('unitTools', unitTools)
}
