export const getRandomInt = function (min: number, max: number): number {
  const roundedMin = Math.ceil(min)
  const roundedMax = Math.floor(max)

  return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin
}

export const removeTrailingZeros = function (value: string): number {
  return parseFloat(value)
}

export const getLocaleFormattedNumber = function (number: number, locale: string): string {
  return new Intl.NumberFormat(locale).format(number) ?? number
}

export const getLocaleNumberSignDisplay = function (number: number, locale: string): string {
  // Live users are using versions of safari that don't support numberFormat's
  // signDisplay option so we need to manually add the sign if positive (by default it will add -)
  const prefix = number > 0 ? '+' : ''

  return prefix + new Intl.NumberFormat(locale).format(number)
}

type RoundingMode = 'round' | 'ceil'

export const getPercentage = function (
  numerator: number,
  denominator: number,
  roundingMode: RoundingMode
): number {
  const calc = (numerator / denominator) * 100

  switch (roundingMode) {
    case 'round':
      return Math.round(calc)

    case 'ceil':
      return Math.ceil(calc)

    default:
      return calc
  }
}

export const getRounded = (value: number, precision = 0) => {
  const adjustDecimalPlace = Math.pow(10, precision)

  const ceil = () => {
    const ceiled = Math.ceil(value * adjustDecimalPlace) / adjustDecimalPlace

    return ceiled
  }

  const floor = () => {
    const floored = Math.floor(value * adjustDecimalPlace) / adjustDecimalPlace

    return floored
  }

  const round = () => {
    const rounded = Math.round(value * adjustDecimalPlace) / adjustDecimalPlace

    return rounded
  }

  return {
    ceil,
    floor,
    round
  }
}

export const formatCurrency = (
  centValue: number,
  currency: string,
  locale: string,
  isValueInLowestDenomination = true,
  minimalDecimalPlaces = 2
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: minimalDecimalPlaces
  }).format(centValue / (isValueInLowestDenomination ? 100 : 1))
}

export function isDecimal(value: number) {
  return !!value.toString().includes('.')
}

export const getCurrencyStyle = (value: number) => {
  const amountIsDecimal = isDecimal(value)

  return amountIsDecimal ? 'currency' : 'currencyNoDecimals'
}
