import { isNative, platform } from '~/plugins/native/capacitor'

// Called last when all other plugins have been initialised
export default function ({ app, store, $log, $sentry }) {
  store.dispatch('device/getInfo')

  // Every time the route changes (fired on initialization too)
  app.router.afterEach((to, from) => {
    store.commit('navigation/addRouteHistory', to)
  })

  window.onNuxtReady(() => {
    store.commit('inventory/app/setClientLoaded', true)

    // Add additional debug information to our Sentry error reporting
    if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
      $sentry.configureScope(scope => {
        scope.setTag('app-platform', platform)
        scope.setTag('app-native', isNative)
      })
    }
  })
}
