import { render, staticRenderFns } from "./NotificationFloating.vue?vue&type=template&id=2a482e0b&scoped=true"
import script from "./NotificationFloating.vue?vue&type=script&setup=true&lang=ts"
export * from "./NotificationFloating.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NotificationFloating.vue?vue&type=style&index=0&id=2a482e0b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a482e0b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HText: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/HText.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default})
