import dayjs from 'dayjs'

import { platform } from '~/plugins/native/capacitor'

export default function (existingModel = {}) {
  return {
    model: {
      message: null,
      data: null,
      level: 'debug',
      meta: {
        platform
      },
      created: dayjs().toISOString(),
      ...existingModel
    }
  }
}
