import { set } from 'vue'

const initalState = () => {
  return {
    dropdownOptions: []
  }
}

export const state = () => initalState()

export const getters = {
  shouldShowDropdown: state => {
    return state.dropdownOptions.length > 0
  },

  dropdownOptions: state => {
    return state.dropdownOptions
  }
}

export const mutations = {
  setDropdownOptions(state, options) {
    set(state, 'dropdownOptions', [...options])
  }
}

export const actions = {
  clearDropdownOptions({ commit }) {
    commit('setDropdownOptions', [])
  }
}
