
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'Loader',
  components: { FontAwesomeIcon },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    loadingText: {
      type: String,
      default: null
    },

    iconOnly: {
      type: Boolean,
      default: false
    },

    customLongLoadingText: {
      type: String,
      default: null
    },

    showLongLoadingText: {
      type: Boolean,
      default: true
    },

    longLoadingTextClass: {
      type: String,
      default: null
    },

    loadingWrapperClass: {
      type: String,
      default: ''
    },

    iconClass: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      timeStart: 0,
      timer: null,
      longLoadingText: null,
      isConsideredTimeout: false,
      faSpinnerThird
    }
  },

  computed: {
    compLoadingText() {
      return this.loadingText || `${this.$t('loading')}...`
    }
  },

  watch: {
    isLoading(loading) {
      if (loading) {
        this.startSlowLoadingTimer()
      } else {
        this.stopSlowLoadingTimer()
      }
    }
  },

  mounted() {
    if (this.isLoading) {
      this.startSlowLoadingTimer()
    }
  },

  unmounted() {
    this.stopSlowLoadingTimer()
  },

  methods: {
    startSlowLoadingTimer() {
      if (!this.timer && this.showLongLoadingText) {
        this.timeStart = this.$date()

        this.timer = setInterval(() => {
          const timeNow = this.$date()

          const secondsTimeDifference = timeNow.diff(this.timeStart, 'second')

          if (secondsTimeDifference > 6) {
            this.longLoadingText = this.customLongLoadingText || this.$t('stillLoading')
          }

          if (secondsTimeDifference > 15) {
            this.longLoadingText = this.customLongLoadingText || this.$t('internetConnectivityIssues')
          }

          // If their internet is offline we don't want them contacting us
          if (secondsTimeDifference > 25 && this.$app.isOnline) {
            this.longLoadingText = this.customLongLoadingText || this.$t('takingTooLongTryReloading')
            this.isConsideredTimeout = true
          }
        }, 1000)
      }
    },

    stopSlowLoadingTimer() {
      clearInterval(this.timer)
      this.longLoadingText = null
    }
  }
}
