export const searchFilterEnums = [
  'offset',
  'limit',
  'format',
  'startDateISO',
  'endDateISO',
  'status',
  'conflict',
  'query',
  'sortDesc',
  'sortBy',
  'docs',
  'type',
  'wanted',
  'bidStatus'
]
