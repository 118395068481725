
import {
  Block,
  BLOCKS,
  Document,
  helpers,
  Inline,
  INLINES,
  MARKS,
  Text,
  TopLevelBlock
} from '@contentful/rich-text-types'
import { PropType, toRefs } from '@nuxtjs/composition-api'
import { h, VNode, VNodeChildren } from 'vue'

import HText from '~/components/elements/HText.vue'
import HTitle from '~/components/elements/HTitle.vue'
import HNotification from '~/components/molecules/HNotification.vue'

type RenderNextNode = (
  nodes: (Block | Text | Inline | TopLevelBlock)[],
  next: RenderNextNode
) => (string | VNode)[]
type MarkRenderer = (children: VNodeChildren | string) => VNode

type Asset = {
  sys: {
    id: string
  }
  url: string
  contentType: string
}

type Callout = {
  sys: {
    id: string
  }
  __typename: 'Callout'
  text: string
}

type Entries = Callout

const unimplementedRenderers = {
  [INLINES.RESOURCE_HYPERLINK]: (_node: Block | Inline | TopLevelBlock) => h('span'),
  [INLINES.ENTRY_HYPERLINK]: (_node: Block | Inline | TopLevelBlock) => h('span'),
  [INLINES.ASSET_HYPERLINK]: (_node: Block | Inline | TopLevelBlock) => h('span'),
  [INLINES.EMBEDDED_RESOURCE]: (_node: Block | Inline | TopLevelBlock) => h('span')
}

export default {
  name: 'RichText',

  props: {
    document: { type: Object as PropType<Document>, required: true },
    assets: {
      type: Array as PropType<Asset[]>,
      default: () => {
        return []
      }
    },

    entries: {
      type: Array as PropType<Entries[]>,
      default: () => {
        return []
      }
    }
  },

  // @ts-ignore next-line no-implicit-any
  setup(props) {
    const { assets, entries } = toRefs<{
      assets: Asset[]
      entries: Entries[]
    }>(props)

    const resolveAssetById = (id: string) => {
      const asset = assets.value.find(asset => asset.sys.id === id)

      return asset
    }

    const defaultMarkRenderers: Record<string, MarkRenderer> = {
      [MARKS.BOLD]: children => h('b', { props: { weight: 'bold' } }, children),
      [MARKS.ITALIC]: children => h('i', children),
      [MARKS.UNDERLINE]: children => h('u', children),
      [MARKS.CODE]: children => h('code', children),
      [MARKS.SUPERSCRIPT]: children => h('sup', children),
      [MARKS.SUBSCRIPT]: children => h('sub', children)
    }

    const defaultNodeRenderers = {
      [BLOCKS.DOCUMENT]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('article', next(node.content, next)),

      [BLOCKS.PARAGRAPH]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HText, next(node.content, next)),

      [BLOCKS.HEADING_1]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, next(node.content, next)),

      [BLOCKS.HEADING_2]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, { props: { size: 'small' } }, next(node.content, next)),

      [BLOCKS.HEADING_3]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, { props: { size: 'smaller' } }, next(node.content, next)),

      [BLOCKS.HEADING_4]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, { props: { size: 'smallest' } }, next(node.content, next)),

      [BLOCKS.HEADING_5]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, { props: { size: 'smallest' } }, next(node.content, next)),

      [BLOCKS.HEADING_6]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h(HTitle, { props: { size: 'smallest' } }, next(node.content, next)),

      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('div', {}, next(node.content, next)),

      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) => {
        const asset = resolveAssetById(node.data.target.sys.id)

        if (!asset) {
          return h('span')
        }

        if (asset.contentType.includes('video/')) {
          return h('video', { attrs: { controls: true } }, [h('source', { attrs: { src: asset.url } })])
        }

        return h(
          'img',
          {
            attrs: {
              src: asset.url
            }
          },
          next(node.content, next)
        )
      },

      [BLOCKS.EMBEDDED_RESOURCE]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('div', next(node.content, next)),

      [BLOCKS.UL_LIST]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('ul', { class: 'unordered-list' }, next(node.content, next)),

      [BLOCKS.OL_LIST]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('ol', { class: 'ordered-list' }, next(node.content, next)),

      [BLOCKS.LIST_ITEM]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('li', {}, next(node.content, next)),

      [BLOCKS.QUOTE]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('blockquote', {}, next(node.content, next)),

      [BLOCKS.TABLE]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('table', next(node.content, next)),

      [BLOCKS.TABLE_ROW]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('tr', next(node.content, next)),

      [BLOCKS.TABLE_CELL]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('td', next(node.content, next)),

      [BLOCKS.TABLE_HEADER_CELL]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) =>
        h('th', next(node.content, next)),

      [BLOCKS.HR]: (_node: Block | Inline | TopLevelBlock) => h('hr'),

      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline | TopLevelBlock) => {
        const entity = entries.value.find(entity => entity.sys.id === node.data.target.sys.id)

        if (!entity) {
          return h('span')
        }

        return h(HNotification, [h(HText, { props: { variant: 'information', weight: 'bold' } }, [entity.text])])
      },

      [INLINES.HYPERLINK]: (node: Block | Inline | TopLevelBlock, next: RenderNextNode) => {
        return h('u', { class: 'hyperlink' }, [
          h(
            'a',
            {
              attrs: {
                rel: 'nofollow',
                target: '_blank',
                href: node.data.uri
              }
            },
            next(node.content, next)
          )
        ])
      },

      text: ({ marks, value }: Text) => {
        if (!marks.length) {
          return value
        }

        const marksReversed = [...marks].reverse()

        const rendered = marksReversed.reduce<VNode>((acc, mark) => {
          const renderer = defaultMarkRenderers[mark.type]

          return renderer([acc])
        }, h('span', value))

        return rendered
      },

      ...unimplementedRenderers
    }

    const renderNodeList = (nodes: (Block | Text | Inline | TopLevelBlock)[]): (string | VNode)[] => {
      return nodes.map(node => renderNode(node))
    }

    const renderNode = (node: Block | Text | Inline | TopLevelBlock) => {
      if (helpers.isText(node)) {
        return defaultNodeRenderers.text(node)
      }

      const nextNode = (nodes: (Block | Text | Inline | TopLevelBlock)[]) => renderNodeList(nodes)

      return defaultNodeRenderers[node.nodeType](node, nextNode)
    }

    return () => h('div', { attrs: { class: 'rich-text' } }, renderNodeList(props.document.content))
  }
}
