import { user } from '~/lib/enums/custom-user-meta'
import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    customUserMeta: [],
    customUserMetaApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  customUserMetaLength: (state, getters) => {
    return state.customUserMeta.length
  },

  getCustomUserMeta: (state, getters) => key => {
    return state.customUserMeta?.find(customUserMeta => customUserMeta.key === key)?.value
  },

  hasDismissedTutorial: (state, getters) => {
    return getters.getCustomUserMeta(user.HAS_DISMISSED_TUTORIAL) ?? false
  },

  hasCreatedJob: (state, getters) => {
    return getters.getCustomUserMeta(user.HAS_CREATED_JOB) ?? false
  },

  hasCreatedVehicles: (state, getters) => {
    return getters.getCustomUserMeta(user.HAS_CREATED_VEHICLES) ?? false
  }
}

export const actions = {
  async add({ state }, { profileId, requestModel }) {
    try {
      await new this.$Api(this.customUserMetaApi).post(`/auth/v1/profile/${profileId}/kv`, {
        kv: requestModel
      })
    } catch (error) {
      this.$log.error(`Add custom user meta error for ${requestModel.key}`, error)
    }
  },

  async fetch({ state, commit }, { profileId }) {
    try {
      const allCarrierCustomUserMeta = await new this.$Api(this.customUserMetaApi).get(
        `/auth/v1/profile/${profileId}/kv`
      )

      commit('setCustomUserMeta', allCarrierCustomUserMeta.response.data.kv)
    } catch (error) {
      this.$log.error(`Fetch custom user meta error`, error)
    }
  },

  async update({ commit }, { profileId, requestModel }) {
    try {
      const { response } = await new this.$Api(this.customUserMetaApi).put(`/auth/v1/profile/${profileId}/kv`, {
        kv: requestModel
      })

      commit('setCustomUserMeta', response.data.kv)
    } catch (error) {
      this.$log.error(`Custom user meta set for ${requestModel.key}`, error)
    }
  },

  async delete({ commit }, { profileId, keys }) {
    try {
      const { response } = await new this.$Api(this.customUserMetaApi).delete(`/auth/v1/profile/${profileId}/kv`, {
        keys
      })

      commit('setCustomUserMeta', response.data.kv)
    } catch (error) {
      this.$log.error('Custom user meta deleted', error)
    }
  }
}

export const mutations = {
  setCustomUserMeta(state, customUserMeta) {
    state.customUserMeta = customUserMeta
  },

  reset(state) {
    state.customUserMeta = []
  }
}
