export interface AssetLivestock {
  species: {
    id: string
  }
  breed: {
    id: string
  }
}

export interface AssetCrop {
  crop: {
    id: string
  }
  variety: {
    id: string
  }
}

export const assetIsAssetLivestock = (asset: AssetLivestock | AssetCrop): asset is AssetLivestock => {
  return (asset as AssetLivestock).species !== undefined
}
