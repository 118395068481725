import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    address: {},
    countries: [],
    regions: {},
    fetchAddressApi: new ApiModel(),
    fetchAddressByIdApi: new ApiModel(),
    fetchAddressLookupApi: new ApiModel(),
    fetchAddressFromWhat3WordsApi: new ApiModel(),
    fetchAddressFromCoordinatesApi: new ApiModel(),
    fetchCountriesApi: new ApiModel(),
    fetchRegionsApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  getCountryOptions: (state, getters, rootState) => {
    if (!rootState.inventory?.app?.coreConfig?.countries) return []

    return (
      Object.values(rootState.inventory?.app?.coreConfig?.countries).map(country => ({
        label: country.name,
        value: country.iso,
        meta: country
      })) ?? []
    )
  },

  getRegionOptionsByCurrentCountry: (_state, getters, _rootState, rootGetters) => {
    const currentCountry = rootGetters['inventory/app/getCurrentOrgCountryInfo']

    return (
      rootGetters['inventory/app/getRegionsForCurrentCountry']?.[currentCountry?.iso2]?.map(region => ({
        label: region.name,
        value: region.iso
      })) ?? []
    )
  },

  getRegionOptionsByCountryCode: state => countryCode => {
    return state.regions?.[countryCode]?.map(region => ({ label: region.name, value: region.iso }))
  },

  getCallingCodeOptions: (state, getters, rootState) => {
    if (!Object.keys(rootState.inventory.app.coreConfig?.countries ?? {}).length) return []

    return Object.values(rootState.inventory.app.coreConfig?.countries).map(country => ({
      label: `${country.name} ${country.callingCode}`,
      value: country.callingCode,
      countryCode: country.iso,
      callingCode: country.callingCode
      // Both codes as properties so inputTelephone logic
      // works if value is changed again
    }))
  }
}

export const actions = {
  async fetchAddress({ state, commit }, queryString) {
    try {
      const { response } = await this.$api
        .geo(state.fetchAddressApi)
        .useStorePath('inventory.geo.fetchAddressApi')
        .getAddress(queryString)

      commit('setAddress', response.data.address)
    } catch (error) {
      this.$log.error(error)
    }
  },

  async fetchAddressById({ state }, id) {
    try {
      const { response } = await this.$api
        .geo(state.fetchAddressByIdApi)
        .useStorePath('inventory.geo.fetchAddressByIdApi')
        .getAddressById(id)

      return response
    } catch (error) {
      this.$log.error(error)
      throw error
    }
  },

  async fetchAddressLookup({ state }, queryString) {
    try {
      const { response } = await this.$api
        .geo(state.fetchAddressLookupApi)
        .useStorePath('inventory.geo.fetchAddressLookupApi')
        .addressLookup(queryString)

      return response
    } catch (error) {
      this.$log.error(error)
      throw error
    }
  },

  async fetchAddressFromWhat3Words({ state }, queryString) {
    try {
      const { response } = await this.$api
        .geo(state.fetchAddressFromWhat3WordsApi)
        .useStorePath('inventory.geo.fetchAddressFromWhat3WordsApi')
        .getAddressFromWhat3Words(queryString)

      return response
    } catch (error) {
      this.$log.error('Error getting address from what3Words string', error)
      throw error
    }
  },

  async fetchAddressFromCoordinates({ state }, coordinates) {
    try {
      const { response } = await this.$api
        .geo(state.fetchAddressFromCoordinatesApi)
        .useStorePath('inventory.geo.fetchAddressFromCoordinatesApi')
        .getAddressFromCoordinates(coordinates)

      return response
    } catch (error) {
      this.$log.error('Error getting address from coordinates', error)
      throw error
    }
  },

  async fetchCountries({ state, commit }) {
    try {
      const { response } = await this.$api
        .geo(state.fetchCountriesApi)
        .useStorePath('inventory.geo.fetchCountriesApi')
        .getCountryOptions()

      commit('setCountries', response.data)
    } catch (error) {
      this.$log.error(error)
    }
  },

  async fetchRegionsByCountryCode({ state, commit }, countryCode, forceUpdate = false) {
    if (state.regions?.[countryCode] && !forceUpdate) {
      return
    }

    try {
      const { response } = await this.$api
        .geo(state.fetchRegionsApi)
        .useStorePath('inventory.geo.fetchRegionsApi')
        .getRegionOptionsByCountryCode(countryCode)

      commit(
        'setRegionsByCountryCode',
        {
          ...state.regions,
          [countryCode]: response.data?.regions
        } ?? {}
      )
    } catch (error) {
      this.$log.error(error)
    }
  }
}

export const mutations = {
  setAddress(state, address) {
    state.address = address
  },

  setCountries(state, countries) {
    state.countries = countries
  },

  setRegionsByCountryCode(state, regions) {
    state.regions = regions
  }
}
