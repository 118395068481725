// We need to always redirect with a locale base route. This can be easy to forget so let's take
// care of it automatically with context.$redirect
export default function ({ app, store }, inject) {
  const redirect = {
    to(route, replace = false) {
      if (replace) {
        app.router.replace(app.localeRoute(route))
      } else {
        app.router.push(app.localeRoute(route))
      }
    },

    back() {
      // If we are trying to go back when there is no previous history or the previous page is the current page then return to the index
      if (
        store.state.navigation.routeHistory.length < 2 ||
        !store.getters['navigation/previousRoutePath'] ||
        store.getters['navigation/previousRoutePath'] === store.getters['navigation/currentRoutePath']
      ) {
        app.router.push('/')
      } else {
        app.router.back()
      }
    }
  }

  inject('redirect', redirect)
}
