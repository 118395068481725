import { set } from 'lodash'

import currencyEnum from '~/lib/enums/currency'
import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    locationTracking: {
      currentLocation: {
        lngLat: null,
        lastUpdated: null
      },
      isDebugEnabled: false,
      isEnabled: false,
      hasCorrectPermissions: false,
      hasDeniedPermissions: false
    },
    flyToDriverCoords: [],
    countries: [],
    currentMapLogisticsRoute: null,
    fetchCountriesApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  getCountryOptions: state => {
    return state.countries.map(country => ({
      label: country.name,
      value: country.id,
      meta: { ...country }
    }))
  },

  getCountryByIso2: state => iso2 => {
    return state.countries.find(country => country.iso2 === iso2)
  },

  getCurrencyOptions: state => {
    if (!state.countries.length) {
      // Defaults for storybook
      return [
        {
          label: '£',
          value: 'GBP'
        },
        {
          label: '$',
          value: 'USD'
        },
        {
          label: '€',
          value: 'EUR'
        }
      ]
    }

    return state.countries.map(country => ({
      label: currencyEnum[country.currency],
      value: country.currency
    }))
  },

  isLocationTrackingReady: state => {
    return state.locationTracking.hasCorrectPermissions
  },

  hasDeniedLocationTrackingPermission: state => {
    return state.locationTracking.hasDeniedPermissions
  },

  currentCountry: (state, getters, rootState) => {
    return (
      rootState.auth.activeOrganisation.country || {
        iso2: 'GB'
      }
    )
  }
}

export const actions = {
  async fetchCountries({ commit, state }) {
    await new this.$Api(state.fetchCountriesApi, 'geo.fetchCountriesApi').get('/geo/v1/country')

    commit('setCountries', state.fetchCountriesApi.response.data)
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setCountries(state, countries) {
    state.countries = countries
  },

  setFlyToDriverCoords(state, lngLatArray) {
    state.flyToDriverCoords = lngLatArray
  },

  reset(state) {
    this.$log.debug('Resetting geo module')

    // Object.assign(state, initialState())

    state.countries = []
  },

  setCurrentMapLogisticsRoute(state, route) {
    state.currentMapLogisticsRoute = route
  }
}
