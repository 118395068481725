export default ({ app, $log }, inject) => {
  const iframe = {
    async embedInElement(url, el) {
      try {
        const response = await fetch(url)
        const content = await response.text()

        if (content.length) {
          el.innerHTML = content
        }
      } catch (err) {
        $log.error('Error embedding iframe', err)
      }
    }
  }

  inject('iframe', iframe)
}
