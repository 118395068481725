
export default {
  name: 'Flag',

  props: {
    // 2 letter country code
    countryCode: {
      type: String,
      default: null
    },

    // ISO 639-1 and ISO 3166-1 alpha-2 codes, separated by hyphen (e.g. 'en-US')
    localeCode: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: 'sm',
      validator: prop => ['xs', 'sm', 'md', 'lg', '2x', '3x'].includes(prop)
    }
  },

  computed: {
    compFilePath() {
      if (this.countryCode) {
        return `${this.countryCode.toLowerCase()}.svg`
      }

      if (this.localeCode) {
        return `locales/${this.localeCode}.svg`
      }

      return null
    }
  }
}
