export const contentfulTags = {
  ADVANCE_PAY_FAQS: 'advancePayFaqs'
}

export const contentfulSpaces = {
  GRAINDEX: 'graindex',
  HELP_CENTRE: 'helpCentre'
}

export const termsAndConditionsTags = {
  MAIN_TERMS_AND_CONDITIONS: 'mainTermsAndConditions',
  SELLER_TERMS_AND_CONDITIONS: 'sellerTermsAndConditions',
  BUYER_TERMS_AND_CONDITIONS: 'buyerTermsAndConditions',
  ADVANCE_PAY_TERMS_AND_CONDITIONS: 'advancePayTermsAndConditions'
}
