import { waitFor } from 'poll-until-promise'
import Vue from 'vue'

// TODO add a max retries/wait time option to the plugin

export default ({ app, $log }, inject) => {
  // Added support for a max wait
  const maxTimePolling = 60 * 1000 // two minutes

  const elementReady = {
    // Accepts ref key string and context (this)
    async ref(ref, context, poll = 200, timeout = maxTimePolling) {
      if (ref && context && typeof ref === 'string' && context instanceof Vue) {
        try {
          const element = await waitFor(
            () => {
              if (context.$refs[ref]) {
                return context.$refs[ref]
              } else {
                throw new Error('Element not found')
              }
            },
            { timeout: maxTimePolling, interval: poll }
          )

          return element
        } catch (error) {
          $log.error('Element not found', error)
        }
      } else {
        throw new Error('Must pass ref as string and component context as second param (this).')
      }
    },

    // CompositionAPI version
    async refCompositionApi(ref, poll = 200, timeout = maxTimePolling) {
      try {
        const isReady = await waitFor(
          () => {
            if (ref.value) {
              return true
            } else {
              throw new Error('Element not found')
            }
          },
          { timeout: maxTimePolling, interval: poll }
        )

        return isReady
      } catch (error) {
        $log.error('Element not found', error)
      }
    },

    async selector(selector, poll = 200, timeout = maxTimePolling) {
      try {
        const element = await waitFor(
          () => {
            if (document.querySelector(selector)) {
              return document.querySelector(selector)
            } else {
              throw new Error('Element not found')
            }
          },
          { timeout: maxTimePolling, interval: poll }
        )

        return element
      } catch (error) {
        $log.warn('Element not found', error)
      }
    }
  }

  inject('elementReady', elementReady)
}
