import { camelCase } from 'lodash'

import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    fetchUsersApi: new ApiModel(),
    fetchUserRolesApi: new ApiModel(),
    newUserApi: new ApiModel(),
    updateUserPerimssionsApi: new ApiModel(),
    deleteUserApi: new ApiModel(),
    resendPasswordApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  users: state => {
    return state.fetchUsersApi.response.data?.docs ?? []
  },

  usersIsloading: state => {
    return state.fetchUsersApi.isLoading
  },

  userRoles: state => {
    const userRoles = state.fetchUserRolesApi.response?.data ?? []

    return userRoles.map(roleCollection => {
      return {
        ...roleCollection,
        // API uses kebab-case for role-keys, converting to keep FE translations and model keys consistent
        id: camelCase(roleCollection.id)
      }
    })
  },

  resendPasswordIsloading: state => {
    return state.resendPasswordApi.isLoading
  }
}

export const actions = {
  async fetchUsers({ getters }, config = { limit: 25, offset: 0 }) {
    await this.$api.users(state.fetchUsersApi).useStorePath('inventory.users.fetchUsersApi').getUsers(config)
  },

  async fetchUserRoles({ getters }, forceUpdate = false) {
    if (forceUpdate || !getters.users.length) {
      await this.$api
        .users(this.fetchUserRolesApi)
        .useStorePath('inventory.users.fetchUserRolesApi')
        .getUserRoles()
    }
  },

  createUser({ state }, postModel) {
    return this.$api.users(state.newUserApi).useStorePath('inventory.users.newUserApi').createUser(postModel)
  },

  async updateUserPermissions({ state }, postModel) {
    await this.$api
      .users(state.updateUserPerimssionsApi)
      .useStorePath('inventory.users.updateUserPerimssionsApi')
      .editUser(postModel.id, postModel.model)
  },

  async deleteUser({ state }, userId) {
    await this.$api.users(state.deleteUserApi).useStorePath('inventory.users.deleteUserApi').deleteUser(userId)
  },

  resendPassword({ state }, userId) {
    return this.$api
      .users(state.resendPasswordApi)
      .useStorePath('inventory.users.resendPasswordApi')
      .resendPassword(userId)
  }
}
