import { NuxtI18nInstance } from '@nuxtjs/i18n'
import { TranslateResult } from 'vue-i18n'

import { weightBushelsMask, weightMask, weightTonnesMask } from '~/lib/input-masks/weight'
import { AssetCrop, assetIsAssetLivestock, AssetLivestock } from '~/lib/types/asset'
import { getLocaleFormattedNumber, removeTrailingZeros } from '~/lib/utility/numbers'

interface App {
  isInventory: boolean
  currentOrganisation: {
    country: {
      iso2: string
    }
  }
  localUnits: {
    weight: 'metric'
    currency: 'GBP'
  }
}

interface UnitTranslationConfig {
  displayUnit: TranslateResult
  displayUnitSingular: TranslateResult
  displayUnitPlural: TranslateResult
  displayUnitShort?: TranslateResult
}

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb',
  pound = 'pound',
  tonne = 'tonne',
  bushel = 'bushel'
}

const getUnitTranslationConfig = (i18n: NuxtI18nInstance, unitKey: WeightUnit, unitValue = 1) => {
  const pluralStringIndex = 2
  const singularStringIndex = 1
  const pluralisationIndex = unitValue === 1 ? singularStringIndex : pluralStringIndex

  const unitTranslationConfig: { [key in WeightUnit]: UnitTranslationConfig } = {
    kg: {
      displayUnit: i18n.tc('unitKg', pluralisationIndex),
      displayUnitSingular: i18n.t('kg'),
      displayUnitPlural: i18n.t('kg')
    },
    lb: {
      displayUnit: i18n.tc('unitLb', pluralisationIndex),
      displayUnitSingular: i18n.t('pound'),
      displayUnitPlural: i18n.t('pounds')
    },
    pound: {
      displayUnit: i18n.tc('unitLb', pluralisationIndex),
      displayUnitSingular: i18n.t('pound'),
      displayUnitPlural: i18n.t('pounds')
    },
    tonne: {
      displayUnit: ` ${i18n.tc('unitTonnes', pluralisationIndex)}`,
      displayUnitSingular: i18n.t('tonne'),
      displayUnitPlural: i18n.t('tonnes'),
      displayUnitShort: i18n.t('unitTonneShort')
    },
    bushel: {
      displayUnit: ` ${i18n.tc('unitBushels', pluralisationIndex)}`,
      displayUnitSingular: i18n.t('bushel'),
      displayUnitPlural: i18n.t('bushels'),
      displayUnitShort: i18n.t('bushelsShort')
    }
  }

  return unitTranslationConfig[unitKey]
}

export const unitConversions: { weight: { [key in WeightUnit]: number } } = {
  weight: {
    kg: 1,
    tonne: 0.001,
    bushel: 0.05,
    lb: 2.205,
    pound: 2.205
  }
}

const unitConfig = {
  weight: {
    default: {
      metric: {
        unitKey: WeightUnit.kg,
        conversionFactor: unitConversions.weight.kg
      },
      imperial: {
        unitKey: WeightUnit.lb,
        conversionFactor: unitConversions.weight.lb
      }
    },
    VEHICLE: {
      metric: {
        unitKey: WeightUnit.tonne,
        conversionFactor: unitConversions.weight.tonne
      },
      imperial: {
        unitKey: WeightUnit.lb,
        conversionFactor: unitConversions.weight.lb
      }
    },
    LIVESTOCK: {
      metric: {
        unitKey: WeightUnit.kg,
        conversionFactor: unitConversions.weight.kg
      },
      imperial: {
        unitKey: WeightUnit.lb,
        conversionFactor: unitConversions.weight.lb
      }
    },
    GRAIN: {
      metric: {
        unitKey: WeightUnit.kg,
        conversionFactor: unitConversions.weight.kg
      },
      imperial: {
        unitKey: WeightUnit.lb,
        conversionFactor: unitConversions.weight.lb
      }
    }
  }
}

export interface GetConfigParams {
  asset?: AssetLivestock | AssetCrop | null
  commodity?: keyof typeof unitConfig.weight | null
  measurement: keyof typeof unitConfig
  unitValue: number
}

export const getConfig = (
  { asset, commodity, measurement = 'weight', unitValue = 0 }: GetConfigParams,
  app: App,
  i18n: NuxtI18nInstance
) => {
  const unitSystem: 'metric' | 'imperial' = (
    {
      capacity: 'metric',
      distance: 'imperial',
      temperature: 'metric',
      weight: 'metric'
    } as Record<string, 'metric' | 'imperial'>
  )[measurement]

  if (asset) {
    if (assetIsAssetLivestock(asset)) {
      if (!commodity) {
        commodity = 'LIVESTOCK'
      }
    } else if (!commodity) {
      commodity = 'GRAIN'
    }
  } else if (!commodity) {
    commodity = 'default'
  }

  if (!unitConfig[measurement][commodity]) {
    console.warn(`No unit config found for measurement: ${measurement}, commodity: ${commodity}`)
    commodity = 'default'
  }

  const config = unitConfig[measurement][commodity][unitSystem]

  const translationConfig = getUnitTranslationConfig(i18n, config.unitKey, unitValue)

  return {
    ...config,
    ...translationConfig,
    unitValueKg: unitValue,
    unitValueConverted: unitValue ? unitValue * config.conversionFactor : unitValue
  }
}

export const getConversionFactor = ({
  measurement = 'weight',
  unit
}: {
  measurement: keyof typeof unitConversions
  unit: WeightUnit | string
}) => {
  return unitConversions[measurement][unit as WeightUnit]
}

export const getUnitTranslations = (i18n: NuxtI18nInstance, unitKey: WeightUnit | string, unitValue?: number) => {
  return getUnitTranslationConfig(i18n, unitKey as WeightUnit, unitValue)
}

export const getUnitMask = (
  unitName: WeightUnit | string,
  maskMin?: number,
  maskMax?: number,
  maskPadFractionalZeros?: boolean
) => {
  if (['tonne', 'tonnes', 't'].includes(unitName)) {
    return weightTonnesMask(maskMin, maskMax, maskPadFractionalZeros)
  }

  if (['bushel', 'bushels'].includes(unitName)) {
    return weightBushelsMask(maskMin, maskMax, maskPadFractionalZeros)
  }

  return weightMask(maskMin, maskMax, maskPadFractionalZeros)
}

export const formatDecimals = (unitValue: number, fixedDecimals = 2): number => {
  return removeTrailingZeros(unitValue.toFixed(fixedDecimals))
}

export const getFullDisplayWeight = (
  i18n: NuxtI18nInstance,
  unitValue: number,
  unitKey: WeightUnit | string,
  fixedDecimals = 2,
  locale?: string
): string => {
  const localeParam: string = locale ?? i18n.localeProperties.iso ?? 'GB'

  const valueFormatted = getLocaleFormattedNumber(formatDecimals(unitValue, fixedDecimals), localeParam)
  const unitFormatted = getUnitTranslations(i18n, unitKey, unitValue).displayUnit

  return `${valueFormatted} ${unitFormatted}`
}

export const getShortDisplayWeight = (
  i18n: NuxtI18nInstance,
  unitValue: number,
  unitKey: WeightUnit | string,
  fixedDecimals = 2,
  locale?: string
): string => {
  const localeParam: string = locale ?? i18n.localeProperties.iso ?? 'GB'

  const valueFormatted = getLocaleFormattedNumber(formatDecimals(unitValue, fixedDecimals), localeParam)
  const unitFormatted = getUnitShort(i18n, unitKey, true)

  return `${valueFormatted}${unitFormatted}`
}

export const getUnitShort = (
  i18n: NuxtI18nInstance,
  unitKey: WeightUnit | string,
  shouldFallbackAddSpace = false
): TranslateResult => {
  const unitTranslations = getUnitTranslations(i18n, unitKey)

  return (
    unitTranslations.displayUnitShort ||
    `${shouldFallbackAddSpace ? ' ' : ''}${unitTranslations.displayUnitPlural}`
  )
}

export const getFormattedAreaYield = (
  i18n: NuxtI18nInstance,
  yieldAmount: number,
  unitKey: WeightUnit,
  locale: string
) => {
  const units = i18n.t('areaUnitShort')
  const formattedYield = getShortDisplayWeight(i18n, yieldAmount, unitKey, 2, locale)

  return `${formattedYield}/${units}`
}

export const getAreaYieldUnits = (i18n: NuxtI18nInstance, weightUnit: WeightUnit) => {
  const weightUnitFormatted = getUnitShort(i18n, weightUnit, true)
  const areaUnitFormatted = i18n.t('areaUnitShort')

  return `${weightUnitFormatted}/${areaUnitFormatted}`
}

export const getFormattedArea = (i18n: NuxtI18nInstance, area: number, short = false) => {
  const areaUnit = short ? i18n.t('areaUnitShort') : i18n.tc('areaUnits', area)
  const delimiter = short ? '' : ' '

  return `${area}${delimiter}${areaUnit}`
}
