import { ApiInventory } from '~/lib/api-inventory'
import { Sample } from '~/lib/types/inventory/sample'

export class TradedService extends ApiInventory {
  fetchAllTrades() {
    return this.get('/trading/contracts?docs=false&format=traded')
  }

  fetchTradeDetails(url: string) {
    return this.get(url)
  }

  deleteSalesContractFile(contractId: string, fileId: string) {
    return this.delete(`/trading/contracts/${contractId}/files/${fileId}`)
  }

  addDeliverySample(params: { contractId: string; deliveryId: string; sample: Sample }) {
    return this.post(
      `/trading/contracts/${params.contractId}/deliveries/${params.deliveryId}/sample`,
      params.sample
    )
  }

  getBuyerDashboard() {
    return this.get('/trading/buyer/dashboard')
  }
}
