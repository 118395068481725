import { Context } from '@nuxt/types'
import Vue from 'vue'

export type ApiModelType<T> = {
  isLoading: boolean
  hasError: boolean
  uploadProgress: number
  model: T | null
  response: {
    code: number | null
    status: string | null
    message: string | null
    data: T | null
    pagination: unknown
    errors: unknown[]
    headers: Record<string, string>
    validation: unknown[]
  }
  timeMethodLastCalled: {
    GET: number
    POST: number
    DELETE: number
    PATCH: number
    PUT: number
    HEAD: number
  }
}

export type ApiModelBuilder = <T extends object>(existingModel?: T) => ApiModelType<T>

export const ApiModel: ApiModelBuilder = function <T>(existingModel?: T): ApiModelType<T> {
  return Vue.observable({
    isLoading: false,
    hasError: false,
    uploadProgress: 0,
    // Logistic relies on model being at least an empty object
    // new world needs this as null if `existingModel` is undefined
    model: existingModel ? { ...existingModel } : ({} as unknown as null),
    response: {
      code: null,
      status: null,
      message: null,
      // Logistics relies on data being at least an empty object
      // new world should see this as null unless there is actual data
      data: [] as unknown as null,
      pagination: {},
      errors: [],
      headers: {},
      validation: []
    },
    // Used for throttling requests per method
    timeMethodLastCalled: {
      GET: 0,
      POST: 0,
      DELETE: 0,
      PATCH: 0,
      PUT: 0,
      HEAD: 0
    }
  })
}

type Inject = (name: string, extension: ApiModelBuilder) => void

export default function (_context: Context, inject: Inject) {
  // Inject to context as $ApiModel
  inject('ApiModel', ApiModel)
}
