import { ContractDetail } from '@hectare/platform.clients.trading'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'

export class SalesContractService extends ApiInventory {
  getSalesContract(id: string): Promise<ContractDetail> {
    return this.get(`/trading/contracts/${id}`)
  }

  deleteSalesContract(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/trading/contracts/${id}`)
  }

  deleteSalesContractFiles(contractId: string, fileId: string): Promise<ContractDetail> {
    return this.delete(`/trading/contracts/${contractId}/files/${fileId}`)
  }
}
