import { CreateOrUpdateTargetPricesSchema, TargetPriceDetailSchema } from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'

export class TargetPricesService extends ApiInventory {
  getTargetPrices(): Promise<TargetPriceDetailSchema[]> {
    return this.get('/trading/target-prices')
  }

  createOrUpdateTargetPrices(postModel: CreateOrUpdateTargetPricesSchema): Promise<TargetPriceDetailSchema> {
    return this.post('/trading/target-prices', postModel)
  }
}
