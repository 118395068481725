import { difference } from 'lodash'

export default function ({ store, app }, inject) {
  const modal = {
    async open(modalName, modalData = {}, dataCallback, isSyncedWithUrl = true) {
      // If we need to use the data in a callback then sharing a URL would break this
      // so let's disable the URL syncing
      if (dataCallback) {
        isSyncedWithUrl = false
      }

      await store.dispatch('modal/open', {
        modalName,
        modalData,
        dataCallback,
        isSyncedWithUrl
      })

      this.updateActionUrl()
    },

    async close(modalName = null, updateActionUrl = true) {
      if (modalName) {
        await store.dispatch('modal/closeByName', modalName)

        if (updateActionUrl) {
          this.updateActionUrl()
        }
      } else {
        this.closeAll(updateActionUrl)
      }
    },

    async closeAll(updateActionUrl = true) {
      await store.dispatch('modal/closeAll')

      if (updateActionUrl) {
        this.updateActionUrl()
      }
    },

    // If all modals have closed and we had an ?action in our URL, let's remove it
    // We use window.$nuxt.$route because context.route isn't reactive here
    updateActionUrl: () => {
      if (!window.$nuxt.$route.query.action) {
        return false
      }

      const modalList = store.getters['modal/allOpenUrlSyncedFlatList'].reverse()

      if (modalList.length === 0) {
        const queries = { ...window.$nuxt.$route.query }

        delete queries.action

        app.router.push({ query: queries })
      } else {
        app.router.push({
          query: { ...window.$nuxt.$route.query, action: modalList.join(',').replace('/', '~') }
        })
      }
    },

    // When the user navigates back and forth let's compare our open modals to the current URL
    // and close any that should no longer be open or open ones if we previously closed all
    syncActionUrl() {
      // We convert slashes to tidle to make it more readable in the URL, so we need to convert it back
      const urlModals = window.$nuxt.$route.query.action
        ? window.$nuxt.$route.query.action.replace('~', '/').split(',')
        : []
      const openModals = store.getters['modal/allOpenUrlSyncedFlatList']

      if (openModals.length > urlModals.length) {
        const modalsToClose = difference(openModals, urlModals)

        modalsToClose.forEach(modalName => this.close(modalName))
      } else if (urlModals.length > openModals.length) {
        const modalsToOpen = difference(urlModals, openModals)

        modalsToOpen.forEach(modalName => this.open(modalName))
      }
    }
  }

  // Inject to context as $modal
  inject('modal', modal)
}
