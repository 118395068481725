import { render, staticRenderFns } from "./AppLoader.vue?vue&type=template&id=48895aca"
import script from "./AppLoader.vue?vue&type=script&lang=js"
export * from "./AppLoader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/image/Img.vue').default,Notification: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/notification/Notification.vue').default,Loader: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/utils/loader/Loader.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default})
