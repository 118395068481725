import {
  AdvancePayOfferSchema,
  BidContract,
  BidDetail,
  ExpiredBidOffer,
  InsightPrices,
  PatchBid
} from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'
import { BidAccept } from '~/lib/types/market-listing/bid'
import { composeSearchParams } from '~/lib/utility/url'

export class BidsService extends ApiInventory {
  getBid(bidId: string): Promise<BidDetail> {
    return this.get(`/trading/bids/${bidId}`)
  }

  patchBid(bidId: string, patchBid: PatchBid): Promise<BidDetail> {
    return this.patch(`/trading/bids/${bidId}`, patchBid)
  }

  withdrawBid(bidId: string): Promise<BidDetail> {
    return this.patch(`/trading/bids/${bidId}/withdraw`)
  }

  acceptBid(bidId: string, bidDetails: BidAccept): Promise<BidDetail> {
    return this.patch(`/trading/bids/${bidId}/accept`, bidDetails)
  }

  attachContractDetails(bidId: string, contractDetails: BidContract): Promise<BidDetail> {
    return this.patch(`/trading/bids/${bidId}/contract`, contractDetails)
  }

  getLatestPrices(searchParams = ''): Promise<InsightPrices[]> {
    return this.get(`/trading/insights/prices${searchParams}`)
  }

  expiredBidOffer(bidId: string, bid: ExpiredBidOffer): Promise<BidDetail> {
    return this.post(`/trading/bids/${bidId}/expired-bid-offer`, bid)
  }

  acceptTradeRequestOnExpiredBid(bidId: string): Promise<BidDetail> {
    return this.post(`trading/bids/${bidId}/expired-bid-accept-offer`)
  }

  declineExpiredBidOffer(bidId: string): Promise<BidDetail> {
    return this.post(`trading/bids/${bidId}/expired-bid-decline-offer`)
  }

  getBidAdvancePayOffer(
    bidId: string,
    bidOptionId: string,
    tonnesToSell?: number
  ): Promise<AdvancePayOfferSchema> {
    const searchParams = composeSearchParams({ tonnesToSell })

    return this.get(
      `/trading/bids/${bidId}/options/${bidOptionId}/advance-pay/offer${searchParams ? `?${searchParams}` : ''}`
    )
  }

  acceptAdvancePayOffer(bidId: string, bidOptionId: string): Promise<AdvancePayOfferSchema> {
    return this.post(`/trading/bids/${bidId}/options/${bidOptionId}/advance-pay/accept-offer`)
  }
}
