export default function ({ store, app }, inject) {
  const notify = {
    add(title, type, position = 'sticky', data = null, message) {
      store.dispatch('notifications/add', {
        title,
        message,
        type,
        position,
        data
      })
    },

    // type='sticky' means the notification is at the top of the page, inline with the content
    // and persists through 1 page load. Generally used when showing a semi permanent success title
    // after a form submit and redirect
    success(title, message = null, data = null, position = 'floating') {
      this.add(title, 'success', position, data, message)
    },

    information(title, message = null, data = null, position = 'floating') {
      this.add(title, 'information', position, data, message)
    },

    warning(title, message = null, data = null, position = 'floating') {
      this.add(title, 'warning', position, data, message)
    },

    error(title, message = null, data = null, position = 'floating') {
      this.add(title, 'error', position, data, message)
    },

    errorGeneric(data = null, position = 'floating') {
      this.add(app.i18n.t('somethingWentWrong'), 'error', position, data)
    }
  }

  // Every time the route changes (fired on initialization too)
  // Used to auto hide sticky notifications if we've changed page twice
  app.router.afterEach((to, from) => {
    store.commit('notifications/routeChange')
  })

  // Inject to context as $notify
  inject('notify', notify)
}
