import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'

export default () => {
  if (process.env.APP_ENVIRONMENT !== 'production') {
    return
  }

  const configDefaults = {
    ignoreNetworkEvents: true
  }

  const sdk = new HoneycombWebSDK({
    endpoint: 'https://api.eu1.honeycomb.io/v1/traces',
    debug: process.env.APP_ENVIRONMENT !== 'production',
    apiKey: process.env.HONEYCOMB_API_KEY,
    serviceName: 'hectaretrading',
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': configDefaults,
        '@opentelemetry/instrumentation-fetch': configDefaults,
        '@opentelemetry/instrumentation-document-load': configDefaults
      })
    ]
  })

  sdk.start()
}
