
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { getRandomColorSchemaFromStringCount } from '~/lib/utility/colors'
import { hasLoadingSkeletons } from '~/lib/utility/feature-flags'

export default {
  name: 'Avatar',

  props: {
    avatarId: {
      type: String,
      default: null
    },

    togglePopoverOnClick: {
      type: Boolean,
      default: true
    },

    idType: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: 'lg',
      validator: prop => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(prop)
    },

    // You can pass the full name to save having to work out initials
    initials: {
      type: String,
      default: null
    },

    imageUrl: {
      type: String,
      default: null
    },

    topRow: {
      type: String,
      default: null
    },

    bottomRow: {
      type: String,
      default: null
    },

    status: {
      type: String,
      default: null,
      validator: prop => ['ONLINE', 'BUSY', 'AWAY'].includes(prop)
    },

    includeDefaultOptions: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default: () => []
    },

    hasRandomColour: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click'],

  data() {
    return {
      fetchAvatarApi: new this.$ApiModel(),
      hasLoadingSkeletons,
      faChevronDown,
      avatar: {
        initials: null,
        topRow: null,
        bottomRow: null,
        imageUrl: null
      },

      popoverConfig: {
        placement: 'bottom-end'
      }
    }
  },

  computed: {
    compImageAlt() {
      return this.compInitials || ''
    },

    displayedInitials() {
      if (this.isLoading) {
        return ''
      }

      return !this.compImageUrl ? this.compInitials : ''
    },

    compIsNameVisible() {
      return this.compTopRow || this.bottomRow || this.$slots.topRow || this.$slots.bottomRow
    },

    compHasDropdown() {
      return this.compDropdownOptions.length > 0
    },

    compDropdownOptions() {
      let options = [...this.options]

      if (
        this.fetchAvatarApi.response.data.profileCard &&
        (this.fetchAvatarApi.response.data.profileCard.email ||
          this.fetchAvatarApi.response.data.profileCard.contactNumber)
      ) {
        options = [
          {
            label: `Email ${this.fetchAvatarApi.response.data.profileCard.name}`,
            active: this.fetchAvatarApi.response.data.profileCard.email,
            onClick: () =>
              (document.location.href = `mailto:${this.fetchAvatarApi.response.data.profileCard.email}`)
          },
          {
            label: this.fetchAvatarApi.response.data.profileCard.contactNumber,
            active: this.fetchAvatarApi.response.data.profileCard.contactNumber,
            onClick: () =>
              (document.location.href = `tel:${this.fetchAvatarApi.response.data.profileCard.contactNumber}`)
          }
        ]
      }

      if (this.includeDefaultOptions) {
        options = [
          ...options,
          {
            label: 'Contact'
          }
        ]
      }

      return options.filter(item => !('active' in item) || item.active)
    },

    compClass() {
      let classes = `size-${this.size}`

      if (this.status) {
        classes += ` ${this.status.toLowerCase()}`
      }

      return classes
    },

    compInitials() {
      const initial = this.fetchAvatarApi.response.data.profileCard?.name ?? this.initials

      if (!initial) {
        return ''
      }

      const words = initial.split(' ')
      let letters = ''

      if (words.length > 1) {
        // take first character from each substring and join together
        letters = words.map(i => i.charAt(0)).join('')
      } else {
        letters = initial
      }

      return letters.length < 3 ? letters.toUpperCase() : letters.substring(0, 2).toUpperCase()
    },

    compTopRow() {
      return this.fetchAvatarApi.response.data.profileCard?.name ?? this.topRow
    },

    compImageUrl() {
      if (this.imageUrl) {
        return this.imageUrl
      }

      return this.fetchAvatarApi.response.data.profileCard?.avatar?.clientUrl
        ? `${this.fetchAvatarApi.response.data.profileCard?.avatar?.clientUrl}?width=70&height=70`
        : null
    },

    avatarColour() {
      return this.hasRandomColour ? getRandomColorSchemaFromStringCount(this.compInitials) : false
    },

    avatarStyle() {
      if (this.compImageUrl) {
        return null
      }

      return {
        background: this.avatarColour.background,
        color: this.avatarColour.textShade
      }
    }
  },

  created() {
    this.fetchProfileCard()
  },

  methods: {
    handleClick() {
      if (this.togglePopoverOnClick) {
        this.$refs.popover.toggle()
      }
    },

    async fetchProfileCard() {
      if (this.avatarId) {
        const apiUrl = this.idType === 'profile' ? '/auth/v1/profile/' : '/auth/v1/organisation/'

        this.fetchAvatarApi.isLoading = true

        await new this.$api(this.fetchAvatarApi).get(`${apiUrl}${this.avatarId}`)
      }
    },

    onClick(option) {
      if (option.onClick) {
        option.onClick()
      }
    }
  }
}
