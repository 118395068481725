export default function ({ $modal, app }, inject) {
  const confirm = ({
    title,
    description,
    centerDescription,
    postamble,
    iframeUrl,
    cancelButtonLabel,
    confirmButtonLabel,
    confirmButtonPriority,
    carrierTermsAndConditions,
    icon,
    type,
    hasDismissButton,
    allowClose,
    allowOverlayClose,
    descriptionBinding,
    contentComponent,
    componentBinding,
    shouldDisplayMobileBackButton,
    shouldHideCancelButton,
    iconCustomClasses
  } = {}) => {
    return new Promise((resolve, reject) => {
      $modal.open(
        'global/confirm',
        {
          title,
          description,
          centerDescription,
          postamble,
          iframeUrl,
          cancelButtonLabel,
          confirmButtonLabel,
          confirmButtonPriority,
          carrierTermsAndConditions,
          icon,
          type,
          hasDismissButton,
          allowClose,
          allowOverlayClose,
          contentComponent,
          componentBinding,
          shouldDisplayMobileBackButton,
          shouldHideCancelButton,
          iconCustomClasses
        },
        response => {
          // We decided to resolve the promise on cancel rather than reject because it would mean a
          // lot of empty catches as we often don't want to take any action if they cancel the action
          resolve(response.hasConfirmed)
        }
      )
    })
  }

  // Inject to context as $confirm
  inject('confirm', confirm)
}
