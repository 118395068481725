import { get } from 'lodash'

import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    getGroupsApi: new ApiModel(),
    newGroupApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  getGroupOptions: state => {
    return get(state.getGroupsApi, 'response.data', []).map(group => ({
      label: group.name,
      value: group.id
    }))
  },

  getGroups: state => {
    return get(state.getgroupsApi, 'response.data', [])
  }
}

export const actions = {
  async fetch({ state, commit }, requestModel = {}) {
    commit(
      'setGlobalState',
      {
        statePath: 'groups.getGroupsApi.model',
        newState: requestModel
      },
      { root: true }
    )

    await new this.$Api(state.getGroupsApi, 'groups.getGroupsApi').get('/auth/v1/group', {}, requestModel)
  },

  async saveNew({ state, commit }, requestModel) {
    commit(
      'setGlobalState',
      {
        statePath: 'groups.newGroupApi.model',
        newState: requestModel
      },
      { root: true }
    )

    await new this.$Api(state.newGroupApi, 'groups.newGroupApi').post('/auth/v1/group')
  }
}

export const mutations = {
  reset(state) {
    this.$log.debug('Resetting groups module')

    Object.assign(state, initialState())
  }
}
