import safeJsonStringify from 'safe-json-stringify'

import LogEntity from '~/lib/entities/log'

export default function ({ $sentry, store }, inject) {
  const log = {
    error(message, error, save = true) {
      console.error(message, error)

      if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
        const reportedError = new Error(message)

        reportedError.name = error?.data?.status ?? error?.status
        reportedError.cause = error?.data?.message
        reportedError.stack = error?.data?.validation ?? error?.data?.stack
        reportedError.message = message

        $sentry.captureException(reportedError)
      }

      if (save) {
        this.addToQueue(message, error, 'error')
      }
    },

    debug(message, data, save = true) {
      if (typeof data === 'undefined') {
        console.log(message)
      } else {
        console.log(message, data)
      }

      if (save) {
        this.addToQueue(message, data, 'debug')
      }
    },

    warn(message, data, save = true) {
      if (typeof data === 'undefined') {
        console.warn(message)
      } else {
        console.warn(message, data)
      }

      if (save) {
        this.addToQueue(message, data, 'warn')
      }
    },

    addToQueue(message, data, level) {
      // If error type then convert to string first
      data = Object.prototype.toString.call(data) === '[object Error]' ? data.toString() : data

      // Only JSON stringify if data isn't a primitive value
      store.commit(
        'logs/addToQueue',
        new LogEntity({ message, data: typeof data === 'object' ? safeJsonStringify(data) : { data }, level })
          .model
      )
    }
  }

  inject('log', log)
}
