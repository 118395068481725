import { ApiInventory } from '~/lib/api-inventory'

export class BusinessUnitService extends ApiInventory {
  createBusinessUnit(postModel) {
    return this.post('/customers/business-units', postModel)
  }

  editBusinessUnit(id, postModel) {
    return this.patch(`/customers/business-units/${id}`, postModel)
  }

  deleteBusinessUnit(businessUnitId) {
    return this.delete(`/customers/business-units/${businessUnitId}`)
  }

  fetchBusinessById(businessId) {
    return this.get(`/customers/business/${businessId}`)
  }

  fetchAllBusinessUnitsList(organisationId) {
    return this.get(`/customers/business-units/${organisationId}`)
  }

  fetchRedTractorCertificate(fetchModel) {
    return this.get('/customers/business/red-tractor', fetchModel)
  }

  fetchBusinessUnitAreaSummary(id) {
    return this.get(`/customers/business-units/${id}/summary`)
  }
}
