// We need to be able to upload files outside of the FileUploadCore component
// For example when uploading offline logistics driver data in the offline FarmTo app

import fileCategories from '~/lib/enums/file-categories'

export default function ({ $log, $Api, $api, app, route, $config, store }, inject) {
  const defaultApiPath = '/system/files'

  const uploadFile = async function (file, category, customFileData) {
    const publicFileTypes = [fileCategories.LOGO, fileCategories.AVATAR]

    const bucket = publicFileTypes.includes(category) ? 'images' : 'files'
    const type = category

    const formDataModel = new FormData()

    formDataModel.append('file', file.rawFile)
    formDataModel.append('bucket', bucket)
    formDataModel.append('type', type)
    formDataModel.append('name', file.name)

    if (file.organisationId) {
      formDataModel.append('organisationId', file.organisationId)
    }

    if (file.fileAccessOrganisations) {
      formDataModel.append('organisations', file.fileAccessOrganisations)
    }

    const { response } = await $api.file(file.uploadApi).upload(formDataModel)

    return {
      ...response?.data,
      clientUrl: response?.data?.url,
      fileId: response?.data?.id,
      name: response.data.name, // Name to be sent to BE
      alias: file.name // Name to show on FE display
    }
  }

  const file = {
    async upload(file, category, customFileData = [], fileApiPath = defaultApiPath) {
      try {
        $log.debug('Starting file upload', {
          name: file.name,
          type: file.type,
          size: file.size,
          category,
          originalSize: file.originalSizeHuman
        })

        file.errorMessage = null

        let fileResponse = {
          clientUrl: null,
          fileId: null
        }

        fileResponse = await uploadFile(file, category, customFileData)

        // Success! update data that can now be used by client
        file = { ...file, ...fileResponse }

        $log.debug('File finished uploading', file.name)
        return file
      } catch (error) {
        $log.error(`Error uploading file ${file.name}`, error)

        file.errorMessage = app.i18n.t('uploadFailedTryAgain')

        throw new Error(error)
      }
    },

    downloadFile(file, filename) {
      const newBlob = new Blob([file])
      const reader = new FileReader()

      reader.readAsDataURL(newBlob)
      reader.onload = event => {
        const a = document.createElement('a')

        a.download = newBlob
        a.href = event.target.result
        a.setAttribute('download', filename)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },

    async delete(api, fileId) {
      try {
        await $api.file(api).deleteFile(fileId)
      } catch (error) {
        $log.error(`Error deleting file ${fileId}`, error)
        throw new Error(error)
      }
    }
  }

  // Inject to context as $file
  inject('file', file)
}
