import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

import { isNative } from '~/plugins/native/capacitor'

export default function ({ $log }) {
  const logDeviceDetails = async () => {
    const [deviceId, channel, builtInVersion] = await Promise.all([
      CapacitorUpdater.getDeviceId(),
      CapacitorUpdater.getChannel(),
      CapacitorUpdater.getBuiltinVersion()
    ])

    $log.debug('Capgo Device ID', deviceId)
    $log.debug('Capgo Channel', channel)
    $log.debug('Capgo Built in Version', builtInVersion)
  }

  if (isNative) {
    CapacitorUpdater.setChannel({ channel: process.env.APP_ENVIRONMENT, triggerAutoUpdate: true })

    CapacitorUpdater.addListener('appReady', () => {
      SplashScreen.hide()
    })

    CapacitorUpdater.notifyAppReady()

    logDeviceDetails()
  }
}
