import { FileSharer } from '@byteowls/capacitor-filesharer'
import { set as vueSet } from 'vue'

import { blobToBase64 } from '~/lib/files/file-size'
import { ApiModel } from '~/plugins/api-logistics/api-model'
import { isNative } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    fileIdsUploading: [],
    fileDownloadApis: {}
  }
}

export const state = () => initialState()

export const getters = {
  hasFilesUploading(state, getters) {
    return state.fileIdsUploading.length > 0
  },

  isFileIdCurrentlyUploading: state => currentFileId => {
    return state.fileIdsUploading.includes(currentFileId)
  }
}

export const mutations = {
  addFileIdCurrentlyUploading(state, currentFileId) {
    state.fileIdsUploading.push(currentFileId)
  },

  removeFileIdsCurrentlyUploading(state, currentFileId) {
    state.fileIdsUploading = state.fileIdsUploading.filter(fileId => fileId !== currentFileId)
  },

  resetAllFilesCurrentlyUploading(state) {
    state.fileIdsUploading = []
  },

  setFileDownloadApi(state, { fileId }) {
    vueSet(state.fileDownloadApis, fileId, new ApiModel())
  }
}

export const actions = {
  async downloadFileById({ state, commit, rootState }, fileId) {
    try {
      commit('setFileDownloadApi', { fileId })
      const { response } = await this.$api
        .file(state.fileDownloadApis[fileId])
        .useStorePath(`file.fileDownloadApis.${fileId}`)
        .download(fileId)

      const newBlob = new Blob([response.data])
      const reader = new FileReader()

      // Get filename from the response headers
      const disposition = response.headers['content-disposition']
      let filename = ''

      if (disposition && disposition.includes('attachment')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      reader.readAsDataURL(newBlob)
      reader.onload = event => {
        const a = document.createElement('a')

        a.download = filename
        a.href = event.target.result
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

      if (isNative) {
        let base64 = await blobToBase64(newBlob)

        base64 = base64.split(',')[1]

        FileSharer.share({
          filename,
          base64Data: base64,
          contentType: 'image/*'
        })
      }
    } catch (error) {
      this.$log.error('Error downloading file', error)
      throw error
    }
  }
}
