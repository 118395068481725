export const businessUnitType = {
  REGION: 'region',
  FARM: 'farm',
  STORE: 'stores'
}

export const businessUnitTag = [
  {
    type: businessUnitType.REGION,
    colour: 'blue'
  },
  {
    type: businessUnitType.FARM,
    colour: 'yellow'
  },
  {
    type: businessUnitType.STORE,
    colour: 'yellow'
  }
]
