import { ApiInventory } from '~/lib/api-inventory'

export class AuthService extends ApiInventory {
  login({ ignoreNotifications = false, ...postModel }) {
    let payload

    if (postModel?.code) {
      payload = {
        username: postModel.identifier,
        password: postModel.code,
        newPassword: postModel.secret
      }
    } else {
      payload = {
        username: postModel.identifier,
        password: postModel.secret
      }
    }

    return this.post(`/auth/login`, payload, { ignoreNotifications })
  }

  logout(accessToken) {
    return this.get('/auth/logout', {}, { headers: { Authorization: `Bearer ${accessToken}` } })
  }

  fetchRefreshToken(refreshToken) {
    return this.get(
      '/auth/tokens',
      {},
      {
        headers: { 'x-authorization-refresh': `Bearer ${refreshToken}` }
      }
    )
  }

  forgotPassword(emailAddress) {
    return this.post(`/auth/forgotten-password`, {
      username: emailAddress
    })
  }

  changePassword(postModel) {
    return this.post(`/auth/change-password`, postModel)
  }

  requestPasswordReset(identifier) {
    return this.post(`/auth/forgotten-password`, {
      username: identifier
    })
  }

  changeMarketplacePassword(postModel) {
    return this.post(`/auth/user/change-marketplace-password`, {
      email: postModel.identifier,
      newPassword: postModel.newPassword,
      confirmNewPassword: postModel.confirmNewPassword,
      oldPassword: postModel.oldPassword
    })
  }

  resetPassword(postModel) {
    return this.post(`/auth/reset-password`, {
      username: postModel.identifier,
      password: postModel.newPassword,
      passwordConfirm: postModel.confirmNewPassword,
      confirmationCode: postModel.code
    })
  }

  resendTemporaryPassword(postModel) {
    return this.get(`/auth/resend-password/${postModel.identifier}`)
  }

  isEmailRegistered(postModel) {
    return this.get('/customers/business-connections/exists', {
      email: postModel.email,
      name: postModel.businessName,
      countryISO: postModel.countryCode
    })
  }

  inviteUser(customerInvite) {
    return this.post('/customers/invite', customerInvite)
  }

  customerSignUp(customerSignUpFormData) {
    return this.post('/customers/signup', customerSignUpFormData)
  }

  customerSignUpFromInvite(customerSignUpFormData, inviteId) {
    return this.post(`/customers/signup/${inviteId}`, customerSignUpFormData)
  }

  getMyProfile() {
    return this.get('/customers/users/me')
  }

  updateUserProfile(id, postModel) {
    return this.patch(`/customers/users/${id}`, postModel)
  }

  sendMagicLink(postModel) {
    return this.post(`/auth/user/send-magic-link`, postModel)
  }

  verifyMagicLink(postModel) {
    return this.post(`/auth/user/verify-magic-link`, postModel)
  }

  loginViaToken(token) {
    return this.post(`/auth/user/token-login`, { token })
  }
}
