import { render, staticRenderFns } from "./IconBadge.vue?vue&type=template&id=0d6b1a1f"
import script from "./IconBadge.vue?vue&type=script&setup=true&lang=ts"
export * from "./IconBadge.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports