
import { createPopper } from '@popperjs/core'
import { uniqueId } from 'lodash'

export default {
  name: 'Popover',

  props: {
    config: {
      type: Object,
      default: () => ({
        placement: 'bottom'
      })
    },

    closeOnClickOutside: {
      type: Boolean,
      default: true
    },

    popoverOnHover: {
      type: Boolean,
      default: false
    },

    popoverOnFocus: {
      type: Boolean,
      default: false
    }
  },

  emits: ['isOpen', 'popperInstance'],

  data() {
    return {
      id: null,
      isOpen: false,
      hoverElement: null,
      dataPopper: null,
      dataTrigger: null,
      popperInstance: null
    }
  },

  computed: {
    compConfig() {
      const defaultOffset = {
        name: 'offset',
        options: { offset: [0, 10] }
      }

      const modifiers = [
        // In Popper 2, the computeStyles modifier has a new option called adaptive enabled by default,
        // which breaks CSS transitions. You should set this option to false if CSS transitions are enabled.
        { name: 'computeStyles', options: { adaptive: false } },
        ...(this.config?.modifiers ?? [])
      ]

      if (!modifiers.some(({ name }) => name === 'offset')) {
        modifiers.push(defaultOffset)
      }

      return {
        ...this.config,
        onFirstUpdate: () => {
          this.$emit('popperInstance', this.popperInstance)
        },

        modifiers
      }
    }
  },

  watch: {
    isOpen(isOpen) {
      this.$emit('isOpen', isOpen)
    }
  },

  created() {
    // Unique ID has to be generated on mounted otherwise it will be different when hydrated vs SSR
    this.id = uniqueId('popover-')
  },

  mounted() {
    this.dataPopper = this.$el.querySelector('[data-popover-popper]')
    this.dataTrigger = this.$el.querySelector('[data-popover-trigger], .popover-trigger')

    if (this.popoverOnFocus) {
      this.dataTriggerInput = this.$el.querySelector('[data-popover-trigger] input , .popover-trigger input')

      this.dataTriggerInput.addEventListener('focus', this.open, false)
    }

    if (this.closeOnClickOutside) {
      document.addEventListener('click', this.close)
    }

    if (this.popoverOnHover) {
      this.hoverElement = document.getElementById(this.id)

      if (this.hoverElement) {
        this.hoverElement.addEventListener('mouseenter', this.open, false)
        this.hoverElement.addEventListener('mouseleave', this.close, false)
      }
    }

    this.popperInstance = createPopper(this.dataTrigger, this.dataPopper, this.compConfig)
  },

  beforeDestroy() {
    if (!this.popperInstance) {
      return
    }

    if (this.closeOnClickOutside) {
      document.removeEventListener('click', this.close)
    }

    if (this.popoverOnHover) {
      if (this.hoverElement) {
        this.hoverElement.removeEventListener('mouseenter', this.open)
        this.hoverElement.removeEventListener('mouseleave', this.close)
      }
    }

    if (this.popperInstance) {
      this.popperInstance.destroy()
      this.popperInstance = null
    }
  },

  methods: {
    update() {
      this.popperInstance.update()
    },

    close(clickEvent = null) {
      if (!this.isOpen) {
        return
      }

      if (clickEvent && this.$el.contains(clickEvent.target) && clickEvent.type !== 'mouseleave') {
        return false
      }

      this.isOpen = false
    },

    open() {
      this.isOpen = true
      this.update()
    },

    toggle() {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.$nextTick(() => {
          this.update()
        })
      }
    }
  }
}
