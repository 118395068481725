const fetchLatestUpdateContentQuery = (userType?: string) => {
  let filterBy = ''

  if (userType) {
    filterBy = `(limit: 1, order: sys_publishedAt_ASC, where: { userType_contains_all: "${userType}"})`
  } else {
    filterBy = `(limit: 1, order: sys_publishedAt_ASC)`
  }

  return `
  {
    latestUpdatesModalContentCollection ${filterBy} {
     items {
       sys {
         id
         publishedAt
       }
       image {
         url
       }
       summary {
         json    
       }
       latestUpdateArticle {
         title
         slug
       }
     }
   }
 }
  `
}

export default fetchLatestUpdateContentQuery
