import { render, staticRenderFns } from "./bidding.vue?vue&type=template&id=84b44ba2"
import script from "./bidding.vue?vue&type=script&lang=ts&setup=true"
export * from "./bidding.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalWrapper: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/modals/modal-wrapper/ModalWrapper.vue').default,NotificationFloatingWrapper: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/notification/NotificationFloatingWrapper.vue').default,Img: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/image/Img.vue').default})
