// TODO: Update user's profile with their prefered language
export default function ({ app, $log, store, $analytics, $notify }) {
  // onBeforeLanguageSwitch called right before setting a new locale
  // We store here for instant UI feedback instead of waiting for the locale to download and activate
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    $log.debug('Before locale switched', newLocale)

    store.commit('locale/setState', {
      key: 'currentLocale',
      value: newLocale
    })
  }

  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    $log.debug('Locale finished switching', newLocale)
  }

  store.commit('locale/setState', {
    key: 'currentLocale',
    value: app.i18n.locale
  })

  store.commit('locale/setState', {
    key: 'locales',
    value: app.i18n.locales
  })
}
