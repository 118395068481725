import { isNative, platform } from '~/plugins/native/capacitor'

// Handle modifications to app icon, be it changing the image or setting unread counts
export default async function ({ store, $log, app, $notify }, inject) {
  const notifications = {}

  if (isNative) {
    const { PushNotifications } = await import('@capacitor/push-notifications')
    const $app = store.getters['inventory/app/getApp']

    notifications.setup = async () => {
      if ($app.deviceDetails.isVirtual) {
        $log.debug('Simulator detected, skipping PN registration')

        store.commit('notifications/setPushNotificationPermission', false)
        store.commit('notifications/setPushNotificationDeniedPermission', true)

        return false
      }

      // Have we already set up push notifications?
      if (store.state.notifications.pushNotifications.isRegistered) {
        $log.debug('Native PN: Already setup, attempting to re-register')

        // Try re-registering if the user is pressing the button again after failure
        PushNotifications.register()

        return false
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      $log.debug('Native PN: Requesting permission...')

      const permissionResult = await PushNotifications.requestPermissions()

      notifications.onPermissionResult(permissionResult)
    }

    notifications.checkPermissions = async () => {
      $log.debug('Native PN: Checking permission...')

      const permissionResult = await PushNotifications.checkPermissions()

      notifications.onPermissionResult(permissionResult)
    }

    notifications.onPermissionResult = async permissionResult => {
      $log.debug('Native Push Notification: onPermissionResult', permissionResult)

      if (permissionResult.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()

        store.commit('notifications/setPushNotificationPermission', true)
        store.commit('notifications/setPushNotificationDeniedPermission', false)
      } else if (permissionResult.receive === 'denied') {
        store.commit('notifications/setPushNotificationPermission', false)
        store.commit('notifications/setPushNotificationDeniedPermission', true)

        store.dispatch('notifications/sendPushNotificationToken', null)
        store.commit('notifications/setPushNotificationRegistration', false)
      } else if (permissionResult.receive === 'prompt' && $app.isAuth) {
        $log.debug('Native Push Notification: prompt')

        permissionResult = await PushNotifications.requestPermissions()
      }
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
      $log.debug('Native PN: Registered', token.value)

      store.dispatch('notifications/sendPushNotificationToken', token.value)

      store.commit('notifications/setPushNotificationRegistration', true)
    })

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', error => {
      $log.error('Native PN: Error on registration', error)

      store.commit('notifications/setPushNotificationPermission', false)
      store.commit('notifications/setPushNotificationDeniedPermission', true)
      store.commit('notifications/setPushNotificationRegistration', false)

      // $notify.error('Error registering Push Notifications, please try again')
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
      $log.debug('Native PN: Received while app open', notification)

      // iOS will show a push notification over the app while open.
      // Android will not, so we need to show our own in-app notification
      if (platform === 'android') {
        $notify.information(notification.title, notification.body, notification.data)
      }
    })

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      $log.debug('Native PN: Opened', notification)

      const redirectUrl =
        notification.notification.data && notification.notification.data.url
          ? notification.notification.data.url
          : null

      const analyticsUrl =
        notification.notification.data && notification.notification.data.shortUrl
          ? notification.notification.data.shortUrl
          : null

      if (redirectUrl) {
        const path = redirectUrl.split('.com').pop()

        if (path) {
          app.router.push({
            path
          })
        }
      }

      if (analyticsUrl) {
        fetch(analyticsUrl).catch(error => {
          $log.error('error fetching push notificaion analytics url', error)
        })
      }

      PushNotifications.removeAllDeliveredNotifications()
    })

    const { App } = await import('@capacitor/app')

    // Check permissions if app comes back to the foreground, i.e the user has come back from adjusting settings
    App.addListener('appStateChange', appState => {
      $log.debug('Push notification setup, app state change', appState)

      if (appState.isActive) {
        notifications.checkPermissions()
      }
    })
  }

  // Inject to Nuxt context as $pushNotifications
  inject('pushNotifications', notifications)
}
