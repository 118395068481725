import { Address } from '~/lib/types/address'
import { Phone } from '~/lib/types/phone'

import { alphabetArray } from './alphabet'
import { getRandomInt } from './numbers'

export const isStringType = (val: unknown) => {
  return typeof val === 'string'
}

export function truncateString(targetString: string, maxLength = 14): string | never {
  if (targetString) {
    return targetString.length > maxLength ? `${targetString.substr(0, maxLength)}...` : targetString
  } else {
    throw new Error('No string provided to truncate')
  }
}

export function getFileExtensionFromString(targetString: string, upperCase = true): string {
  if (!targetString) return ''

  let result = targetString.split('.')[targetString.split('.').length - 1]

  if (upperCase) {
    result = result.toUpperCase()
  }

  return result
}

export function removeAllWhitespace(string: string): string {
  return string.replace(/\s+/g, '')
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function removeSpecialCharacters(targetString: string): string {
  return targetString ? targetString.replace(/[^a-zA-Z ]/g, '') : ''
}

export function composeInitialSurname(targetString: string): string {
  const words = targetString.split(' ')

  return `${words[0].charAt(0)}. ${words[words.length - 1]}`
}

export function generateRandomNumberPlate(): string {
  let randomLetters = ''

  for (let i = 0; i < 3; i++) {
    randomLetters += alphabetArray[Math.floor(Math.random() * alphabetArray.length)]
  }

  return `${randomLetters}${getRandomInt(0, 100)} ${randomLetters}`
}

export function convertCamelCaseToSentence(variable: string): string {
  let sentence = ''

  for (const char of variable) {
    if (char === char.toUpperCase()) {
      sentence += ' '
    }

    sentence += char
  }

  return sentence
}

export function composeAddressString(addressObject: Address, isShort = false): string {
  let composedAddress = ''

  if (addressObject?.buildingNumber && !addressObject.line1) {
    composedAddress += `${addressObject.buildingNumber}, `
  }

  if (addressObject?.buildingName && !addressObject.line1) {
    composedAddress += `${addressObject.buildingName}, `
  }

  if (addressObject.line1) {
    composedAddress += `${addressObject.line1}, `
  }

  if (addressObject.line2 && !isShort) {
    composedAddress += `${addressObject.line2}, `
  }

  if (addressObject.locality && !isShort) {
    composedAddress += `${addressObject.locality}, `
  } else if (addressObject.city && !isShort) {
    composedAddress += `${addressObject.city}, `
  }

  if (addressObject.region) {
    composedAddress += `${addressObject.region}, `
  }

  if (addressObject.code && !isShort) {
    composedAddress += `${addressObject.code}`
  } else if (addressObject.postcode && !isShort) {
    // Inventory uses postcode vs code
    composedAddress += `${addressObject.postcode}`
  }

  // Remove the last comma and any whitespace after it
  return composedAddress.replace(/,\s*$/, '')
}

export function composePhonePostFormat(phoneObject?: Phone) {
  // API returns number objects but requires them as strings,
  // our inputs handle objects this helper is for getting the postformat
  if (phoneObject?.callingCode && phoneObject?.number) {
    const number = removeAllWhitespace(phoneObject.number)

    return `${phoneObject.callingCode} ${number}`
  } else {
    return null
  }
}

export function composeDisguisedString(numOfDisguisedChars: 2, startChars: string, endChars: string): string {
  const lastChars = endChars.substring(endChars.length - numOfDisguisedChars, endChars.length)
  const remainingChars = endChars.substring(0, endChars.length - numOfDisguisedChars)

  const disguisedChars = new Array(lastChars.length).fill('*').join('')

  return `${startChars}${remainingChars}${disguisedChars}`
}
