// We store logs in Vuex from the SSR lifecycle so that we don't have to delay rendering.
// We then rely on the client to periodically send these off once the page has loaded, including it's own
export default function ({ store }, inject) {
  const logProcessor = {
    queueTimer: null,

    startQueueTimer() {
      this.queueTimer = setInterval(() => {
        this.sendLogs()
      }, 5000)
    },

    async sendLogs() {
      await store.dispatch('logs/sendLogs')
    }
  }

  logProcessor.startQueueTimer()

  // When the user is closing the page, flush out our logs queue
  window.addEventListener('beforeunload', async function () {
    await logProcessor.sendLogs()
  })

  // Inject to context as $api
  inject('logProcessor', logProcessor)
}
