import { set } from 'lodash'

import { storePersist } from '~/lib/store-persist'
import { isNative } from '~/plugins/native/capacitor'

export const state = () => ({})

export const actions = {
  // We need to force the current requested route into navigation array in SSR mode
  nuxtServerInit({ commit, dispatch }, { req }) {
    // Temp fix for Nitro not passing req
    if (!req) {
      req = {
        url: '/'
      }
    }

    commit('navigation/addRouteHistory', { fullPath: req.url }, { root: true })
  },

  // We usually do this on logout to clear all our store data
  async resetAllModules({ commit, dispatch }) {
    this.$log.debug('Resetting all modules')

    commit('auth/reset', null, { root: true })
    commit('geo/reset', null, { root: true })
    commit('api-data-cache/reset', null, { root: true })
    commit('custom-user-meta/reset')

    // Loads is a dispatch because it needs to call multiple mutations to reset
    await dispatch('logistics/loads/reset', null, { root: true })

    if (isNative) {
      await storePersist.deleteAllLocalStateFiles()
    }
  }
}

export const mutations = {
  // A single mutation for updating any namespaced state with a single mutation to avoid lots of Vuex boilerplate
  setGlobalState(state, { statePath, newState }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, statePath, newState)
  }
}
