import { platform } from '~/plugins/native/capacitor'

export default function ({ store }) {
  if (platform === 'ios') {
    const { Keyboard } = require('@capacitor/keyboard')

    // Don't hide the "done/close" keyboard bar on iOS
    Keyboard.setAccessoryBarVisible({ isVisible: true })

    // Sometimes the keyboard covers elements, we may need to react to it being visible
    Keyboard.addListener('keyboardWillShow', () => {
      store.commit('device/setNativeKeyboardVisible', true)
    })

    Keyboard.addListener('keyboardWillHide', () => {
      store.commit('device/setNativeKeyboardVisible', false)
    })
  }
}
