import { keys, pickBy } from 'lodash'
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1536,
    '2xl': 1700
  },
  // Default for SSR
  defaultBreakpoint: 'lg'
})

const breakpointMixin = {
  computed: {
    $breakpoint() {
      const $mq = this.$mq

      const breakpointsDesktopFirst = {
        '2xlAndDown': ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes($mq),
        xlAndDown: ['xs', 'sm', 'md', 'lg', 'xl'].includes($mq),
        lgAndDown: ['xs', 'sm', 'md', 'lg'].includes($mq),
        mdAndDown: ['xs', 'sm', 'md'].includes($mq),
        smAndDown: ['xs', 'sm'].includes($mq)
      }

      const breakpointsMobileFirst = {
        xsAndUp: ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes($mq),
        smAndUp: ['sm', 'md', 'lg', 'xl', '2xl'].includes($mq),
        mdAndUp: ['md', 'lg', 'xl', '2xl'].includes($mq),
        lgAndUp: ['lg', 'xl', '2xl'].includes($mq),
        xlAndUp: ['xl', '2xl'].includes($mq)
      }

      const breakpoints = { ...breakpointsDesktopFirst, ...breakpointsMobileFirst }

      breakpoints[$mq] = true
      breakpoints.classList = keys(pickBy(breakpoints)).join(' ')

      return breakpoints
    }
  }
}

Vue.mixin(breakpointMixin)

// Defaults for SSR where window isn't defined
const initialWindowConfig = process.client
  ? {
      width: window.innerWidth,
      height: window.innerHeight
    }
  : {
      width: 1280,
      height: 800
    }

Vue.prototype.$screen = Vue.observable(initialWindowConfig)

if (process.client) {
  window.addEventListener('resize', () => {
    Vue.prototype.$screen.width = window.innerWidth
    Vue.prototype.$screen.height = window.innerHeight
  })
}
