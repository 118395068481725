import { Device } from '@capacitor/device'

import { modulesMap } from '~/lib/enums/permissions.config'
import { clearAnalyticsDefaultData, setAnalyticsDefaultData } from '~/lib/utility/event-tracking'

import inventoryMiddleware from './inventory'

const getUserRoleForAnalytics = (roles = []) => {
  const userRolesForInventory = [modulesMap.inventoryCore]
  const userRolesForTrading = [modulesMap.tradingCore, modulesMap.tradingBuyers]
  const userRoleForBuyer = modulesMap.tradingBuyers
  const userRoleForSeller = modulesMap.tradingCore

  const isTradingUser = userRolesForTrading.some(userRole => roles.includes(userRole))
  const isInventoryUser = userRolesForInventory.some(userRole => roles.includes(userRole))

  if (isTradingUser && isInventoryUser) {
    return 'hybrid'
  }

  if (roles.includes(userRoleForSeller)) {
    return 'seller'
  }

  if (roles.includes(userRoleForBuyer)) {
    return 'buyer'
  }

  if (isInventoryUser) {
    return 'inventory'
  }
}

const getUserProductForAnalytics = (userRoles = []) => {
  const userRolesForTrading = [modulesMap.tradingCore, modulesMap.tradingBuyers]
  const userRolesForInventory = [modulesMap.inventoryCore]

  if (userRolesForTrading.some(tradingRole => userRoles.includes(tradingRole))) {
    return 'trading'
  }

  if (userRolesForInventory.some(inventoryRole => userRoles.includes(inventoryRole))) {
    return 'inventory'
  }
}

const isPlatformMobileDevice = platform => {
  const mobileDeviceIds = ['ios', 'android']

  return mobileDeviceIds.includes(platform)
}

const trackDeviceInfo = async () => {}

export default function (context) {
  const { store } = context

  const setAnalyticsDefaults = async () => {
    const deviceInfo = await Device.getInfo()

    const profile = store.state.inventory.auth.profile
    const impersonating = store.state.inventory.auth.impersonateBusiness?.id
    const userRoles = profile?.roles

    const userRoleForAnalytics = getUserRoleForAnalytics(userRoles)
    const userProduceForAnalytics = getUserProductForAnalytics(userRoles)

    const userData = {
      user_id: profile?.id,
      user_role: userRoleForAnalytics,
      user_product: userProduceForAnalytics,
      user_impersonating: !!impersonating
    }

    const platformData = {
      platform: `${deviceInfo.platform}, ${deviceInfo.operatingSystem}, ${deviceInfo.osVersion}`,
      is_mobile: isPlatformMobileDevice(deviceInfo.platform)
    }

    setAnalyticsDefaultData({
      ...userData,
      ...platformData
    })
  }

  clearAnalyticsDefaultData()
  setAnalyticsDefaults()
  trackDeviceInfo()

  return inventoryMiddleware(context)
}
