export const offlineDataAuth = {
  FARMTO_INVENTORY_ACCESS_TOKEN: 'farmto-inventory-access-token',
  FARMTO_INVENTORY_REFRESH_TOKEN: 'farmto-inventory-refresh-token',
  FARMTO_INVENTORY_ID_TOKEN: 'farmto-inventory-id-token',
  FARMTO_INVENTORY_PROFILE: 'farmto-inventory-profile',
  FARMTO_INVENTORY_IMPERSONATE: 'farmto-inventory-impersonate',
  FARMTO_INVENTORY_SELECTED_ORG: 'farmto-inventory-selected-org'
}

export const offlineStorage = {
  DRAFT_LISTING: 'draft-listing'
}
