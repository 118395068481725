import axios from 'axios'
import { cloneDeep, get, isEmpty } from 'lodash'
import * as retryAxios from 'retry-axios'

export const axiosInstance = axios.create()

class ApiCore {
  constructor(nuxtContext, apiModel, storeStatePath = null, timeout = 30000) {
    this.nuxt = nuxtContext
    this.currentPlatform = 'LOGISTICS'

    // We allow usage of this.$Api() without passing an apiModel, although it's not recommended
    if (!apiModel) {
      apiModel = new this.nuxt.$ApiModel()
    }

    this.storeStatePath = storeStatePath
    this.isImmutable = !!storeStatePath

    // Are we making state immutible for Vuex or updating the referenced apiModel directly
    this.state = this.isImmutable ? cloneDeep(apiModel) : apiModel

    this.isThirdPartyApi = false
    this.isDebugEnabled = false
    this.timeout = timeout
    this.axios = axiosInstance

    retryAxios.attach(this.axios)

    this.hooks = {
      transformRequest: null,
      transformResponse: null
    }

    if (process.client) {
      this.cancelSource = axios.CancelToken.source()
    }

    // Axios fetch doesn't have defaults for transform
    this.hooks.transformResponse = axios.defaults.transformResponse
    this.hooks.transformRequest = axios.defaults.transformRequest

    // Set raxConfig to a variable to be used by api-inventory.js
    this.raxConfig = null
  }

  useStorePath(storeStatePath) {
    this.storeStatePath = storeStatePath
    this.isImmutable = !!storeStatePath

    this.state = cloneDeep(this.state)

    return this
  }

  // Execute the API request
  async send(path, method = 'GET', data = this.state?.model, config) {
    this.isThirdPartyApi = path?.includes('http')

    const callDebug = `${method} ${path}`

    const canSaveLogs = !path?.includes('/analytics/')

    // Maintenance mode, block all requests
    if (
      this.nuxt.store.getters['inventory/app/isMaintenanceModeEnabled'] &&
      !this.nuxt.store.getters['inventory/app/isMaintenanceModeBypassed']
    ) {
      this.nuxt.$log.debug('Maitenance mode blocked', callDebug)

      return false
    }

    // Check throttling
    if (config.throttleMs) {
      const timeMethodLastCalledMs = Date.now() - this.state.timeMethodLastCalled[method]

      // Called too often
      if (timeMethodLastCalledMs < config.throttleMs) {
        this.nuxt.$log.debug('Throttling call', callDebug, canSaveLogs)
        return false
      }
    }

    // Don't add to $log if request is going to /log otherwise the log queue will never empty!
    if (this.isDebugEnabled && !path.includes('/log/v1/log')) {
      this.nuxt.$log.debug(callDebug, null, canSaveLogs)
    }

    // Log our last call for use in throttling
    this.state.timeMethodLastCalled[method] = Date.now()

    const defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (config['Content-Type']) {
      defaultHeaders['Content-Type'] = config['Content-type']
    }

    if (config.deleteContentType) {
      defaultHeaders['Content-Type'] = undefined
      defaultHeaders.Accept = '*/*'
    }

    const options = {
      method,
      timeout: this.timeout,
      headers: config.headers ? config.headers : defaultHeaders,
      url: path,
      responseType: config.responseType ?? 'json',
      // Setting withCredentials allows the API to set httpOnly cookies in the response headers of an XHR request
      // We need to add some safeguards to this and ensure we are only allowing this when contacting our API with /auth/ endpoints
      withCredentials: config.withCredentials && !this.isThirdPartyApi && path.includes('/auth/'),
      isFormData: config.isFormData,
      transformRequest: config.transformRequest || this.hooks.transformRequest || undefined,
      transformResponse: this.hooks.transformResponse || undefined,
      cancelToken: process.client ? this.cancelSource.token : undefined,
      onUploadProgress: progressEvent => {
        this.state.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }

    // Override the base url if needed - added for InputSearchAutocomplete
    if (config.baseURL) {
      options.baseURL = config.baseURL
    }

    if (method === 'GET') {
      options.params = data
    } else {
      options.data = data
    }

    const loginTimeoutStatusCode = 440

    // Intentionally not retrying 503s as we want to react quickly to maintenance mode
    options.raxConfig = {
      instance: this.axios,
      httpMethodsToRetry: ['GET', 'OPTIONS', 'HEAD'],
      statusCodesToRetry: [
        [100, 199],
        [403, 403],
        [429, 429],
        [440, 440],
        [500, 502],
        [504, 504]
      ],
      onRetryAttempt: retryError => {
        // The retry attempt will occur after the promise is resolved / rejected
        return new Promise((resolve, reject) => {
          // Refresh our token if 403
          if (
            [403].includes(retryError?.response?.status) &&
            !this.nuxt.route.path.includes('/auth') &&
            !retryError.request.responseURL.includes('/auth/')
          ) {
            try {
              this.nuxt.store.dispatch('auth/refreshToken').then(() => {
                resolve()
              })
            } catch (error) {
              reject(error)
            }
          } else if (
            retryError?.response?.status === loginTimeoutStatusCode &&
            this.nuxt.route.path.includes('/auth') &&
            retryError.request.responseURL.includes('/auth/')
          ) {
            this.nuxt.store.dispatch('inventory/auth/endImpersonateBusiness', { getUserProfile: false })
            resolve()
          } else {
            resolve()
          }
        })
      }
    }

    if (!isEmpty(this.raxConfig)) {
      options.raxConfig = this.raxConfig
    }

    const urlKey = encodeURIComponent(`${path}${JSON.stringify(data)}`)

    this.state.isLoading = true
    this.state.response.status = null
    this.state.response.message = null
    this.state.response.errors = []

    if (config.cache) {
      // Do we have a cache for this urlKey?
      const cachedResponse = this.nuxt.store.getters['api-data-cache/getCachedData'](urlKey)

      // We have a match! Let's immediately set the response to the cached value
      if (!isEmpty(cachedResponse)) {
        this.state.response = cloneDeep(cachedResponse)
        this.state.isLoading = false
      }
    }

    this.updateState()

    const apiResponse = await this.axios(options)
      .catch(error => {
        const statusCode = get(error, 'response.status') || error.status || 500

        this.state.response.code = statusCode
        this.state.hasError = true

        const ignoreError =
          error.response === undefined || error.code === 'ECONNABORTED' || !error.message || statusCode < 500
        // || this.axios.isCancel(error)

        // Let's not spam Sentry with errors caused by bad internet
        if (ignoreError) {
          if (!path.includes('/log/v1/log')) {
            this.nuxt.$log.debug(`API ignored error ${path}`, error, canSaveLogs)
          }
        } else {
          this.nuxt.$log.error(`API error ${path}, page:${this.nuxt.route.path}`, error.message, canSaveLogs)
        }

        this.state.response.status = 'error'

        const errors = error.response?.data?.errors || error.response?.data?.data || []

        this.state.response.errors = errors

        this.state.isLoading = false

        if (statusCode > 399 && statusCode < 500) {
          const validationErrors = error.response?.data?.data?.validation ?? error.data?.data?.validation ?? []

          this.state.response.validation = validationErrors
        }

        if (statusCode === loginTimeoutStatusCode && !this.nuxt.route.path.includes('/auth')) {
          this.nuxt.store.dispatch('inventory/auth/endImpersonateBusiness')
          this.nuxt.$notify.information(error.message)
          this.nuxt.$redirect.to('/inventory/internal/business-accounts')
        }

        this.updateState()

        throw new Error(error.message || 'Something went wrong, please reload and try again')
      })
      .finally(() => {
        this.updateState()
      })

    if (this.isDebugEnabled) {
      this.nuxt.$log.debug({ apiResponse }, null, canSaveLogs)
    }

    try {
      if (apiResponse && apiResponse.data) {
        this.state.response.code = apiResponse.status
        this.state.response.status = apiResponse.data.status
        this.state.response.message = apiResponse.data.message
        this.state.response.headers['content-disposition'] = apiResponse.headers?.['content-disposition'] ?? null
        this.state.response.data = apiResponse.data.data ?? apiResponse.data

        const pagination = apiResponse.data.pagination || apiResponse.data.data?.pagination

        this.state.response.pagination = pagination
          ? {
              currentPage: pagination.currentPage,
              documentsPerPage: pagination.limit,
              totalDocuments: pagination.totalDocuments,
              isVisible: pagination.totalDocuments > pagination.limit,
              previousPage: pagination.prev,
              nextPage: pagination.next,
              currentDocumentIndex: pagination.currentDocumentIndex
            }
          : {}

        // If we've enabled cache for this request let's save the response
        if (config.cache) {
          this.nuxt.store.commit('api-data-cache/addToCache', {
            urlKey,
            data: cloneDeep(this.state.response)
          })
        }

        this.state.hasError = false
        this.state.response.validation = []
      } else if (this.state.response.status !== 'error' && !this.isThirdPartyApi) {
        console.warn(
          `Request to ${path} completed, but with no data key in the response`,
          JSON.stringify(apiResponse)
        )
      }
    } catch (error) {
      this.nuxt.$log.error('Error setting api response', error)
    } finally {
      this.state.isLoading = false
      this.updateState()
    }

    return cloneDeep(this.state)
    // Support method chaining
  }

  updateState() {
    const newState = cloneDeep(this.state)

    // If we provided a vuex path for the state object, let's go mutate it.
    // This is a nasty side effect but it prevents so much Vuex boilerplate it's worth it.
    if (this.storeStatePath) {
      this.nuxt.store.commit('setGlobalState', {
        statePath: this.storeStatePath,
        newState
      })
    }
  }

  enableDebug() {
    this.isDebugEnabled = true

    // Support method chaining
    return this
  }

  get(path, params = undefined, config = {}) {
    return this.send(path, 'GET', params, config)
  }

  post(path, data, config = {}) {
    return this.send(path, 'POST', data, config)
  }

  postFormData(path, data = undefined, config = {}) {
    config = { ...config, isFormData: true }
    return this.send(path, 'POST', data, config)
  }

  patch(path, data, config = {}) {
    return this.send(path, 'PATCH', data, config)
  }

  put(path, data, config = {}) {
    return this.send(path, 'PUT', data, config)
  }

  delete(path, data, config = {}) {
    return this.send(path, 'DELETE', data, config)
  }

  head(path, data = undefined, config = {}) {
    return this.send(path, 'HEAD', data, config)
  }

  resetResponse() {
    Object.assign(this.state.response, { ...new this.nuxt.$ApiModel().response })
  }

  cancel() {
    this.cancelSource.cancel()
  }

  // This is usually called on first page load to set
  // existing data from a local storage source
  hydrate(data) {
    if (!data) {
      return this
    }

    this.state.response.data = data

    return this
  }
}

export default ApiCore
