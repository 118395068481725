import { ApiInventory } from '~/lib/api-inventory'
import {
  AuthService,
  BusinessUnitService,
  CommodityService,
  CoreService,
  FileService,
  GeoService,
  OrganisationService,
  RecordService,
  SearchService,
  UsersService
} from '~/services/inventory'
import {
  BidsService,
  ExFarmPricingService,
  MarketListingService,
  MarketUpdateService,
  NewsFeedService,
  SalesContractService,
  TargetPricesService,
  TradedService,
  TradeRequestsService
} from '~/services/trading'

export const createApi = nuxtContext => {
  const api = {
    auth: apiModel => new AuthService(nuxtContext, apiModel),
    businessUnit: apiModel => new BusinessUnitService(nuxtContext, apiModel),
    commodity: apiModel => new CommodityService(nuxtContext, apiModel),
    core: apiModel => new CoreService(nuxtContext, apiModel),
    default: apiModel => new ApiInventory(nuxtContext, apiModel),
    exFarmPricing: apiModel => new ExFarmPricingService(nuxtContext, apiModel),
    geo: apiModel => new GeoService(nuxtContext, apiModel),
    record: apiModel => new RecordService(nuxtContext, apiModel),
    search: apiModel => new SearchService(nuxtContext, apiModel),
    organisations: apiModel => new OrganisationService(nuxtContext, apiModel),
    file: apiModel => new FileService(nuxtContext, apiModel),
    users: apiModel => new UsersService(nuxtContext, apiModel),
    traded: apiModel => new TradedService(nuxtContext, apiModel),
    marketListing: apiModel => new MarketListingService(nuxtContext, apiModel),
    marketUpdates: apiModel => new MarketUpdateService(nuxtContext, apiModel),
    newsFeed: apiModel => new NewsFeedService(nuxtContext, apiModel),
    bids: apiModel => new BidsService(nuxtContext, apiModel),
    tradeRequests: apiModel => new TradeRequestsService(nuxtContext, apiModel),
    targetPrices: apiModel => new TargetPricesService(nuxtContext, apiModel),
    salesContracts: apiModel => new SalesContractService(nuxtContext, apiModel)
  }

  return api
}

export default function (nuxtContext, inject) {
  const api = createApi(nuxtContext)

  // Inject to context as $api
  // Note: we already have $Api with a capital letter which will be deprecated
  // when inventory and logistics are merged
  inject('api', api)
}
