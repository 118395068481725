const getHotJarInstance = () => {
  return window.hj
}

export const identifyUser = (uid: string, email: string, organisation: string) => {
  const hotjar = getHotJarInstance()

  if (!hotjar) {
    return
  }

  hotjar('identify', uid, {
    email,
    organisation
  })
}
