export const weightMask = (min = 0, max = 2000000, padFractionalZeros = false) => {
  return {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros,
        thousandsSeparator: ',',
        radix: '.',
        mapToRadix: ['.'],
        min,
        max
      }
    }
  }
}

export const weightTonnesMask = (min = 0, max = 2000000, padFractionalZeros = false) => {
  return {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros,
        normalizeZeros: true,
        thousandsSeparator: ',',
        radix: '.',
        scale: 2,
        mapToRadix: ['.'],
        min,
        max
      }
    }
  }
}

export const weightBushelsMask = (min = 0, max = 2000000, padFractionalZeros = false) => {
  return {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros,
        thousandsSeparator: ',',
        radix: '.',
        scale: 2,
        mapToRadix: ['.'],
        min,
        max
      }
    }
  }
}
