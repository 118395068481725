export const setOfflineStorage = (keyName: string, value: string): void => {
  return localStorage.setItem(keyName, value)
}

export const clearOfflineStorage = (keyName: string): void => {
  return localStorage.removeItem(keyName)
}

export const getOfflineStorage = (keyName: string): string | null => {
  const value = localStorage.getItem(keyName)

  return value === 'null' ? null : value
}

export const setSessionStorage = (keyName: string, value: string): void => {
  return sessionStorage.setItem(keyName, value)
}

export const getSessionStorage = (keyName: string): string | null => {
  const value = sessionStorage.getItem(keyName)

  return value === 'null' ? null : value
}
