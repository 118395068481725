import { set } from 'vue'

const initalState = () => {
  return {
    // For tracking heights that may affect layouts
    // If we add a fixed footer we can add the right amout of
    // padding to prevent the footer from overlapping the content
    dynamicHeights: {
      fixedFooter: 0
    }
  }
}

export const state = () => initalState()

export const getters = {
  getDynamicHeight: state => key => {
    return state.dynamicHeights[key] ?? 0
  }
}

export const mutations = {
  addDynamicHeight(state, { key, value }) {
    console.log('adding height', key, ':', value)
    console.log('dh', state.dynamicHeights)
    console.log('lookup', state.dynamicHeights[key])

    if (state.dynamicHeights[key] !== undefined) {
      console.log('already exists, adding', value)
      state.dynamicHeights[key] += value
    }
  },

  setDynamicHeight(state, { key, value }) {
    if (!state.dynamicHeights[key]) {
      set(state.dynamicHeights, key, 0)
    }

    state.dynamicHeights[key] = value
  },

  removeDynamicHeight(state, { key, value }) {
    if (state.dynamicHeights[key]) {
      state.dynamicHeights[key] -= value

      if (state.dynamicHeights[key] < 0) {
        state.dynamicHeights[key] = 0
      }
    }
  }
}

// export const actions = {
//   addDynamicHeight({ state, commit }, { key, value }) {
//     if (state.dynamicHeights.key) {
//       commit('addDynamicHeight', { key, value })
//     } else {
//       commit('setDynamicHeight', { key, value })
//     }
//   },
//   removeDynamicHeight({ state, commit }, { key, value }) {
//     if (state.dynamicHeights.key) {
//       commit('removeDynamicHeight', { key, value })
//     } else {
//       commit('setDynamicHeight', { key, value })
//     }
//   }
// }
