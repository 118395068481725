import { camelCase, find, upperFirst } from 'lodash'

const initialState = () => {
  return {
    list: [],
    lastOpened: null,
    lastClosed: null,
    isLoading: false
  }
}

export const state = () => initialState()

export const getters = {
  allOpenFlatList: state => {
    return state.list.map(modal => modal.modalName)
  },

  allOpenUrlSyncedFlatList: (state, getters) => {
    return state.list.flatMap(modal => (modal.isSyncedWithUrl ? [modal.modalName] : []))
  },

  totalOpen: state => {
    return state.list.length
  },

  activeModal: state => {
    return state.list.length > 0 ? state.list[0] : {}
  },

  isLoading: state => {
    return state.isLoading
  }
}

export const actions = {
  open({ commit, state, dispatch }, payload) {
    let modalName
    let isSyncedWithUrl
    let dataCallback
    let modalData = {}

    // If payload is a string then that's the modal name, otherwise
    // it means we are passing data as well as a name
    if (typeof payload === 'object') {
      modalName = payload.modalName
      modalData = payload.modalData
      dataCallback = payload.dataCallback
      isSyncedWithUrl = payload.isSyncedWithUrl
    } else {
      modalName = payload
      isSyncedWithUrl = true
    }

    if (find(state.list, { modalName })) {
      this.$log.debug('Duplicate model found, not showing', modalName)
      return
    }

    this.$log.debug('Modal: Showing', modalName)

    commit('setLastOpened', modalName)
    commit('addModal', { modalName, modalData, dataCallback, isSyncedWithUrl })

    dispatch('onModalOpen', modalName)
  },

  closeAll({ commit, state, dispatch }) {
    this.$log.debug('Modal: Closing all')

    if (state.list.length === 0) {
      dispatch('onModalClose', null)
      return false
    }

    const currentModalName = state.list[0].modalName

    commit('setLastClosed', currentModalName)

    commit('deleteAllModals')

    dispatch('onModalClose', currentModalName)
  },

  closeByName({ commit, state, dispatch }, modalName) {
    // If the modal we want to close isn't open, then return
    if (!find(state.list, { modalName })) {
      return false
    }

    this.$log.debug('Modal: Closing', modalName)

    commit('setLastClosed', modalName)

    commit('deleteModalByName', modalName)

    dispatch('onModalClose', modalName)
  },

  onModalClose({ getters, dispatch, rootState }, modalName) {
    if (getters.totalOpen === 0) {
      this.$scroll.unlock()
    }
  },

  onModalOpen({ dispatch }, modalName) {
    this.$scroll.lock()
  },

  showTerms({ dispatch }, type = 'general') {
    dispatch('open', {
      modalName: 'ModalTerms',
      modalData: {
        type
      }
    })
  }
}

export const mutations = {
  addModal(state, { modalName, modalData = {}, dataCallback = () => {}, isSyncedWithUrl = true }) {
    const modalFolderName = modalName
    const modalFileName = `Modal${upperFirst(camelCase(modalName.split('/').pop()))}`

    state.list.unshift({
      component: () =>
        import(/* webpackChunkName: "[request]" */ `~/components/modals/${modalFolderName}/${modalFileName}`),
      modalName,
      modalData,
      dataCallback,
      isSyncedWithUrl
    })
  },

  deleteModalByName(state, modalName) {
    state.list = state.list.filter(modal => modal.modalName !== modalName)
  },

  deleteAllModals(state) {
    state.list = []
  },

  setLastOpened(state, modalName) {
    state.lastOpened = modalName

    // We set this to null because if the same model get's opened then closed
    // Our watchers won't see the lastClosed value change
    state.lastClosed = null
  },

  setLastClosed(state, modalName) {
    state.lastClosed = modalName
  },

  setDataReturn(state, { data, modalName }) {
    if (!modalName) {
      this.$log.error('You must specify modalName')
      return
    }

    state.dataReturn = { data, modalName }
  },

  resetDataReturn(state) {
    state.dataReturn = initialState().dataReturn
  },

  setIsLoading(state, value) {
    state.isLoading = value
  }
}
