import { render, staticRenderFns } from "./error.vue?vue&type=template&id=38c81c94"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/link/Link.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default})
