import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr'

import dayjs from 'dayjs'
import calendarFormat from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#relativetime
dayjs.extend(relativeTime)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#localizedFormat
dayjs.extend(localizedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#calendar
dayjs.extend(calendarFormat)

// DOCS: https://day.js.org/docs/en/plugin/duration
dayjs.extend(duration)

// DOCS: https://day.js.org/docs/en/plugin/timezone
dayjs.extend(utc)
dayjs.extend(timezone)

// DOCS: https://day.js.org/docs/en/plugin/is-between
dayjs.extend(isBetween)

// DOCS: https://day.js.org/docs/en/plugin/is-today
dayjs.extend(isToday)

dayjs.extend(minMax)

export default ({ app }, inject) => {
  const date = dayjs

  date.relative = dateString => {
    return date.utc(dateString).local().calendar(null, {
      sameDay: '[Today] [at] HH:mm', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow] [at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'llll', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday] [at] HH:mm', // The day before ( Yesterday at 2:30 AM )
      lastWeek: 'llll', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'llll' // Everything else ( 7/10/2011 )
    })
  }

  date.monthIndexToString = (monthIndex, isLong = true, locale = null) => {
    locale = locale ?? app.i18n?.locale

    const format = isLong ? 'long' : 'short'

    const date = new Date()

    date.setMonth(monthIndex - 1)

    return date.toLocaleString(locale, { month: format })
  }

  date.monthStrings = () => {
    const monthIndices = [...Array(12).keys()]

    return monthIndices.map(monthIndex => {
      return date.monthIndexToString(monthIndex + 1)
    })
  }

  date.relativeShort = dateString => {
    return date.utc(dateString).local().calendar(null, {
      sameDay: '[Today] [at] HH:mm', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow] [at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'll', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday] [at] HH:mm', // The day before ( Yesterday at 2:30 AM )
      lastWeek: 'll', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'ddd, MMM D' // Everything else ( 7/10/2011 )
    })
  }

  date.getUpcomingMonthOptions = ({ numberOfMonths = 12, startDate = date.utc(), displayShortHand = false }) => {
    return new Array(numberOfMonths).fill().map((_, index) => {
      const optionDate = startDate.add(index, 'month')

      return {
        label: optionDate.format(displayShortHand ? 'MMM YYYY' : 'MMMM YYYY'),
        value: {
          month: optionDate.format('MMMM'),
          year: optionDate.year()
        }
      }
    })
  }

  date.utcToLocal = utcString => {
    return date.utc(utcString).local().toISOString()
  }

  date.localToUtc = localTimeString => {
    return date(localTimeString).utc().toISOString()
  }

  date.msToTime = duration => {
    const seconds = Math.floor((duration / 1000) % 60)
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    return {
      hours,
      minutes,
      seconds
    }
  }

  date.handleAvailableDateRangeInPast = availableDateRange => {
    const todaysDate = date.utc()

    if (date.utc(availableDateRange.from).isBefore(todaysDate)) {
      // if the availableDate.from date is in the past then set it to todays
      availableDateRange.from = date.utcToLocal(todaysDate)
    }

    if (date.utc(availableDateRange.by).isBefore(todaysDate)) {
      // if the date range is in the past, set availableDate.from to today and no limit on availableDate.by
      availableDateRange.by = date.utcToLocal(todaysDate.add(1, 'year'))
    }

    return availableDateRange
  }

  date.setToTimeStartOfDay = day => {
    return date.utc(day).hour(0).minute(0)
  }

  date.setToTimeEndOfDay = day => {
    return date.utc(day).hour(23).minute(59)
  }

  date.setToStartOfMonth = (month, year) => {
    return date(1).month(month).year(year)
  }

  date.setToEndOfMonth = (month, year) => {
    return date().month(month).endOf('month').year(year)
  }

  date.getMonthNumber = monthString => {
    // Add one to get month value as array is 0 based index
    return monthString
      ? [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december'
        ].indexOf(monthString) + 1
      : -1
  }

  // Add to Nuxt context as $date
  inject('date', dayjs)
}
