import { platform } from '~/plugins/native/capacitor'

if (platform === 'ios') {
  // iOS statusbar tap, scroll to top
  window.addEventListener('statusTap', () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })
}
