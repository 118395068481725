import {
  CreateUser,
  OrganisationSummary,
  PatchUser,
  UserBasicInfo,
  UserDetail
} from '@hectare/platform.clients.customers'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'

export class UsersService extends ApiInventory {
  getUsers({ limit = 25, offset = 0 }): Promise<UserDetail[]> {
    return this.get(`/customers/users?offset=${offset}&limit=${limit}`)
  }

  getUser(userId: string): Promise<UserDetail> {
    return this.get(`/customers/users/${userId}`)
  }

  getUserInfo(userId: string): Promise<UserBasicInfo> {
    return this.get(`/customers/users/${userId}/info`)
  }

  createUser(postModel: CreateUser): Promise<UserDetail> {
    return this.post(`/customers/users`, postModel)
  }

  getUserRoles(): Promise<OpenAPIV3.ResponseObject> {
    return this.get(`/customers/security/roles`)
  }

  deleteUser(userId: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/customers/users/${userId}`)
  }

  editUser(userId: string, postModel: PatchUser): Promise<UserDetail> {
    return this.patch(`/customers/users/${userId}`, postModel)
  }

  resendPassword(id: string) {
    return this.get(`/customers/users/${id}/resend-password`)
  }

  verifyUpdate(postModel: string) {
    return this.post('/customers/users/verify-update', postModel)
  }

  lookupOrCreateUserByTelephone(phone: string): Promise<UserDetail> {
    return this.post('/customers/users/lookup/telephone', { phone })
  }

  getOrganisationCarriers(): Promise<OrganisationSummary> {
    return this.get('/customers/organisations/carrier')
  }
}
