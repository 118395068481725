import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=148c8286&scoped=true"
import script from "./Avatar.vue?vue&type=script&lang=js"
export * from "./Avatar.vue?vue&type=script&lang=js"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=148c8286&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148c8286",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/image/Img.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default,Popover: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/popover/Popover.vue').default})
