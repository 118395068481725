import { hasPasswordSecurityRulesUpdate } from '~/lib/utility/feature-flags'

export interface ResetPasswordModel {
  identifier: string
  code: string
  newPassword: string
  confirmNewPassword: string
}

export interface ResetPasswordGraindexModel {
  identifier: string
  newPassword: string
  confirmNewPassword: string
  oldPassword: string
  email: string
}

export type NewPasswordValidation = {
  min: number
  oneLowercaseCharacter?: boolean
  oneUppercaseCharacter: boolean
  oneDigit: boolean
  oneSpecialCharacter?: boolean
  required: boolean
}

export type ConfirmNewPassword = {
  required: boolean
  confirmed: string
}

export function GraindexResetPasswordEntity(existing: Partial<ResetPasswordGraindexModel>) {
  return {
    model: {
      identifier: null,
      newPassword: null,
      confirmNewPassword: null,
      oldPassword: null,
      ...existing
    },
    validation: {
      identifier: { required: true },
      newPassword: {
        min: 8,
        ...(!hasPasswordSecurityRulesUpdate && {
          oneLowercaseCharacter: true
        }),
        oneUppercaseCharacter: true,
        ...(!hasPasswordSecurityRulesUpdate && {
          oneSpecialCharacter: true
        }),
        oneDigit: true,
        required: true
      },
      confirmNewPassword: { required: true, confirmed: 'newPassword' }
    }
  }
}

export default function (existing: Partial<ResetPasswordModel>) {
  return {
    model: {
      identifier: null,
      code: null,
      newPassword: null,
      confirmNewPassword: null,
      ...existing
    },
    validation: {
      identifier: { required: true },
      code: { required: true, min: 6 },
      newPassword: {
        min: 8,
        ...(!hasPasswordSecurityRulesUpdate && {
          oneLowercaseCharacter: true
        }),
        oneUppercaseCharacter: true,
        ...(!hasPasswordSecurityRulesUpdate && {
          oneSpecialCharacter: true
        }),
        oneDigit: true,
        required: true
      },
      confirmNewPassword: { required: true, confirmed: 'newPassword' }
    }
  }
}
