import reduce from 'image-blob-reduce'

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const shrinkImageBlob = async (blobIn, maxDimension = 2000) => {
  const reducer = reduce()

  try {
    // return blobIn
    return await reducer.toBlob(blobIn, { max: maxDimension, alpha: true })
  } catch (error) {
    throw new Error(error)
  }
}

export const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result.toString())

    reader.onerror = error => reject(error)

    reader.readAsDataURL(blob)
  })
}
