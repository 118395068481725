import { businessUnitType } from '~/lib/enums/business-unit'
import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    businessUnitsApi: new ApiModel()
  }
}

export const state = () => initialState()

export const mutations = {
  setBusinessUnitsApi(state, businessUnitsApi) {
    state.businessUnitsApi = businessUnitsApi
  }
}

export const getters = {
  isLoadingBusinessUnits: state => {
    return state.businessUnitsApi.isLoading
  },

  businessUnits: state => {
    return state.businessUnitsApi.response.data?.businessUnits ?? []
  },

  getBusinessUnitById: (state, getters, rootState, rootGetters) => id => {
    return (
      (getters.businessUnits.length && getters.businessUnits.find(unit => unit.id === id)) ??
      (rootGetters['inventory/app/getApp'].businessUnits.length &&
        rootGetters['inventory/app/getApp'].businessUnits.find(unit => unit.id === id))
    )
  },

  getBusinessUnitsByType: (state, getters) => typesToInclude => {
    const businessUnits = getters.businessUnits

    if (!typesToInclude) return businessUnits

    if (typeof typesToInclude === 'string') {
      return businessUnits.filter(businessUnit => businessUnit.type === typesToInclude) ?? []
    } else if (Array.isArray(typesToInclude)) {
      return businessUnits.filter(businessUnit => typesToInclude.includes(businessUnit.type)) ?? []
    } else {
      return []
    }
  },

  getBusinessUnitOptions:
    (state, getters) =>
    (includeLocationGroups = false) => {
      const businessUnits = includeLocationGroups
        ? getters.getBusinessUnitsByType()
        : getters.getBusinessUnitsByType([businessUnitType.FARM, businessUnitType.STORE])

      return (
        businessUnits.map(unit => {
          return {
            label: unit.name,
            type: unit.type,
            name: unit.name,
            value: unit
          }
        }) ?? []
      )
    },

  getLocationGroupOptions: (state, getters) => {
    const locationGroups = getters.getBusinessUnitsByType([businessUnitType.REGION])

    return (
      locationGroups.map(unit => {
        return {
          label: unit.name,
          type: unit.type,
          name: unit.name,
          value: unit
        }
      }) ?? []
    )
  }
}

export const actions = {
  async fetchBusinessUnits({ state, getters, rootGetters }, forceUpdate = false) {
    const organisationId = rootGetters['inventory/auth/getCurrentOrg'].id

    if (forceUpdate || !getters.businessUnits.length) {
      try {
        await this.$api
          .businessUnit(state.businessUnitsApi)
          .useStorePath(`inventory.businessUnits.businessUnitsApi`)
          .fetchAllBusinessUnitsList(organisationId)

        return state.businessUnitsApi
      } catch (error) {
        this.$log.error('fetching business units error', error)
      }
    }
  }
}
