import { set } from 'lodash'

const initialState = () => {
  return {
    currentLocale: null,
    locales: []
  }
}

export const state = () => initialState()

export const getters = {
  getCurrentLocale: state => {
    return state.currentLocale
  },

  getAvailableLocaleOptions: state => {
    return state.locales.map(locale => ({
      label: locale.name,
      value: locale.code
    }))
  }
}

export const actions = {
  switchLocaleByCountryCode({ state }, code) {
    const countryCode = code.toUpperCase()

    const localesByCountryCode = {
      GB: 'en-GB'
    }

    if (state.currentLocale === localesByCountryCode[countryCode]) {
      return
    }

    if (localesByCountryCode[countryCode]) {
      this.$i18n.setLocale(localesByCountryCode[countryCode])
      this.$date.locale(localesByCountryCode[countryCode].toLowerCase())
    } else {
      this.$i18n.setLocale(localesByCountryCode.GB)
      this.$date.locale(localesByCountryCode.GB.toLowerCase())
    }
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  reset(state) {
    this.$log.debug('Resetting locale module')

    Object.assign(state, initialState())
  }
}
