import { cloneDeep } from 'lodash'

export default function ({ store }, inject) {
  const setGlobalState = (statePath, state) => {
    store.commit('setGlobalState', {
      statePath,
      newState: cloneDeep(state)
    })
  }

  // Inject to context as $setGlobalState
  inject('setGlobalState', setGlobalState)
}
