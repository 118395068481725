export default function () {
  return {
    model: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validation: {
      oldPassword: { required: true },
      newPassword: {
        required: true,
        min: 8,
        cognitoPassword: true
      },
      confirmNewPassword: { required: true, min: 8, confirmed: 'newPassword' }
    }
  }
}
