import { ApiInventory } from '~/lib/api-inventory'

export class FileService extends ApiInventory {
  upload(postModel, config) {
    return this.post('/system/files', postModel, config)
  }

  download(fileId) {
    return this.get(`/system/files/${fileId}`, {}, { responseType: 'blob' })
  }

  deleteFile(fileId) {
    return this.delete(`/system/files/${fileId}`)
  }
}
