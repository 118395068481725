import { render, staticRenderFns } from "./HDialog.vue?vue&type=template&id=566c74a6&scoped=true"
import script from "./HDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./HDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./HDialog.vue?vue&type=style&index=0&id=566c74a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566c74a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HTitle: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/HTitle.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default,Button: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/button/Button.vue').default,LoaderOverlay: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/utils/loader/LoaderOverlay/LoaderOverlay.vue').default})
