import { get, isPlainObject } from 'lodash'

import { truncateObjectStrings } from '~/lib/transform-data-format'
import { ApiModel } from '~/plugins/api-logistics/api-model'
import { isNative } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    queue: [],
    sendLogsApiModel: new ApiModel(),
    fetchLogTagsApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  getTags: state => {
    return get(state.fetchLogTagsApi, 'response.data', [])
  }
}

export const actions = {
  async sendLogs({ state, commit, rootGetters }) {
    // TODO: rm fallback once inventory stores moved https://hectareagtech.atlassian.net/browse/HE-218
    const app = rootGetters['inventory/app/getApp'] ?? { isStorybook: true, isNative: false }

    if (state.queue.length === 0 || app.isStorybook || !app.isAuth || app.isInventory) {
      return false
    }

    // Process all logs in the queue and remove them
    const batch = [...state.queue]

    commit('emptyQueue')

    try {
      const mappedLogs = batch.map(log => ({
        timestamp: log.created,
        region: process.server ? 'client-ssr' : 'client-spa',
        service: `client-${isNative ? 'native' : 'web'}`,
        app: log.meta.platform,
        level: log.level,
        data: {
          message: log.message,
          data: isPlainObject(log.data) ? { ...log.data } : Array.isArray(log.data) ? [...log.data] : log.data,
          appVersion: app.version,
          user: app.user?.id
        }
      }))

      await new this.$Api(state.sendLogsApiModel, 'logs.sendLogsApiModel').post('/log/v1/logs', mappedLogs)
    } catch (error) {
      // Can't $log.debug here as we could create an inifite loop of sending failed logs
      console.error('Error sending analytics logs', error)

      // Re-add failed logs back into the queue
      commit('addToQueue', batch)
    }
  },

  async fetchTags({ state, commit }) {
    await new this.$Api(state.fetchLogTagsApi, 'logs.fetchLogTagsApi').get('/log/v1/tags')
  }
}

export const mutations = {
  addToQueue(state, log) {
    const reducedLog = truncateObjectStrings(log)

    if (Array.isArray(reducedLog)) {
      state.queue = [...reducedLog, ...state.queue]
    } else {
      state.queue.push(reducedLog)
    }
  },

  emptyQueue(state) {
    state.queue.splice(0)
  },

  reset(state) {
    this.$log.debug('Resetting logs module')

    Object.assign(state, initialState())
  }
}
