let platform = 'none'
let isNative = false
let deviceInfo = 'none'

// This is an abstraction for IE11 / iOS 9 which do not support Proxy which
// Capacitor uses at it's core. A polyfill is too heavy for Proxy support
const loadCapacitor = async function () {
  try {
    // Don't load for web. http://192 is for HMR native local dev. http://localhost without ssl is native app
    if (window && window.Capacitor) {
      const { Device } = await import('@capacitor/device')

      deviceInfo = await Device.getInfo()
      platform = window.Capacitor.getPlatform()
      isNative = window.Capacitor.isNativePlatform()
    } else {
      // Keep our default values
    }
  } catch (e) {
    // Catch the error IE11 throws about Proxy being undefined;
  }
}

export { deviceInfo, isNative, loadCapacitor, platform }
