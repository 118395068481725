import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    varietiesApi: new ApiModel(),
    commoditiesApi: new ApiModel(),
    commoditySpecificationsApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  commodities: (state, getters, rootState) => {
    return rootState.inventory.app.coreConfig.commodities ?? []
  },

  varieties: state => {
    return state.varietiesApi.response.data ?? []
  },

  commodityByTypes: (state, getters) => type => {
    return getters?.commodities.filter(commodity => commodity.type === type) ?? []
  },

  commodityById: (state, getters) => commodityId => {
    const matchedCommomdity = getters.commodities.find(commodity => {
      return commodity.items.find(item => item.id === commodityId)
    })

    return {
      type: matchedCommomdity?.type ?? undefined,
      commodity: matchedCommomdity?.items.find(commodity => commodity.id === commodityId)
    }
  },

  getCommoditySpecificationTypes: state => {
    return state.commoditySpecificationsApi.response.data ?? []
  }
}

export const actions = {
  async fetchCommodities({ getters }, forceUpdate = false) {
    if (forceUpdate || !getters.commodities.length) {
      try {
        await this.$api
          .record(state.commoditiesApi)
          .useStorePath('inventory.record.commoditiesApi')
          .getCommodities()
      } catch (error) {
        this.$log.error('Fetch commodities error', error)
      }
    }
  },

  async fetchSpecificationsByType({ state }, type) {
    try {
      await this.$api
        .record(state.commoditySpecificationsApi)
        .useStorePath('inventory.record.commoditySpecificationsApi')
        .getCommoditySpecifications(type)
    } catch (error) {
      this.$log.error('Fetch commodity specifications error', error)
    }
  }
}
