import { Device } from '@capacitor/device'
import { get } from 'lodash'

import ApiCore from '~/lib/api-core'
import { offlineDataAuth } from '~/lib/enums/cookies/offline-data-keys.js'
import { getOfflineStorage } from '~/lib/utility/offline-storage'

export class ApiInventory extends ApiCore {
  constructor(nuxtContext, apiModel, { timeout = 30000 } = {}) {
    super(nuxtContext, apiModel, null, timeout)

    this.currentPlatform = 'INVENTORY'

    // We want to trigger a retry if an endpoint returns 401
    this.raxConfig = {
      shouldRetry: error => {
        const isLogoutRequestOrAuthRoute =
          error.request.responseURL.includes('/logout') || this.nuxt.route.path.includes('/auth')

        const isUnauthorised = error.response?.status === 401

        const isForbiddenImpersonating =
          error.response?.status === 403 && nuxtContext.store.state.inventory.auth.impersonateBusiness?.id

        return !isLogoutRequestOrAuthRoute && (isUnauthorised || isForbiddenImpersonating)
      },
      statusCodesToRetry: [
        [401, 401],
        [403, 403]
      ],
      onRetryAttempt: retryError => {
        // The retry attempt will occur after the promise is resolved / rejected
        return new Promise((resolve, reject) => {
          try {
            nuxtContext.store.dispatch('inventory/auth/fetchRefreshToken').then(() => {
              retryError.config.headers.Authorization = `Bearer ${nuxtContext.store.state.inventory.auth.accessToken}`
              retryError.config.headers[
                'x-authorization-id'
              ] = `Bearer ${nuxtContext.store.state.inventory.auth.idToken}`

              if (nuxtContext.store.state.inventory.auth.impersonateBusiness?.id) {
                retryError.config.headers['x-impersonation'] =
                  nuxtContext.store.state.inventory.auth.impersonateBusiness.token
              }

              resolve()
            })
          } catch (error) {
            reject(error)
          }
        })
      }
    }

    // Our API response data is normally in data.data to account for data.pagination
    // Our mock server doesn't support this so we need to move it
    this.hooks.transformResponse = this.hooks.transformResponse.concat(data => {
      return typeof data === 'object' && 'data' in data ? data : { data }
    })

    // API wants request in snake_case so let's convert before sending
    // this.hooks.transformRequest = this.hooks.transformRequest.concat(data => {
    //   if (!data) {
    //     return data
    //   }

    //   return JSON.stringify(JSON.parse(data))
    // })

    // Modify the outgoing REQUEST
    this.axios.interceptors.request.use(async config => {
      const deviceInfo = await Device.getInfo()

      // Never proxy files as JSON server can't handle binaries
      config.baseURL =
        nuxtContext.$config.apiShouldMock && !config.url.includes('system/files')
          ? nuxtContext.$config.apiMockBaseUrl
          : nuxtContext.$config.apiBaseUrlInventory

      config.headers['x-timezone-offset'] = nuxtContext.$date().utcOffset()

      config.headers[
        'x-device-info'
      ] = `${deviceInfo.platform}, ${deviceInfo.operatingSystem}, ${deviceInfo.osVersion}`

      if (nuxtContext.store.state.inventory.auth.accessToken) {
        config.headers.Authorization = `Bearer ${nuxtContext.store.state.inventory.auth.accessToken}`

        // Inventory auth requires both headers to be sent for authentication
        if (nuxtContext.store.state.inventory.auth.idToken) {
          config.headers['x-authorization-id'] = `Bearer ${nuxtContext.store.state.inventory.auth.idToken}`
          // config.headers['cache-control'] = 'max-age=604800, must-revalidate'
        }

        if (nuxtContext.store.state.inventory.auth.impersonateBusiness?.token) {
          config.headers['x-impersonation'] = `${nuxtContext.store.state.inventory.auth.impersonateBusiness.token}`
        }

        const selectedOrganisation = JSON.parse(getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_SELECTED_ORG))

        // Selected organisation
        if (selectedOrganisation?.id && selectedOrganisation?.name) {
          config.headers['x-organisation-id'] = `${selectedOrganisation.id}`
          config.headers['x-organisation-name'] = `${selectedOrganisation.name}`
        }
      }

      return config
    })

    // Handle RESPONSE
    this.axios.interceptors.response.use(
      // Successful response
      response => {
        this.updateState()

        return response
      },
      // Error response
      error => {
        const statusCode = get(error, 'response.status') || 500

        if (statusCode === 500) {
          this.state.response.message = this.nuxt.i18n.t('somethingWentWrong')
        } else if (error.response?.data?.data?.errors) {
          this.state.response.message = error.response?.data?.data?.errors[0].message
        }

        this.updateState()

        // We need errors to bubble up to calling context promise
        error.shouldThrowFatal = true

        return Promise.reject(error)
      }
    )
  }

  // Allow admin calls to set an override key
  // Not currently used in inventory but we need to support the function
  // here because it's called in ApiData.vue
  setOrganisationKey(organisationKey) {
    return this
  }
}
