
import { icons } from '~/lib/utility/assets'

export default {
  name: 'AppLoader',

  data() {
    return {
      icons
    }
  },

  mounted() {
    if (this.$app.isNative) {
      this.$store.dispatch('device/changeStatusBarColor', { isDark: false, hex: '#FFFFFF' })
    }
  },

  methods: {
    retryConnection() {
      this.$store.dispatch('app/fetchStatus')
    }
  }
}
