import { get } from 'lodash'

import { storePersist } from '~/lib/store-persist'
import { isNative, loadCapacitor, platform } from '~/plugins/native/capacitor'

// In native app environment window.Capacitor will be available even without the core lib being imported first
if (window && window.Capacitor) {
  console.log('Loading core capacitor')
  import('@capacitor/core')
}

// We don't want capacitor code running server side so we only execute this client side, if runnning native app
export default async function ({ store, app, $log }, inject) {
  await loadCapacitor()

  let launchRedirectRoute = null

  if (isNative) {
    store.dispatch(
      'device/changeStatusBarColor',
      {
        hex: get(store.state, 'app.config.colors.background.hex', '#FFFFFF'),
        isDark: get(store.state, 'app.config.colors.background.isDark', false)
      },
      { root: true }
    )

    const { App } = await import('@capacitor/app')

    // Handle deep links (universal links) coming into the app and redirect appropriately
    App.addListener('appUrlOpen', openListenerEvent => {
      $log.debug('Deep link launch:', openListenerEvent.url)

      try {
        const url = new URL(openListenerEvent.url)

        const route = { path: url.pathname + url.search }

        if (store.state.app.isClientLoaded) {
          app.router.push(route)
        } else {
          launchRedirectRoute = route
        }
      } catch (error) {
        $log.error(`Failed to launch deep link - ${openListenerEvent.url}`, error)
      }
    })

    window.onNuxtReady(() => {
      $log.debug('Native Nuxt ready')

      if (launchRedirectRoute) {
        app.router.push(launchRedirectRoute)
      }
    })

    storePersist.setup(store)
  }

  // Generic small native functions
  const native = {
    openAppSettings: async () => {
      const { NativeSettings } = await import('capacitor-native-settings')

      $log.debug('Opening native app settings', platform)

      // https://github.com/RaphaelWoude/capacitor-native-settings#iosoptions
      if (platform === 'ios') {
        NativeSettings.openIOS({ option: 'app' })
      } else if (platform === 'android') {
        NativeSettings.openAndroid({ option: 'application_details' })
      }
    },

    keepScreenAwake: async (keepAwake = true) => {
      const { KeepAwake } = await import('@capacitor-community/keep-awake')

      if (keepAwake) {
        await KeepAwake.keepAwake()
      } else {
        await KeepAwake.allowSleep()
      }
    },

    vibrate: async impactStyle => {
      const { Haptics, ImpactStyle } = await import('@capacitor/haptics')

      if (impactStyle === 'notification') {
        // Several quick pulses
        await Haptics.notification()
      } else if (impactStyle) {
        // Short pulse
        const impactConfig = {
          light: ImpactStyle.Light,
          medium: ImpactStyle.Medium,
          heavy: ImpactStyle.Heavy
        }

        await Haptics.impact({ style: impactConfig[impactStyle] })
      } else {
        // Longer continuous pulse
        await Haptics.vibrate()
      }
    }
  }

  // Inject into Nuxt context as $native
  inject('native', native)
}
