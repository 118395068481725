export const cookiesTerms = {
  FARMTO_USER_ACCEPTED_TERMS: 'farmto-user-accepted-terms',
  FARMTO_USER_ACCEPTED_COOKIES: 'farmto-user-accepted-cookies'
}

export const cookiesAuth = {
  FIRST_LOGIN_SELLER: 'hectare-first-login-seller',
  FIRST_LOGIN_BUYER: 'hectare-first-login-buyer'
}

export const cookiesReload = {
  FARMTO_LAST_RELOAD: 'farmto-last-reload'
}

export const searchFilters = {
  inventory: {
    INVENTORY_RECORD_FILTERS: 'filters-inventory-record',
    INVENTORY_STORAGE_FIELD_FILTERS: 'filters-inventory-storage-field',
    INVENTORY_STORAGE_STORE_FILTERS: 'filters-inventory-storage-store',
    INVENTORY_RECORDS_IN_FIELD_FILTERS: 'filters-inventory-records-in-field',
    INVENTORY_MOVEMENTS_FILTERS: 'filters-inventory-movements',
    INVENTORY_MOVEMENTS_INTAKES_FILTERS: 'filters-inventory-movements-intakes',
    INVENTORY_MOVEMENTS_INTERNAL_FILTERS: 'filters-inventory-movements-internal',
    INVENTORY_MOVEMENTS_EXTERNAL_FILTERS: 'filters-inventory-movements-external',
    INVENTORY_ACTIONS_FILTERS: 'filters-inventory-actions',
    INVENTORY_MOVEMENTS_INCOMPLETE_FILTERS: 'filters-inventory-movements-incomplete',
    INVENTORY_SETUP_STORE_FILTERS: 'filters-inventory-setup-store',
    INVENTORY_SETUP_FIELD_FILTERS: 'filters-inventory-setup-field',
    INVENTORY_SETUP_BUSINESS_UNIT_ADD_FILTERS: 'filters-inventory-business-unit-add-filters',
    TRADING_INVENTORY_RECORD_FILTERS: 'filters-trading-inventory-record',
    INVENTORY_HARVEST_PLANS_FILTERS: 'filters-inventory-harvest-plans-filters'
  },
  trading: {
    TRADING_CONTRACTS: 'filters-trading-contracts',
    TRADING_OPEN_SALES_CONTRACTS: 'filters-trading-open-sales-contracts',
    TRADING_COMPLETED_SALES_CONTRACTS: 'filters-trading-completed-sales-contracts',
    TRADING_CANCELLED_SALES_CONTRACTS: 'filters-trading-cancelled-sales-contracts',
    TRADING_PREMIUM_SALES_CONTRACTS: 'filters-trading-premium-sales-contracts',
    TRADING_ACTIVE_STORAGE_CONTRACTS: 'filters-trading-active-storage-contracts',
    TRADING_COMPLETED_STORAGE_CONTRACTS: 'filters-trading-completed-storage-contracts',
    TRADING_TRADE_AVAILABILITY: 'filters-trading-trade-availability',
    TRADING_TRADE_POSITION: 'filters-trading-trade-position',
    TRADED_CONTRACTS: 'filters-trading-contracts',
    MARKET_LISTING_LIVE_AND_ENDED: 'filters-trading-market-listing-traded-ended',
    MARKET_LISTING_LIVE: 'filters-trading-market-listing-live',
    MARKET_LISTING_ENDED: 'filters-trading-market-listing-ended',
    MARKET_LISTING_TRADED: 'filters-trading-market-listing-traded',
    TRADING_BUYER_BIDS_ACCEPTED: 'filters-trading-buyer-bids-accepted',
    TRADING_BUYER_MY_BIDS_ACCEPTED: 'filters-trading-buyer-my-bids-accepted',
    TRADING_BUYER_BIDS: 'filters-trading-buyer-bids',
    TRADING_BUYER_LISTINGS: 'filters-trading-buyer-listings',
    TRADING_SELLER_WANTED_LISTINGS: 'filters-trading-seller-wanted-listings',
    TRADING_SELLER_BIDS_ACCEPTED: 'filters-trading-seller-bids-accepted'
  }
}

// Used to dynamically grab correct enum by search store
export const searchFilterEnumConfig = {
  records: searchFilters.inventory.INVENTORY_RECORD_FILTERS,
  recordsInField: searchFilters.inventory.INVENTORY_RECORDS_IN_FIELD_FILTERS,
  storageStore: searchFilters.inventory.INVENTORY_STORAGE_STORE_FILTERS,
  movements: searchFilters.inventory.INVENTORY_MOVEMENTS_FILTERS,
  movementsIntake: searchFilters.inventory.INVENTORY_MOVEMENTS_INTAKES_FILTERS,
  movementsInternal: searchFilters.inventory.INVENTORY_MOVEMENTS_INTERNAL_FILTERS,
  movementsExternal: searchFilters.inventory.INVENTORY_MOVEMENTS_EXTERNAL_FILTERS,
  movementsIncomplete: searchFilters.inventory.INVENTORY_MOVEMENTS_INCOMPLETE_FILTERS,
  actions: searchFilters.inventory.INVENTORY_ACTIONS_FILTERS,
  setupStores: searchFilters.inventory.INVENTORY_SETUP_STORE_FILTERS,
  setupFields: searchFilters.inventory.INVENTORY_SETUP_FIELD_FILTERS,
  businessUnitAdd: searchFilters.inventory.INVENTORY_SETUP_BUSINESS_UNIT_ADD_FILTERS,
  contracts: searchFilters.trading.TRADING_CONTRACTS,
  openSalesContracts: searchFilters.trading.TRADING_OPEN_SALES_CONTRACTS,
  completedSalesContracts: searchFilters.trading.TRADING_COMPLETED_SALES_CONTRACTS,
  cancelledSalesContracts: searchFilters.trading.TRADING_CANCELLED_SALES_CONTRACTS,
  premiumSalesContracts: searchFilters.trading.TRADING_PREMIUM_SALES_CONTRACTS,
  activeStorageContracts: searchFilters.trading.TRADING_ACTIVE_STORAGE_CONTRACTS,
  completedStorageContracts: searchFilters.trading.TRADING_COMPLETED_STORAGE_CONTRACTS,
  tradeAvailability: searchFilters.trading.TRADING_TRADE_AVAILABILITY,
  tradePosition: searchFilters.trading.TRADING_TRADE_POSITION,
  traded: searchFilters.trading.TRADED_CONTRACTS,
  wantedTradeRequestsSeller: searchFilters.trading.TRADING_SELLER_WANTED_LISTINGS,
  marketListingAll: searchFilters.trading.MARKET_LISTING_LIVE_AND_ENDED,
  marketListingEnded: searchFilters.trading.MARKET_LISTING_ENDED,
  marketListingTraded: searchFilters.trading.MARKET_LISTING_TRADED,
  tradingSellerBidsAccepted: searchFilters.trading.TRADING_SELLER_BIDS_ACCEPTED,
  tradingInventory: searchFilters.inventory.TRADING_INVENTORY_RECORD_FILTERS,
  tradingBuyerMyBids: searchFilters.trading.TRADING_BUYER_BIDS,
  tradingBuyerMyBidsAccepted: searchFilters.trading.TRADING_BUYER_MY_BIDS_ACCEPTED,
  tradingBuyerAwaitingBids: searchFilters.trading.TRADING_BUYER_LISTINGS,
  harvestPlans: searchFilters.inventory.INVENTORY_HARVEST_PLANS_FILTERS
}
