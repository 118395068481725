import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    businessesApi: new ApiModel(),
    organisationsApi: new ApiModel(),
    updateOwnerApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  businesses: state => {
    return state.businessesApi.response.data?.docs ?? []
  },

  businessesIsLoading: state => {
    return state.businessesApi.isLoading
  },

  getOrganisation: state => {
    return state.organisationsApi.response.data
  }
}

export const actions = {
  fetchOrganisationsPaginated({ getters }, config = { limit: 25, offset: 0, query: '' }) {
    return this.$api
      .organisations(state.businessesApi)
      .useStorePath('inventory.organisations.businessesApi')
      .fetchOrganisations(config)
  },

  fetchOrganisationById({ state }, id) {
    return this.$api
      .organisations(state.organisationsApi)
      .useStorePath('inventory.organisations.organisationsApi')
      .fetchOrganisationById(id)
  },

  changeUserToOwner({ state }, postModel) {
    return this.$api
      .organisations(state.updateOwnerApi)
      .useStorePath('inventory.organisations.updateOwnerApi')
      .changeUserToOwner(postModel)
  }
}
