import { ApiInventory } from '~/lib/api-inventory'

export class GeoService extends ApiInventory {
  getAddress(queryString) {
    return this.get(`/geo/address?query=${queryString}`)
  }

  addressLookup(queryString, countryISO3 = 'GBR') {
    return this.get(`/geo/search?query=${queryString}&countryISO=${countryISO3}`)
  }

  searchAddressById(searchId) {
    return this.get(`/geo/search?id=${searchId}`)
  }

  getAddressById(id, source) {
    const params = new URLSearchParams({
      ...(id ? { id } : {}),
      ...(source ? { source } : {})
    })

    return this.get(`/geo/address?${params.toString()}`)
  }

  getCountryOptions() {
    return this.get('/geo/countries')
  }

  getRegionOptionsByCountryCode(countryCode) {
    return this.get(`geo/regions?countryISO=${countryCode}`)
  }

  getAddressFromWhat3Words(what3Words) {
    return this.get(`/geo/what3words?query=${what3Words}`)
  }

  getAddressFromCoordinates(coordinates) {
    const { lat, lon } = coordinates

    return this.get(`/geo/coordinates?lat=${lat}&lon=${lon}`)
  }
}
