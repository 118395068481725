import {
  captureEvent as capturePosthogEvent,
  clearDefaults as posthogClearDefaults,
  setDataFields as posthogSetDataFields,
  setDefaults as posthogSetDefaults
} from '~/services/posthog'

type AnalyticsBaseParams = 'is_mobile' | 'platform' | 'location'

type AnalyticsBidParams = 'bid_id' | 'bid_max_weight' | 'bid_min_weight' | 'bid_price'

type AnalyticsListingParams =
  | 'listing_commodity'
  | 'listing_commodity_grade'
  | 'listing_commodity_type'
  | 'listing_harvest_year'
  | 'listing_id'
  | 'listing_max_weight'
  | 'listing_min_weight'

type AnalyticsUserParams = 'user_id' | 'user_product' | 'user_role'

type AnalyticsCalculatePositionParams =
  | 'crop'
  | 'currently_sold'
  | 'date'
  | 'desired_position'
  | 'grade'
  | 'harvest_year'
  | 'price_source'
  | 'region'

type AnalyticsTargetPriceParams = 'region' | 'alert_set' | 'location'

type AnalyticsMarketNewsParams = 'subject' | 'market_update_id'

type AnalyticsRelistParams = 'reason'

type AnalyticsMarketingQuestionParams = 'answer'

export type AssuranceLookupStatus = 'success' | 'failure' | 'later'

type AnalyticsAssuranceLookupStatusParams = 'status'

type AnalyticsParams =
  | AnalyticsBaseParams
  | AnalyticsBidParams
  | AnalyticsCalculatePositionParams
  | AnalyticsListingParams
  | AnalyticsUserParams
  | AnalyticsMarketNewsParams
  | AnalyticsTargetPriceParams
  | AnalyticsRelistParams
  | AnalyticsMarketingQuestionParams
  | AnalyticsAssuranceLookupStatusParams

type AnalyticsEvents =
  | '$pageview'
  | 'alert_teammate_added'
  | 'bid_acceptance_accept_trade'
  | 'bid_acceptance_completed'
  | 'bid_acceptance_started'
  | 'bid_creation_completed'
  | 'bid_creation_started'
  | 'bid_reopen_completed'
  | 'bid_reopen_started'
  | 'bid_seen'
  | 'calculate_my_position_completed'
  | 'collection_receipt_completed'
  | 'collection_receipt_image_started'
  | 'collection_receipt_started'
  | 'contract_upload_completed'
  | 'contract_upload_started'
  | 'ex_farm_table_focus'
  | 'finance_bank_details_popup_clicked'
  | 'finance_continue_without_selected'
  | 'finance_eligible_bid_opened'
  | 'finance_faq_clicked'
  | 'finance_info_link_clicked'
  | 'finance_info_link_clicked'
  | 'finance_show_me_more_clicked'
  | 'finance_trade_completed'
  | 'finance_trade_confirmation_clicked'
  | 'finance_trade_started'
  | 'finance_trading_calculator_completed'
  | 'flexible_tonnage_info_focus'
  | 'futures_prices_focus'
  | 'listing_commodity_entry_completed'
  | 'listing_creation_completed'
  | 'listing_creation_started'
  | 'listing_seen'
  | 'listing_spec_info_completed'
  | 'listing_spec_info_expanded'
  | 'listing_spec_info_started'
  | 'market_insights_highlights_carousel_scrolled'
  | 'market_news_clicked'
  | 'market_update_bubble_closed'
  | 'market_update_bubble_opened'
  | 'marketing_question'
  | 'pending_account_listing_creation_clicked'
  | 'pending_finance_need_help_clicked'
  | 'price_offers_seen'
  | 'recent_trade_table_focus'
  | 'relist_completed'
  | 'request_access_form_completed'
  | 'seller_click_buyer_profile'
  | 'seller_hover_buyer'
  | 'set_target_price_complete'
  | 'set_target_price_started'
  | 'trade_summary_download'
  | 'view_all_trading_expanded'
  | 'wanted_creation_completed'
  | 'wanted_creation_started'
  | 'assurance_lookup'
  | 'login_success'
  | 'selfserve_signup_success'
  | 'live-chat-show'

type AnalyticsPayload = { event?: AnalyticsEvents } & { [key in AnalyticsParams]?: unknown }

const pushToDataLayer = (input: any) => {
  if (window?.dataLayer?.push) {
    window.dataLayer.push(input)
  }
}

export const setEventData = (analyticsPayload: AnalyticsPayload) => {
  posthogSetDataFields(analyticsPayload)

  pushToDataLayer(analyticsPayload)
}

export const trackEvent = (analyticsPayload: AnalyticsPayload) => {
  capturePosthogEvent(analyticsPayload)

  pushToDataLayer(analyticsPayload)
}

export const setAnalyticsDefaultData = (defaults: { [key in AnalyticsParams]?: unknown }) => {
  posthogSetDefaults(defaults)

  pushToDataLayer(defaults)
}

export const clearAnalyticsDefaultData = () => {
  posthogClearDefaults()

  pushToDataLayer(function () {
    this.reset()
  })
}
