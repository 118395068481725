import { ApiInventory } from '~/lib/api-inventory'

export class RecordService extends ApiInventory {
  getCommodities() {
    return this.get(`/system/commodities`)
  }

  getCommoditySpecifications(type) {
    return this.get(`/inventory/commodities/specifications/${type}`)
  }
}
